import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
const Protect = (props) => {
    let { ComponentName } = props;
    let navigate = useNavigate();

    let login_status = secureLocalStorage.getItem("login_status");
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!login_status) {
                navigate("/login");
            }
        }, 50);
        return () => clearInterval(intervalId);
    }, [login_status]);

    return <ComponentName />
};
export default Protect;

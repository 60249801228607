import React from 'react'
import Footer from '../Home_page/Footer'
import { Link } from 'react-router-dom'
const Orderstatus_inntout = () => {
  return (
    <div>
   <div>
  <div className="container mt-5 mb-5 rtl __inline-53" style={{textAlign: 'left'}}>
    <div className="row d-flex justify-content-center">
      <div className="col-md-10 col-lg-10">
        <div className="card">
          <div className="card-body">
            <div className="mb-3">
              <center>
                <i className="fa fa-check-circle __text-60px __color-0f9d58" />
              </center>
            </div>
            <h6 className="font-black fw-bold text-center">Order Placed Successfully!</h6>
            {/* <p className="text-center fs-12">Your payment has been successfully processed and your order - */}
              {/* <span className="fw-bold text-primary">
                100185
              </span>
              Has been placed.</p> */}
            <div className="row mt-4">
              <div className="col-12 text-center">
                <Link to="/inntoutorderlist" className="btn btn--primary mb-3 text-center">
                   Order List
                </Link>
              </div>
              <div className="col-12 text-center">
                <a href="/inntoutHome" className="text-center">Continue Shopping</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <Footer/>

</div>

    
    </div>
  )
}

export default Orderstatus_inntout
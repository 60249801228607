import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import Slider from 'react-slick';
import { useQuery } from '@tanstack/react-query';


const Footerbanner_inntout = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        arrows: false, // Hide the previous and next arrows
        autoplay: true, // Enable autoplay
        slidesToShow: 1, // Initially, show 4 slides
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Adjust the settings for screens with width less than 1024px
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768, // Adjust the settings for screens with width less than 768px
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480, // Adjust the settings for screens with width less than 480px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const fetchProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/inntOutFooterbanner_list`);
        return response.data;
    };
    const { data: products, error, isLoading } = useQuery({
        queryKey: ['inntOutFooterbanner_list'],
        queryFn: fetchProducts,
    });

    return (
        <div className='container'>
            <div className='row justify-content-center mt-3'>
                <div className='col-lg-10'>
                    <Slider {...settings}>
                        {products?.data?.map((item, index) => {
                            // Check if the item is an image or video based on the file extension
                            const isImage = item?.banner_image.endsWith(".png") || item?.banner_image.endsWith(".jpg");
                            const isVideo = item?.banner_image.endsWith(".mp4");

                            return (
                                <div key={index}>
                                    {isImage && <img className="w-100 __slide-img img-fluid" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${item?.banner_image}`} alt={`Slide ${index}`} />}
                                    {isVideo && (
                                        <>
                                            <video style={{ objectFit: "cover" }} className="w-100 __slide-img img-fluid" autoPlay
                                                muted
                                                loop>
                                                <source className="w-100 __slide-img img-fluid" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${item?.banner_image}`} type="video/mp4" />
                                            </video>
                                            <style>
                                                {`
          video::-internal-media-controls-overlay-cast-button {
            display: none;
          }
          video::-webkit-media-controls-enclosure {
            display: none;
          }
          video::-webkit-media-controls-panel {
            display: none;
          }
        `}
                                            </style>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Footerbanner_inntout
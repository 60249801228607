import Home from './Components/Home_page/Home';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Userprofile from './Components/Auth_pages/Userprofile';
import Addtocard from './Components/Auth_pages/Addtocard';
import Checkout from './Components/Auth_pages/Checkout';
import Signup from './Components/Signup';
import Login from './Components/Login';
import Wishlist from './Components/Auth_pages/Wishlist';
import Chekoutpayment from './Components/Auth_pages/Chekoutpayment';
import Order_success_status from './Components/Auth_pages/Order_success_status';
import Auth_header from './Components/Auth_pages/Auth_header';
import Singleproducts from './Components/Home_page/Singleproducts';
import Orderdetails from './Components/Auth_pages/Orderdetails';
import Mywallate from './Components/Auth_pages/Mywallate';
import Myloutrypoint from './Components/Auth_pages/Myloutrypoint';
import Address from './Components/Auth_pages/Address';
import Coupans from './Components/Auth_pages/Coupans';
import Track_order from './Components/Auth_pages/Track_order';
import Search_Products from './Components/Auth_pages/Search_Products';
import Contact from './Components/Auth_pages/Contact';
import Aboutcompany from './Components/Auth_pages/Aboutcompany';
import Faq from './Components/Auth_pages/Faq';
import Terms_and_conditions from './Components/Auth_pages/Terms_and_conditions ';
import Privacypolicy from './Components/Auth_pages/Privacypolicy';
import TopSellers from './Components/Home_page/TopSellers';
import Topsaller_details from './Components/Auth_pages/Topsaller_details';
import Refertoearn from './Components/Auth_pages/Refertoearn';
import Chatbox from './Components/Auth_pages/Chatbox';
import Shopview from './Components/Auth_pages/Shopview';
import Inbox from './Components/Auth_pages/Inbox';
import Track_order_status from './Components/Auth_pages/Track_order_status';
import Products_order from './Components/Auth_pages/Products_order';
import Restaurantdetails from './Components/Inntout/Restaurantdetails';
import Suppportticket_chat from './Components/Auth_pages/Suppportticket_chat';
import Hello from './Components/Hello';
import Verifyotp from './Components/Verifyotp';
import Forgotpassword from './Components/Forgotpassword';
import Categories_products from './Components/Auth_pages/Categories_products';
import Technicalissue from './Components/Technicalissue';
import { useEffect, useState } from 'react';
import Nointernet from './Components/Nointernet';
import Errorpage from './Components/Errorpage';
import InntoutHome from './Components/Inntout/InntoutHome';
import Categories_products_inntout from './Components/Inntout/Categories_products_inntout';
import Singleproducts_inntout from './Components/Inntout/Singleproducts_inntout';
import Addtocard_inntout from './Components/Inntout/Addtocard_inntout';



import Suggested_viewall_products from './Components/Home_page/Suggested_viewall_products';
import Fevoirateshops from './Components/Auth_pages/Fevoirateshops';
import Categoriesviewalllist from './Components/Auth_pages/Categoriesviewalllist';
import Viewallproductslist from './Components/Home_page/Viewallproductslist';
import Tranding_products_viewalllist from './Components/Home_page/Tranding_products_viewalllist';
import Vendorchating from './Components/Auth_pages/Vendorchating';
import InntOutcheckoutList from './Components/Inntout/InntOutcheckoutList';
import { Provider } from 'react-redux';
import store from './readux/store';
import Inntoutorderlist from './Components/Inntout/Inntoutorderlist';
import Inntout_orderdetails from './Components/Inntout/Inntout_orderdetails';
import Inntout_searchlist from './Components/Inntout/Inntout_searchlist';
import Giftcard from './Components/Auth_pages/Giftcard';
import Giftcardsendlist from './Components/Auth_pages/Giftcardsendlist';
import Innnt_suggestion from './Components/Auth_pages/Innnt_suggestion';
import Myfriendlist from './Components/Auth_pages/Myfriendlist';
import Address_Add from './Components/Auth_pages/Address_Add';
import Allshopsinntout from './Components/Inntout/Allshopsinntout';
import Chatlist from './Components/Auth_pages/Chatlist';
import Usermenubar from './Components/Auth_pages/Usermenubar';
import Mobileproductssearch from './Components/Auth_pages/Mobileproductssearch';
import Mobileproductsearch_inntout from './Components/Inntout/Mobileproductsearch_inntout';

// translation language
import global_en from './Translation/en/global.json'
import global_fa from './Translation/fa/global.json'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import Select_Adderess from './Components/Auth_pages/Select_Adderess';
import Select_address_inntout from './Components/Inntout/Select_address_inntout';
import Orderstatus_inntout from './Components/Inntout/Orderstatus_inntout';
import Protect from './Components/Protect';
import { messaging, onMessage } from './firebase';


import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TodaysOffers_all from './Components/Inntout/TodaysOffers_all';
import TopSales_all from './Components/Inntout/TopSales_all';
import DiscountProduct_list from './Components/Home_page/DiscountProduct_list';
import Dealofdaysproductslist from './Components/Home_page/Dealofdaysproductslist';
import Refertoearn_history from './Components/Auth_pages/Refertoearn_history';
import DayOfthedealsProducts_filter from './Components/Home_page/DayOfthedealsProducts_filter';
import NewArrivals_productfilter from './Components/Home_page/NewArrivals_productfilter';
import Customer_favouriteProducts_filter from './Components/Home_page/Customer_favouriteProducts_filter';
import DiscountProduct_list_inntout from './Components/Inntout/DiscountProduct_list_inntout';
import Topsaller_details_inntout from './Components/Inntout/Topsaller_details_inntout';
import Return_policy from './Components/Home_page/Return_policy';
import Refund_policy from './Components/Home_page/Refund_policy';
import Shopview_inntout from './Components/Inntout/Shopview_inntout';
import NewArrivals_skeleten from './Components/Skeleten/NewArrivals_skeleten';
import TopSellers_skeleten from './Components/Skeleten/TopSellers_skeleten';
import Category_Sceleten from './Components/Skeleten/Category_Sceleten';

// Initialize the QueryClient
const queryClient = new QueryClient();

i18next.init({
  interpolation: { escapeValue: false },
  lng: secureLocalStorage.getItem("language") || "en",
  resources: {
    en: {
      global: global_en
    },
    fa: {
      global: global_fa
    }
  }
})
function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [isOnline]);

  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === 'granted') {
  //       console.log('Notification permission granted.');
  //     } else {
  //       console.log('Unable to get permission to notify.');
  //     }
  //   });


  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload.notification.body);
  //     new Notification(payload.notification.title, {
  //       body: payload.notification.body,
  //     });
  //   });

  // }, []);


  // get locatiom longitude and lattitude 
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let data = {
            "lat": position.coords.latitude,
            "lon": position.coords.longitude
          }
          // console.log(data)
          secureLocalStorage.setItem("latlon", JSON.stringify(data))
        },
        (error) => {
        }
      );
    }
  }, [])

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <div>
          {isOnline ? (
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <Auth_header />
                  <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/signup' element={<Signup />}></Route>
                    <Route path='/login' element={<Login />}></Route>
                    <Route path='/verifyotp' element={<Verifyotp />}></Route>
                    <Route path='/forgotpassword' element={<Forgotpassword />}></Route>
                    <Route path='/userprofile' element={<Protect ComponentName={Userprofile} />}></Route>
                    <Route path='/categories_products' element={<Categories_products />}></Route>
                    <Route path='/categoriesviewalllist' element={<Categoriesviewalllist />}></Route>
                    <Route path='/singleprojects/:pid/:vid' element={<Singleproducts />}></Route>
                    <Route path='/addtocard' element={<Protect ComponentName={Addtocard} />}></Route>
                    <Route path='/wishlist' element={<Protect ComponentName={Wishlist} />}></Route>
                    <Route path='/myfriendlist' element={<Protect ComponentName={Myfriendlist} />}></Route>
                    <Route path='/chatlist' element={<Chatlist />}></Route>
                    <Route path='/fevoirateshops' element={<Protect ComponentName={Fevoirateshops} />}></Route>
                    <Route path='/chackout' element={<Checkout />}></Route>
                    <Route path='/select_Adderess' element={<Protect ComponentName={Select_Adderess} />}></Route>
                    <Route path='/chackoutpayment' element={<Protect ComponentName={Chekoutpayment} />}></Route>
                    <Route path='/oderstaus' element={<Protect ComponentName={Order_success_status} />}></Route>
                    <Route path='/oderdetails' element={<Orderdetails />}></Route>
                    <Route path='/mywallet' element={<Protect ComponentName={Mywallate} />}></Route>
                    <Route path='/myloutrypoint' element={<Protect ComponentName={Myloutrypoint} />}></Route>
                    <Route path='/address' element={<Protect ComponentName={Address} />}></Route>
                    <Route path='/address_Add' element={<Protect ComponentName={Address_Add} />}></Route>
                    <Route path='/chatbox' element={<Chatbox />}></Route>
                    <Route path='/vendorchating' element={<Vendorchating />}></Route>
                    <Route path='/coupan' element={<Protect ComponentName={Coupans} />}></Route>
                    <Route path='/referandearn' element={<Protect ComponentName={Refertoearn} />}></Route>
                    <Route path='/refertoearn_history' element={<Protect ComponentName={Refertoearn_history} />}></Route>
                    <Route path='/trackorder' element={<Track_order />}></Route>
                    <Route path='/searchproducts' element={<Search_Products />}></Route>
                    <Route path='/contact' element={<Protect ComponentName={Contact} />}></Route>
                    <Route path='/aboutcompany' element={<Aboutcompany />}></Route>
                    <Route path='/faq' element={<Faq />}></Route>
                    <Route path='/termsandcondition' element={<Terms_and_conditions />}></Route>
                    <Route path='/pricacyprolicy' element={<Privacypolicy />}></Route>
                    <Route path='/dealofdaysproductslist' element={<Dealofdaysproductslist />}></Route>

                    <Route path='/topsaller' element={<TopSellers />}></Route>
                    <Route path='/topsaller_details' element={<Topsaller_details />}></Route>
                    <Route path='/shopview' element={<Protect ComponentName={Shopview} />}></Route>
                    <Route path='/inbox' element={<Protect ComponentName={Inbox} />}></Route>
                    <Route path='/track_order_status' element={<Track_order_status />}></Route>
                    <Route path='/products_order' element={<Protect ComponentName={Products_order} />}></Route>
                    <Route path='/giftcard' element={<Protect ComponentName={Giftcard} />}></Route>
                    <Route path='/giftcardsendlist' element={<Protect ComponentName={Giftcardsendlist} />}></Route>
                    <Route path='/innnt_suggestion' element={<Protect ComponentName={Innnt_suggestion} />}></Route>
                    <Route path='/restaurantdetails' element={<Restaurantdetails />}></Route>
                    <Route path='/suggested_viewall_products' element={<Suggested_viewall_products />}></Route>
                    <Route path='/supportticket_chat' element={<Suppportticket_chat />}></Route>
                    <Route path='/technicalissue' element={<Technicalissue />}></Route>
                    <Route path='/viewallproducts' element={<Technicalissue />}></Route>
                    <Route path='/tranding_products_viewalllist' element={<Tranding_products_viewalllist />}></Route>
                    <Route path='/viewallproductslist' element={<Viewallproductslist />}></Route>
                    <Route path='/mobileproductssearch' element={<Mobileproductssearch />}></Route>
                    <Route path='/discountProduct_list' element={<DiscountProduct_list />}></Route>
                    <Route path='/dayOfthedealsProducts_filter' element={<DayOfthedealsProducts_filter />}></Route>
                    <Route path='/newArrivals_productfilter' element={<NewArrivals_productfilter />}></Route>
                    <Route path='/customer_favouriteProducts_filter' element={<Customer_favouriteProducts_filter />}></Route>
                    <Route path='/return_policy' element={<Return_policy />}></Route>
                    <Route path='/refund_policy' element={<Refund_policy />}></Route>
                    <Route path='*' element={<Errorpage />}></Route>
                    {/* inntout */}
                    <Route path='/inntoutHome' element={<InntoutHome />}></Route>
                    <Route path='/Inntoutproductslist' element={<Categories_products_inntout />}></Route>
                    <Route path='/singleprojects_inntout/:ipid/:ivid' element={<Singleproducts_inntout />}></Route>
                    <Route path='/addtocardlist_inntout' element={<Protect ComponentName={Addtocard_inntout} />}></Route>
                    <Route path='/inntOutcheckoutList' element={<Protect ComponentName={InntOutcheckoutList} />}></Route>
                    <Route path='/inntoutorderlist' element={<Protect ComponentName={Inntoutorderlist} />}></Route>
                    <Route path='/inntout_orderdetails' element={<Inntout_orderdetails />}></Route>
                    <Route path='/inntout_searchlist' element={<Inntout_searchlist />}></Route>
                    <Route path='/allshopsinntout' element={<Allshopsinntout />}></Route>
                    <Route path='/mobileproductsearch_inntout' element={<Mobileproductsearch_inntout />}></Route>
                    <Route path='/select_address_inntout' element={<Protect ComponentName={Select_address_inntout} />}></Route>
                    <Route path='/Orderstatus_inntout' element={<Protect ComponentName={Orderstatus_inntout} />}></Route>
                    <Route path='/todaysOffers_all' element={<TodaysOffers_all />}></Route>
                    <Route path='/topSales_all' element={<TopSales_all />}></Route>
                    <Route path='/discountProduct_list_inntout' element={<DiscountProduct_list_inntout />}></Route>
                    <Route path='/topsaller_details_inntout' element={<Topsaller_details_inntout />}></Route>
                    <Route path='/shopview_inntout' element={<Shopview_inntout />}></Route>

                    <Route path='/hello' element={<Hello />}></Route> 
                    <Route path='/usermenu' element={<Usermenubar />}></Route>  
                    <Route path='/newArrivals_skeleten' element={<NewArrivals_skeleten />}></Route> 
                    <Route path='/topSellers_skeleten' element={<TopSellers_skeleten />}></Route>
                    <Route path='/category_Sceleten' element={<Category_Sceleten />}></Route>
                    {/* <Route path='/p_router' element={<Protact />}></Route> */}

                  </Routes>
                </Router>
              </QueryClientProvider>
            </Provider>
          ) : (
            <Nointernet />
          )}
        </div>
      </I18nextProvider>

    </>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../Auth_pages/header.css'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { address_list, inntout_setcard_list, user_profile } from '../../readux/arrSlice'
import { useTranslation } from 'react-i18next'
import { Sidebar } from 'primereact/sidebar';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FiCornerUpLeft } from "react-icons/fi";
import Mobileview_inntout from '../Auth_pages/Mobileview_inntout'

const Auth_header_inntout = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch();
  let token = secureLocalStorage.getItem("token")
  let userid = secureLocalStorage.getItem("useid")
  let [showuser, setshowuser] = useState({})
  let [show_cardlist, setshow_cardlist] = useState([])
  let [storepricevalue, setstorepricevalue] = useState(0)
  let [status, setstatus] = useState("")
  const [location, setLocation] = useState(null);
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [visible, setVisible] = useState(false);
  let language = secureLocalStorage.getItem("language")


  let logout = () => {
    navigate("/")
    localStorage.removeItem("loginstatus")
    secureLocalStorage.removeItem("useid")
    secureLocalStorage.removeItem("token")
    setshowuser({})
  }

  let searchproducts = (event) => {
    event.preventDefault()
    navigate(`/searchproducts`)
  }

  window.onscroll = function () { scrollFunction() };
  function scrollFunction() {
    var header = document.getElementById("headerr");
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      header.style.position = "fixed";
      header.style.top = "0";
    } else {
      header.style.position = "absolute";
      header.style.top = "0";
    }
  }

  // get user data
  const profiledata = useSelector(state => state.arr);
  useEffect(() => {
    getprofileuser()
  }, [showuser, userid])
  const getprofileuser = () => {
    try {
      let dataAbout = {
        userId: userid
      }
      const options = {
        headers: {
          token: token
        },
      };
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/getUser_profile`, dataAbout, options)
        .then((res) => {
          if (res.data.result) {
            setshowuser(res?.data?.data?.[0])
            dispatch(user_profile(res?.data?.data?.[0]));
          }

        })
        .catch((error) => {
        });

    } catch (error) {

    }

  };

  // get getcardlist liset
  useEffect(() => {
    if (userid != null) {
      getcardlist()
    }
  }, [storepricevalue]) // show_cardlist

  const arr = useSelector(state => state.arr);
  let getcardlist = () => {
    try {
      let data = {
        "userId": userid,
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/InnoutcartList`, data).then((res) => {
        // console.log(res)
        setshow_cardlist(res.data.data[0]?.products)
        dispatch(inntout_setcard_list(res.data.data[0]?.products))
        setstatus(res.data.data[0]?.status)

        if (res.status === 200) {
          let storeprice = 0
          for (let x of res.data.data[0]?.products) {
            storeprice += x.productId.sale_price
          }
          setstorepricevalue(storeprice)
        }
      }).catch((error) => {
        setshow_cardlist([])
        setstorepricevalue(0)

      })
    } catch (error) {
    }
  }



  // get address 
  useEffect(() => {
    getaddresshandel()
  }, [])
  const address = useSelector(state => state.arr);
  let getaddresshandel = () => {
    try {
      const options = {
        headers: {
          token: token
        },
      };
      let data = {
        "userId": userid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/getAddress`, data, options)
        .then((res) => {
          if (res.data.result) {
            dispatch(address_list(res?.data?.data));
          }
        })
        .catch((error) => {
          // console.log("erer", error)
        });
    } catch (error) {
    }
  }

  let [showcategorylist, setshowcategorylist] = useState()
  let inntcategory = secureLocalStorage.getItem("inntoutcategory")
  useEffect(() => {
    try {
      let data = {
        "maincategoryId": inntcategory
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
        // console.log(res.data.data)
        setshowcategorylist(res?.data?.data)
      }).catch((error) => {

      })
    } catch (error) {
    }
  }, [0])

  // /categories_products"
  let Productslist = (allproductsid) => {
    // console.log(allproductsid)
    secureLocalStorage.setItem("Inntoutcategories_productsid", allproductsid)
    navigate(`/Inntoutproductslist`)
  }


  // language translatar
  let [t, i18n] = useTranslation("global")
  let changelanguage = (lang: string) => {
    i18n.changeLanguage(secureLocalStorage.getItem("language"))
  }

  // search api call 
  let [showsuggetionvalue, setshowsuggetionvalue] = useState([])
  let [search, setsearch] = useState('')
  const [searchValue, setSearchValue] = useState(''); // State to hold the search valu
  let [serchobj, setserchobj] = useState(
    {
      "serachvalue": ""
    }
  )

  let getvaluehandel = (e) => {
    setserchobj({ ...serchobj, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    let data = {
      "key": serchobj?.serachvalue
    }
    let timer = setTimeout(() => {
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/searchInntoutProduct`, data).then((res) => {
        if (res.status === 200) {
          console.log(res?.data?.data)
          setshowsuggetionvalue(res?.data?.data)
        }
      }).catch((error) => {
      })
    }, 500);

    return () => {
      clearTimeout(timer)
    }
  }, [serchobj.serachvalue])

  const handleChange = (event, newValue) => {
    serchobj.serachvalue = newValue
    secureLocalStorage.setItem("searchvalue", newValue)
    navigate("/inntout_searchlist")
  }

  let handleFormSubmit = (event) => {
    event.preventDefault()
    secureLocalStorage.setItem("searchvalue", serchobj?.serachvalue)
    navigate("/inntout_searchlist")
  }


  // gotohome handel
  let gotohome = () => {
    navigate("/")
  }
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to update screen dimensions
  const updateScreenDimensions = () => {
    setScreenDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Update screen dimensions on resize
  useEffect(() => {
    window.addEventListener('resize', updateScreenDimensions);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateScreenDimensions);
    };
  }, []); // No dependency array means this runs once on mount and sets the event listener
  //   console.log("screenDimensions.width", screenDimensions.width <=500 ? "true":"false");






  return (
    <>
      {screenDimensions.width >= 1000 ? (
        <header className="box-shadow-sm rtl __inline-10 ">
          <div className="navbar-sticky bg-light mobile-head ">

            <div style={{ boxShadow: "gray 1.1px -1.8px 10.2px" }} className="navbar navbar-expand-md navbar-light headerfreez " id="headerr">
              <div className="container">
                <button className="navbar-toggler" type="button" onClick={gotohome}>
                  <FiCornerUpLeft style={{ fontSize: "25px" }} />
                </button>

                <Link className="navbar-brand d-none d-sm-block  flex-shrink-0 __min-w-7rem pl-3" to="/inntoutHome">
                  <img width={"35px"} className="__inline-11" src="./imglist/flag.png" />
                </Link>

                {/* <Link className="navbar-brand d-sm-none" to="/inntoutHome">
                <img className="mobile-logo-img __inline-12" src="./logo/newlogo15.jpeg" />
              </Link> */}

                <Link className="navbar-brand d-none d-md-none d-lg-block  d-sm-block mr-3 flex-shrink-0 __min-w-7rem p-0" to="/inntoutHome">
                  <img width={"55px"} height={"25px"} className="" src="./logo/newlogo15.jpeg" />
                  <small className='text-dark'> {t("navbar.deliverto")}   :
                    {userid?.length > 0 ? (
                      address?.addresdata?.length > 0 ? (
                        <>{profiledata?.userprofiledata?.first_name}&nbsp;{address?.addresdata[0]?.city_name}&nbsp;{address?.addresdata[0]?.pin_code}</>
                      ) : (
                        <>{profiledata?.userprofiledata?.first_name}&nbsp;{city}&nbsp;{pincode}</>
                      )
                    ) : ("burkina faso")}
                  </small>
                </Link>

                <div className="input-group-overlay mx-lg-4 search-form-mobile" style={{ textAlign: 'left' }}>

                  <div style={{ textAlign: 'right' }} className="d-lg-none">
                    <button className="btn close-search-form-mobile">
                      <i className="tio-clear" />
                    </button>
                  </div>

                  <form onSubmit={handleFormSubmit} className="search_form">
                    <Stack spacing={2} sx={{ width: "100%", borderRadius: "10px" }}>
                      <Autocomplete
                        style={{ backgroundColor: "white" }}
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        value={searchValue} // Set the value of the Autocomplete to the searchValue state
                        onChange={handleChange} // Handle change in the Autocomplete
                        options={showsuggetionvalue?.map((option) => option.product_name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='serachvalue'
                            onChange={getvaluehandel}
                            size="small"
                            value={search}
                            placeholder={t("navbar.searchhere_inntout")}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />

                    </Stack>
                    <input style={{ display: "none" }} className="form-control appended-form-control search-bar-input" type="text" autoComplete="off" placeholder="Search here..." name="name" />
                    <button className="input-group-append-overlay search_button" type="submit" style={{ borderRadius: '0px 7px 7px 0px', left: 'unset', right: 0, top: 0 }}>
                      <span className="input-group-text __text-20px">
                        <i className="czi-search text-white" />
                      </span>
                    </button>
                  </form>

                </div>
                {userid?.length > 0 ? (
                  <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">

                    {/* <div className="navbar-tool open-search-form-mobile d-lg-none ml-md-3 mr-2">
                    <Link className="navbar-tool-icon-box bg-secondary" to="/mobileproductssearch">
                      <i className="tio-search" />
                    </Link>
                  </div> */}
                    <div className="dropdown  d-none d-lg-block">
                      <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ paddingLeft: '.5rem' }}>

                        <b>{language == null ? ("Language") : (language == "en" ? <>   <img className="mr-2" width={20} src="./imglist/enlishlogo.png" alt="Eng" />English</> : <> <img width={20} className="mr-2" src="./imglist/logofrench.jpeg" />French</>)}</b>
                      </button>
                      <div className="dropdown-menu __dropdown-menu-3 __min-w-165px" aria-labelledby="dropdownMenuButton1" style={{ textAlign: 'left' }}>
                        <div onClick={() => changelanguage(secureLocalStorage.setItem("language", "en"))} className="dropdown-item" href="#">
                          <img className="mr-2" width={20} src="./imglist/enlishlogo.png" alt="english" />
                          English
                        </div>
                        <div className="dropdown-divider" />
                        <div onClick={() => changelanguage(secureLocalStorage.setItem("language", "fa"))} className="dropdown-item" >
                          <img width={20} className="mr-2" src="./imglist/logofrench.jpeg" /> French
                        </div>
                      </div>
                    </div>

                    <div id="cart_items">
                      <div className="navbar-tool">
                        <Link className=" d-none d-lg-block" to="/userprofile"  >
                          <div className="navbar-tool ml-3" >
                            <div className="mr-2" >
                              <span><b>{t("navbar.hello")},</b></span> <span><b>{profiledata?.userprofiledata?.first_name}</b></span>
                            </div>
                          </div>
                        </Link>
                        <Link className="navbar-tool-icon-box bg-secondary dropdown-toggle mr-2" to="/addtocardlist_inntout">
                          <span className="navbar-tool-label">
                            {arr?.inntoutcardlist?.length}
                          </span>
                          <i className="navbar-tool-icon czi-cart" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                    <form onSubmit={handleFormSubmit} className="search_form  signdata" style={{ width: "200px" }}>
                      <Stack spacing={2} sx={{ width: "100%", borderRadius: "10px" }}>
                        <Autocomplete
                          style={{ backgroundColor: "white" }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          value={searchValue} // Set the value of the Autocomplete to the searchValue state
                          onChange={handleChange} // Handle change in the Autocomplete
                          options={showsuggetionvalue?.map((option) => option.product_name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='serachvalue'
                              onChange={getvaluehandel}
                              size="small"
                              value={search}
                              placeholder={t("navbar.searchhere_inntout")}
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />

                      </Stack>
                      <input style={{ display: "none" }} className="form-control appended-form-control search-bar-input" type="text" autoComplete="off" placeholder="Search here..." name="name" />
                      <button className="signdata input-group-append-overlay search_button " type="submit" style={{ borderRadius: '0px 7px 7px 0px' }}>
                        <span style={{ backgroundColor: "rgb(20, 85, 172)", position: "absolute", right: "60px", top: "1px" }} className=" input-group-text __text-20px">
                          <i className="czi-search text-white" />
                        </span>
                      </button>
                    </form>

                    <style>

                      {`@media (min-width: 530px) {
                      .signdata {
                       display: none; /* Hide the nav-list when width is less than 530px */
                           }
                              }
                   `}
                    </style>

                    <div className="dropdown  d-none d-lg-block">
                      <button className="btn dropdown-toggle " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ paddingLeft: '.5rem' }}>
                        {/* <img className="mr-2" width={20} src="./imglist/enlishlogo.png" alt="Eng" /> */}
                        <b> {language == null ? ("Language") : (language == "en" ? <><img className="mr-2" width={20} src="./imglist/enlishlogo.png" alt="Eng" />English</> : <><img width={20} className="mr-2" src="./imglist/logofrench.jpeg" /> French</>)}</b>
                      </button>
                      <div className="dropdown-menu __dropdown-menu-3 __min-w-165px" aria-labelledby="dropdownMenuButton2" style={{ textAlign: 'left' }}>
                        <div onClick={() => changelanguage(secureLocalStorage.setItem("language", "en"))} className="dropdown-item" href="#">
                          <img className="mr-2" width={20} src="./imglist/enlishlogo.png" alt="english" />
                          English
                        </div>
                        <div className="dropdown-divider" />
                        <div onClick={() => changelanguage(secureLocalStorage.setItem("language", "fa"))} className="dropdown-item" >
                          <img width={20} className="mr-2" src="./imglist/logofrench.jpeg" /> French
                        </div>
                      </div>
                    </div>

                    <div id="cart_items" style={{ position: "relative" }}>
                      <div className="navbar-tool  " >
                        <div className=" d-none d-lg-block" >
                          <Link to="/login" className="navbar-tool ml-3" type="button" >
                            <div className="mr-2" >
                              <span><b>{t("navbar.hello")},</b></span> <span><b>sign in</b></span>
                            </div>
                          </Link>
                        </div>
                        <Link className="navbar-tool-icon-box bg-secondary dropdown-toggle" style={{ marginLeft: "75px" }} to="/login">
                          <i className="navbar-tool-icon czi-cart text-info" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}


              </div>
            </div>

            <div className="container" style={{ backgroundColor: "rgb(120, 165, 120)" }}>
              <div className='row justify-content-center'>
                <div className='col-lg-10'>
                  <div className="navbar navbar-expand-md " style={{ marginTop: "58px" }}>
                    <div className="">

                      <div className="" >

                        <ul className="d-flex" style={{ listStyle: "none" }} >
                          <li className="nav-item" >
                            <Link to="/inntoutHome" className="nav-link text-capitalize text-dark text-bold" style={{ paddingLeft: "0px", fontWeight: "bold" }}>
                              Home
                            </Link>
                          </li>
                          <li className="nav-item dropdown ">
                            <Link className="nav-link text-capitalize text-dark" to="/" style={{ fontWeight: "bold" }}>Back to innt</Link>
                          </li>
                          <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-dark " style={{ fontWeight: "bold" }} href="#" data-toggle="dropdown">Categories</a>
                            <ul className="dropdown-menu __dropdown-menu-sizing dropdown-menu-left scroll-bar" style={{ textAlign: 'left' }}>
                              {showcategorylist?.map((items, index) => {
                                return (
                                  <li key={index} style={{ cursor: "pointer" }} onClick={() => Productslist(items._id)} className="__inline-17">
                                    <div className='pl-3 pt-3 pb-3'>
                                      <div >
                                        {items.category_englishName}
                                      </div>
                                    </div>
                                  </li>
                                )
                              })}

                            </ul>
                          </li>

                          <li className="nav-item dropdown ">
                            <Link className="nav-link text-capitalize text-dark" to="/discountProduct_list_inntout" style={{ fontWeight: "bold" }}>Discounts</Link>
                          </li>
                          <li className="nav-item dropdown ">
                            <Link className="nav-link text-capitalize text-dark" to="/inntoutorderlist" style={{ fontWeight: "bold" }}>My Order</Link>
                          </li>
                        </ul>

                        <style>
                          {`
    @media (max-width: 768px) {
      .nav-link {
        font-size: x-small; /* Change to small font size on mobile */
        padding: 0px;
        padding-right: 15px;
      }
    }
  `}
                        </style>

                        <div className="logout-btn mt-auto d-md-none">
                          <hr />
                          <a style={{ cursor: "pointer" }} className="nav-link text-dark">
                            <strong className="text-base" onClick={logout}>Logout</strong>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </header>
      ) : (
        <Mobileview_inntout />
      )}

    </>
  )
}

export default Auth_header_inntout
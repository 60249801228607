import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useQuery } from '@tanstack/react-query';
import { MdOutlineStarPurple500 } from "react-icons/md";
import { IoIosStar } from 'react-icons/io';
import Todaydeal_skeleten from '../Skeleten/Todaydeal_skeleten';

const Customerfevorites = () => {

    const settings2 = {
        dots: false,
        infinite: true,
        speed: 700, // Increase the speed for smoother transitions
        arrows: false, // Hide the previous and next arrows
        autoplay: false, // Enable autoplay
        slidesToShow: 6, // Initially, show 4 slides
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Adjust the settings for screens with width less than 1024px
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 768, // Adjust the settings for screens with width less than 768px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480, // Adjust the settings for screens with width less than 480px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    const fetchProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/customer_favourite_list`);
        return response.data;
    };

    const { data: products, error, isLoading } = useQuery({
        queryKey: ['customer_favourite_list'],
        queryFn: fetchProducts,
    });


    const dispatch = useDispatch();
    let navigate = useNavigate()
    let [t, i18n] = useTranslation("global")
    let userid = secureLocalStorage.getItem("useid")
    let [SuggestedProduct, setSuggestedProduct] = useState([])


    // get wish list 
    useEffect(() => {
        getnewArrival_Product_list()
    }, [0])
    let getnewArrival_Product_list = () => {
        try {
            axios.get(`${process.env.REACT_APP_SECRET_KEY}/customer_favourite_list`).then((res) => {
                if (res.status == 200) {
                    setSuggestedProduct(res.data.data)
                }
            }).catch((error) => {
                // console.log(error)

            })
        } catch (error) {
        }



    }

    // Singleproducts 
    let Singleproducts = (items) => {
        console.log(items)
        secureLocalStorage.setItem("venderId", items?.venderId)
        secureLocalStorage.setItem("productId", items?.productId)
        navigate(`/singleprojects/${items?.productId}/${items?.venderId}`)
    }

    // viewallproducts
    let viewallproducts = () => {
        navigate("/customer_favouriteProducts_filter")
    }

    return (
        <>
            <div className="container py-4 px-md-3">
                <div className="__inline-62 pl-4 pr-4 pt-2 pb-4 deal_of_the_day1">
                    <div className="for-feature-title __text-22px font-bold mt-0 ml-1 mb-2" style={{ color: 'black', textAlign: "start" }}>
                        {t("navbar.customerFevorites")}
                        <div onClick={viewallproducts} className="text-capitalize" style={{ fontSize: "15px", color: '#1455ac', cursor: "pointer", float: "right" }}>
                            {t("navbar.viewall")}
                        </div>
                    </div>
                    <div>
                        {products?.data?.length > 0 ? (
                            <>
                                {products?.data?.length >= 6 ? (
                                    <Slider {...settings2}>
                                        {products?.data?.map((items, index) => {
                                            return (
                                                <div key={index} className='col-lg-12 ' onClick={() => Singleproducts(items)}>
                                                    {items?.discount === 0 ? ("") : (
                                                        <span className="for-discoutn-value p-1 pl-2 pr-2" style={{ position: "absolute", top: "1px", left: "5px" }}>
                                                            -{items?.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                        </span>
                                                    )}
                                                    <div className="product-single-hover style--card deal_of_the_day1 ">
                                                        <div className="overflow-hidden position-relative">
                                                            <div className=" inline_product clickable d-flex justify-content-center">
                                                                <div className="pb-0 text-center">
                                                                    <a className="w-90 text-center" style={{ paddingTop: "40px" }}>
                                                                        <LazyLoadImage
                                                                            className='img-fluid'
                                                                            width={"100%"}
                                                                            alt="Loding..."
                                                                            effect="blur"
                                                                            src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="single-product-details">
                                                                <div className="rating-show justify-content-between pl-2">
                                                                    <span className="d-inline-block font-size-sm text-body">
                                                                        {items?.rating === 0 ? ("") : ("")}

                                                                        {items?.rating === 1 ? (<IoIosStar className="tio-star text-warning" />) : ("")}
                                                                        {items?.rating === 2 ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                                        {items?.rating === 3 ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                                        {items?.rating === 4 ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                                        {items?.rating === 5 ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}


                                                                        {items?.rating === 1.5 ? (<>  <IoIosStar class="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                                        {items?.rating === 2.5 ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar class="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                                        {items?.rating === 3.5 ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar class="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                                        {items?.rating === 4.5 ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /><IoIosStar class="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                                        {/* <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" /> */}
                                                                        <label className="badge-style text-dark"> {items?.rating === 0 ? "" : `(${items?.rating})`} </label>
                                                                    </span>
                                                                </div>
                                                                <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className=" pl-2 pb-0">
                                                                    {items?.product_name?.slice(0, 15)}{items?.product_name?.length > 23 ? "..." : ""}
                                                                </div>

                                                                <div className="product-price   align-items-center gap-8  pl-2 pb-0">
                                                                    <strike className="" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                        CFA{items?.unit_price}
                                                                    </strike>
                                                                    <span className="text-accent text-dark">
                                                                        CFA{items?.sale_price}
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </Slider>
                                ) : (
                                    <div className="row">
                                        {products?.data?.map((items, index) => {
                                            return (
                                                <div key={index} className='col-lg-2 col-md-6 col-6' onClick={() => Singleproducts(items)}>
                                                    {items?.discount === 0 ? ("") : (
                                                        <span className="for-discoutn-value p-1 pl-2 pr-2" style={{ position: "absolute", top: "1px", left: "5px" }}>
                                                            -{items?.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                        </span>
                                                    )}
                                                    <div className="product-single-hover style--card" key={index} style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                        <div className="overflow-hidden position-relative">
                                                            <div className=" inline_product clickable d-flex justify-content-center">
                                                                <div className=" pb-0">
                                                                    <a className="w-100">
                                                                        <LazyLoadImage
                                                                            className='img-fluid'
                                                                            width={"100%"}
                                                                            alt="Loding..."
                                                                            effect="blur"
                                                                            src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="single-product-details">
                                                                <div className="rating-show justify-content-between pl-2">
                                                                    <span className="d-inline-block font-size-sm text-body">
                                                                        {items?.rating === 0 ? ("") : ("")}

                                                                        {items?.rating === 1 ? (<i className="tio-star text-warning" />) : ("")}
                                                                        {items?.rating === 2 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                                                        {items?.rating === 3 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                                                        {items?.rating === 4 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                                                        {items?.rating === 5 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}


                                                                        {items?.rating === 1.5 ? (<><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                                                        {items?.rating === 2.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                                                        {items?.rating === 3.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                                                        {items?.rating === 4.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                                                        {/* <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" />
                                                                <MdOutlineStarPurple500 className="tio-star-outlined text-warning" /> */}
                                                                        <label className="badge-style text-dark">( {items?.rating} )</label>
                                                                    </span>
                                                                </div>
                                                                <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                    {items?.product_name?.slice(0, 15)}{items?.product_name?.length > 23 ? "..." : ""}
                                                                </div>

                                                                <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                    <span className="text-accent text-dark">
                                                                        CFA{items?.sale_price}
                                                                    </span>
                                                                    <strike className="" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                        CFA{items?.unit_price}
                                                                    </strike><br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Todaydeal_skeleten />
                        )}

                    </div>
                </div>
            </div>

        </>
    )
}

export default Customerfevorites
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { useDispatch } from 'react-redux';
import { inntout_setcard_list, pathname_inntout } from '../../readux/arrSlice';
import { useQuery } from '@tanstack/react-query';
const Todayoffer_inntout = () => {

    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false, // Hide the previous and next arrows
        autoplay: false, // Enable autoplay
        slidesToShow: 6, // Initially, show 4 slides
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Adjust the settings for screens with width less than 1024px
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 768, // Adjust the settings for screens with width less than 768px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480, // Adjust the settings for screens with width less than 480px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const dispatch = useDispatch();
    let navigate = useNavigate()
    let [loderstatus, setloderstatus] = useState(true)
    let userid = secureLocalStorage.getItem("useid")
    let [lenthloader, setlenthloader] = useState(true)
    let [todaylist_productslist, settodaylist_productslist] = useState([])
    const fetchProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/inntoutToday_offerProduct_list`);
        if (response.status == 200) {
            setloderstatus(false)
            setlenthloader(false)
            // console.log("inner data", response?.data?.data )
            settodaylist_productslist(response?.data?.data)
            return response.data;
        }
    };

    const { data: products, error, isLoading } = useQuery({
        queryKey: ['inntoutToday_offerProduct_list'],
        queryFn: fetchProducts,
        onSuccess: (data) => {

            if (data && data.data) {
                // console.log('Fetched data:', data.data);
                // settodaylist_productslist(data.data); // Update the state with fetched data
              } else {
                // console.error('No data found');
              }
        },
        onError: (error) => {
            setloderstatus(false)
            setlenthloader(false)
        }
    });

    // console.log("productsdata",products?.data?.length==0? "not" :"yes")
    // console.log("productsdata", typeof products?.data)


    // Singleproducts 
    let location_path = useLocation();
    let Singleproducts = (items) => {
        secureLocalStorage.setItem("venderId", items?.venderId)
        secureLocalStorage.setItem("productId", items?.productId)
        navigate(`/singleprojects_inntout/${items?.productId}/${items?.venderId}`)
        secureLocalStorage.setItem("pagepath", location_path?.pathname)
        dispatch(pathname_inntout(location_path?.pathname))
    }

    let viewallproducts = () => {
        navigate("/todaysOffers_all")
    }

    return (
        <>
            <div className="container ">
                <div className='row justify-content-center mt-3'>
                    <div className='col-lg-10'>
                        <div className="__inline-62 p-4 " style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>

                            <div className="feature-product-title mt-0 ml-1 mb-3" style={{ color: "black", fontWeight: "700", textAlign: "start" }}>
                                Today's Offers
                                <div className="text-capitalize " onClick={viewallproducts} style={{ fontSize: "15px", color: "#1455ac", fontWeight: "700", cursor: "pointer", float: "right" }}>
                                    View All
                                </div>
                            </div>

                            <div className="">
                        {todaylist_productslist?.length ==0  ? (
                                    (loderstatus == true ? (
                                        <div className='text-center mt-4'>
                                            <div className='row justify-content-center'>
                                                <div className="spinner-border text-primary"></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='col-12 row  justify-content-center mt-2'>
                                            <div className='col-lg-4 col-12 text-center'>
                                                <div>
                                                    <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                                </div>
                                                <h6 className='mt-2'>Data Not Found</h6>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="">
                                        {products?.data?.length > 6 ? (
                                            <Slider {...settings2}>
                                                {products?.data?.map((items, index) => {
                                                    return (
                                                        <div onClick={() => Singleproducts(items)} key={index} className='col-lg-12 mb-2'>
                                                            {items.discount == 0 ? "" : (
                                                                <span className="for-discoutn-value p-1">
                                                                    -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                                </span>
                                                            )}
                                                            <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                                <div className="overflow-hidden position-relative">
                                                                    <div className=" inline_product clickable d-flex justify-content-center">
                                                                        {items.discount == 0 ? "" : (
                                                                            <span className="for-discoutn-value mt-2 ml-2 p-1 pl-2 pr-2">
                                                                                -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                                            </span>
                                                                        )}
                                                                        <div className=" pb-0">
                                                                            <Link className="w-100">
                                                                                <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                            </Link>
                                                                        </div>
                                                                        {/* <div className="quick-view">
                                                                            <span onClick={() => getproductsdetails(items)} className="btn-circle stopPropagation" data-toggle="modal" data-target="#todaydealsmyModal">
                                                                                <i className="czi-eye align-middle" />
                                                                            </span>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="single-product-details">
                                                                        <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                            {items?.product_name.slice(0, 17)}{items?.product_name?.length > 23 ? "..." : ""}
                                                                        </div>

                                                                        <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                            <span className="text-accent text-dark">
                                                                                CFA{items?.sale_price}
                                                                            </span>
                                                                            <strike className="pl-1" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                                CFA{items?.unit_price}
                                                                            </strike><br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </Slider>
                                        ) : (
                                            <div className='row'>
                                                {products?.data?.map((items, index) => {
                                                    return (
                                                        <div onClick={() => Singleproducts(items)} key={index} className='col-lg-2 col-md-6 col-6  mb-2'>
                                                            {items.discount == 0 ? "" : (
                                                                <span className="for-discoutn-value p-1">
                                                                    -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                                </span>
                                                            )}
                                                            <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                                <div className="overflow-hidden position-relative">
                                                                    <div className=" inline_product clickable d-flex justify-content-center">
                                                                        <div className=" pb-0">
                                                                            <Link className="w-100">
                                                                                <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                            </Link>
                                                                        </div>
                                                                        {/* <div className="quick-view">
                                                                        <span onClick={() => getproductsdetails(items)} className="btn-circle stopPropagation" data-toggle="modal" data-target="#todaydealsmyModal">
                                                                            <i className="czi-eye align-middle" />
                                                                        </span>
                                                                    </div> */}
                                                                    </div>
                                                                    <div className="single-product-details">
                                                                        <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                            {items?.product_name.slice(0, 17)}{items?.product_name?.length > 23 ? "..." : ""}
                                                                        </div>

                                                                        <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                            <span className="text-accent text-dark">
                                                                                CFA{items?.sale_price}
                                                                            </span>
                                                                            <strike className="pl-1" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                                CFA{items?.unit_price}
                                                                            </strike><br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )}




                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Todayoffer_inntout
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { Typography } from '@mui/material'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { FaHeart } from "react-icons/fa";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import toast, { Toaster } from "react-hot-toast";
import { TfiLayoutMenuSeparated } from "react-icons/tfi";
import Popover from '@mui/material/Popover';
import swal from 'sweetalert';
import Footer from './Footer';
import { MdDiscount } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { setcard_list, setwish_list } from '../../readux/arrSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
const Dealofdaysproductslist = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate()
    let userid = secureLocalStorage.getItem("useid")
    let inntcategory = secureLocalStorage.getItem("inntcategory")
    let viewallproductsdata = JSON.parse(secureLocalStorage.getItem("viewallproductsdata"))
    let [todaydeals_Product_list, settodaydeals_Product_list] = useState([])
    let [showproductsdetails, setshowproductsdetails] = useState([])
    let [mainproductsimg, setmainproductsimg] = useState("")
    let [storquntityvalue, setstorquntityvalue] = useState("")
    let [loderstatus, setloderstatus] = useState(true)
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);
    const [priceGap] = useState(500);
    let [filtervalueobj, setfiltervalueobj] = useState(
        {
            "productIds": [],
            "categoryId": "",
            "rating": "",
            "price": minPrice,
            "price1": "" ? 1000 : 1000,
            "discount": "",
            "status": "1"
        }
    )
    let [showcategorylist, setshowcategorylist] = useState()
    let [attributes, setattributes] = useState([])

    // social media toggel
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'popover-button' : undefined;



    const fetchProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/dayOftheDeals_ProductList`);
        if (response.data.data) {
            return response.data.data;
        }
        else if (response.data.data?.length == 0) {
            setloderstatus(false)
        }
    };

    const { data: products, error, isLoading } = useQuery({
        queryKey: ['dayOftheDeals_ProductList'],
        queryFn: fetchProducts,
        onError: (error) => {
            setloderstatus(false)
        }
    });




    // get wish list 
    useEffect(() => {
        getnewArrival_Product_list()
    }, [0])
    let getnewArrival_Product_list = () => {
        try {
            axios.get(`${process.env.REACT_APP_SECRET_KEY}/dayOftheDeals_ProductList`).then((res) => {
                // console.log(res.data)
                if (res.status == 200) {
                    settodaydeals_Product_list(res.data.data)
                    let newid = res?.data?.data?.map(item => item.productId);
                    filtervalueobj.productIds = newid
                }
            }).catch((error) => {
                // console.log(error)

            })
        } catch (error) {
        }

    }

    // get products details
    let locationdata = JSON.parse(secureLocalStorage.getItem("latlon"))
    let getproductsdetails = (items) => {
        // console.log("hello", hello)
        try {
            let data = {
                "userId": userid,
                "productId": items?.productId,
                "shopId": items?.venderId,
                "lat": locationdata?.lat,
                "lon": locationdata?.lon
            }
            // console.log(data)
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, data).then((res) => {
                // console.log(res.data.data)
                setshowproductsdetails(res?.data?.data)
                setmainproductsimg(res?.data?.data[0]?.images?.[0])
                setattributes(res?.data?.data?.[0]?.attributes)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }

    // attribute handel
    const [extractedValues, setExtractedValues] = useState([]);
    const getattribute = (newvalue, index, items) => {
        console.log("items", items)
        const updatedValues = [...extractedValues];
        updatedValues[index].value = newvalue;
        // console.log(updatedValues[index].value)
        console.log("updatedValues", updatedValues)
        setExtractedValues(updatedValues);
    }
    useEffect(() => {
        const values = attributes.map(item => ({
            name: item.attribute_name,
            value: item.attribute_values[0]
        }));
        // console.log("extractedValues", extractedValues)
        setExtractedValues(values);
    }, [attributes]);


    // Singleproducts 
    let Singleproducts = (items) => {
        secureLocalStorage.setItem("venderId", items?.venderId)
        secureLocalStorage.setItem("productId", items?.productId)
        navigate(`/singleprojects/${items?.productId}/${items?.venderId}`)
    }

    // like dishlike handel
    let livedisclikehandel = (items) => {
        try {
            let data = {
                "userId": userid,
                "productId": items.productId,
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/like_dislike_product_api`, data).then((res) => {
                // console.log(res.data.result)
                if (res.data.result) {
                    toast.success(`${res.data.message}`);

                    try {
                        let datashow = {
                            "userId": userid,
                            "productId": items?.productId,
                            "shopId": items?.venderId
                        }
                        // console.log(data)
                        axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, datashow).then((res) => {
                            // console.log(res.data.data)
                            setshowproductsdetails(res?.data?.data)
                            setmainproductsimg(res?.data?.data[0]?.images?.[0])
                            let datawishlist = {
                                "userId": userid
                            }
                            axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, datawishlist).then((res) => {
                                if (res.status == 200) {
                                    dispatch(setwish_list(res.data.data));
                                }
                            }).catch((error) => {
                                dispatch(setwish_list([]));
                            })
                        }).catch((error) => {
                        })
                    } catch (error) {
                    }
                }
            }).catch((error) => {
            })
        } catch (error) {
        }
    }

    // add to card addtocardhandele
    let addtocardhandele = (productId) => {
        try {
            let data = {
                "userId": userid,
                "productId": productId,
                "attributes": extractedValues,
                "qty": storquntityvalue !== "" ? (storquntityvalue) : ("1"),
                "status": "0",
            }
            // console.log(data)
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/Addcart`, data).then((res) => {
                // console.log(res.data.result)
                if (res.data.result) {
                    toast.success(`${res.data.message}`);
                    let cartlistdata = {
                        "userId": userid,
                        "status": "0"
                    }
                    axios.post(`${process.env.REACT_APP_SECRET_KEY}/cartList`, cartlistdata).then((res) => {
                        if (res.status === 200) {
                            dispatch(setcard_list(res.data.data[0]?.products));
                        }
                    }).catch((error) => {
                        dispatch(setcard_list([]));
                    })
                }
            }).catch((error) => {
                // console.log(error)
                if (error.response && error.response.status === 400) {
                    toast.error(`${error.response.data.message}`);
                }
            })
        } catch (error) {
        }
    }

    // login handel
    let UsersingnHandel = () => {
        swal({
            title: "Please Sign in ?",
            text: "You need to Sign in to view this feature",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            // content: {
            //     element: "input",
            //     attributes: {
            //         placeholder: "Enter your reason for deletion",
            //         type: "text",
            //     },
            // },
        }).then((willDelete) => {
            if (willDelete) {
                deleteaddress();
            } else {
            }
        });
        let deleteaddress = () => {
            navigate("/login")
        }
    }

    // go to cart
    let gotocarthandel = () => {
        setTimeout(() => {
            navigate("/addtocard")
        }, 500);
    }


    // price range handel
    const handlePriceInputChange = (e) => {
        const inputName = e.target.name;
        let newValue = parseInt(e.target.value);
        if (newValue < 0) {
            newValue = 0;
        } else if (newValue > 10000) {
            newValue = 10000;
        } else if (inputName === 'minPrice' && newValue > maxPrice - priceGap) {
            newValue = maxPrice - priceGap;
        } else if (inputName === 'maxPrice' && newValue < minPrice + priceGap) {
            newValue = minPrice + priceGap;
        }

        if (inputName === 'minPrice') {
            setMinPrice(newValue);
            filtervalueobj.price = newValue
        } else {
            setMaxPrice(newValue);
            filtervalueobj.price1 = newValue
        }
    };


    // category handel
    useEffect(() => {
        try {
            let data = {
                "maincategoryId": inntcategory
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
                // console.log(res.data.data)
                setshowcategorylist(res?.data?.data)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }, [0])


    // filter value handel
    let filtervalue = (event) => {
        // console.log(event.target.value)
        setfiltervalueobj({ ...filtervalueobj, [event.target.name]: event.target.value })
    }

    // filter products 
    let filterproducts = (event) => {
        event.preventDefault()
        console.log("hello boss", filtervalueobj)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/dayOfthedealsProducts_filter`, filtervalueobj).then((res) => {
            console.log(res?.data)
            settodaydeals_Product_list(res?.data?.data)
        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <>
            <Toaster />
            {products?.length > 0 ? (
                <div className="container py-4 rtl px-0 px-md-3">
                    <div className="__inline-62 p-4">

                        <div className="feature-product-title mt-0 ml-1 mb-4" style={{ color: '#1455ac', textAlign: "start" }}>
                            Today's Deals
                        </div>

                        <div className='row mb-3'>
                            <div className='col-lg-2'>
                                <div className="sorting-item">
                                    <BiSolidCategory />

                                    <label className="for-shoting" htmlFor="sorting">
                                        <span className="text-nowrap">Category</span>
                                    </label>
                                    <select onChange={filtervalue} name='categoryId'>
                                        <option hidden>Select</option>
                                        {showcategorylist?.map((items, index) => {
                                            return (
                                                <option value={items._id}>{items.category_englishName}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className="sorting-item">
                                    <MdDiscount />
                                    <label className="for-shoting" htmlFor="sorting">
                                        <span className="text-nowrap">Discount</span>
                                    </label>
                                    <select onChange={filtervalue} name='discount'>
                                        <option hidden>Select</option>
                                        <option value="5">5%</option>
                                        <option value="10">10%</option>
                                        <option value="15">15%</option>
                                        <option value="20">20%</option>
                                        <option value="25">25%</option>
                                        <option value="30">30%</option>

                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <div className="sorting-item">
                                    <i className="tio-star text-warning" />
                                    <label className="for-shoting" htmlFor="sorting">
                                        <span className="text-nowrap">Reaview</span>
                                    </label>
                                    <select onChange={filtervalue} name='rating'>
                                        <option value="latest" hidden>Select</option>
                                        <option value="1">1<i className="tio-star text-warning" /></option>
                                        <option value="2">2<i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>
                                        <option value="3">3<i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>
                                        <option value="4">4<i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>
                                        <option value="5">5<i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>

                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-2'>
                                <small><b>Min:</b> {minPrice}</small> <br />
                                <input
                                    type="range"
                                    className="min-range"
                                    min="0"
                                    max="10000"
                                    value={minPrice}
                                    step="1"
                                    onChange={handlePriceInputChange}
                                    name="minPrice"
                                />
                            </div>
                            <div className='col-lg-2'>
                                <small><b>Max:</b> {maxPrice}</small><br />
                                <input
                                    type="range"
                                    className="max-range"
                                    min="0"
                                    max="10000"
                                    value={maxPrice}
                                    step="1"
                                    onChange={handlePriceInputChange}
                                    name="maxPrice"
                                />
                            </div>
                            <div className='col-lg-2'>
                                <form >

                                    <div className="text-start">
                                        <button onClick={filterproducts} className="btn--primary btn">
                                            Search <i className="fa fa-search" aria-hidden="true" />
                                        </button>
                                    </div>
                                </form>

                            </div>
                            <div>

                            </div>
                        </div>

                        <div className="">
                            <div className="">
                                <div className="row" id="">
                                    {products?.map((items, index) => {
                                        return (
                                            <div key={index} className=" col-lg-2 col-md-6 col-sm-4 col-6  p-2 " onClick={() => Singleproducts(items)}>
                                                {items?.discount === 0 ? ("") : (
                                                    <span className="for-discoutn-value p-1 pl-2 pr-2">
                                                        -{items?.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                    </span>
                                                )}
                                                <div className="product-single-hover style--card deal_of_the_day1 ">
                                                    <div className="overflow-hidden position-relative">
                                                        <div className=" inline_product clickable d-flex justify-content-center">
                                                            {/* onClick={() => getproductsdetails(items)} data-toggle="modal" data-target="#viewallmyModal" */}
                                                            <div className="pb-0 text-center">
                                                                <a className="w-90 text-center" style={{ paddingTop: "40px" }}>
                                                                    <img width={"80%"} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="single-product-details">
                                                            <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                {items.product_name?.slice(0, 20)}{items.product_name?.length > 23 ? "..." : ""}
                                                            </div>

                                                            <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                <strike className="pl-1 pr-2" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                    CFA{items.unit_price}
                                                                </strike>
                                                                <span className="text-accent text-dark">
                                                                    CFA{items.sale_price}
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {loderstatus == true ? (
                        <div className='row justify-content-center mt-5 mb-5'>
                            <div className="spinner-border text-primary"></div>
                        </div>
                    ) : (
                        <div className='col-12 row  justify-content-center mt-5 mb-5'>
                            <div className='col-lg-4 col-12 text-center'>
                                <div>
                                    <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                </div>
                                <h6 className='mt-2'>Data Not Found</h6>
                            </div>
                        </div>
                    )}
                </>
            )}

            <Footer />
        </>
    )
}

export default Dealofdaysproductslist
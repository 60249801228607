import React, { useEffect, useState } from 'react'
import Footer from '../Home_page/Footer';
import { Link, useNavigate } from 'react-router-dom'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from "react-hot-toast";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';
import { MdDiscount } from 'react-icons/md';
import { IoIosStar } from 'react-icons/io';
import { useQuery } from '@tanstack/react-query';
import { BiSolidCategory } from 'react-icons/bi';


const Categories_products_inntout = () => {
  const dispatch = useDispatch();
  let userid = secureLocalStorage.getItem("useid")
  let inntcategory = secureLocalStorage.getItem("inntcategory")
  let Inntoutcategories_productsid = secureLocalStorage.getItem("Inntoutcategories_productsid")
  let [show_allproducts_category, setshow_allproducts_category] = useState([])
  let [pricelowhigh, setpricelowhigh] = useState([])
  let [gethighprice, setgethighprice] = useState()
  let [getlowprice, setgetlowprice] = useState()
  let navigate = useNavigate()
  let [loderstatus, setloderstatus] = useState(true)
  const [visibleBottom, setVisibleBottom] = useState(false);
  let [showcategory, setshowcategory] = useState([])


  // main category list
  useEffect(() => {
    try {
      let data = {
        "maincategoryId": inntcategory
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
        setshowcategory(res?.data?.data)
      }).catch((error) => {
      })
    } catch (error) {
    }
    setTimeout(() => {
      setloderstatus(false)
    }, 3000);
  }, [0])

  // get products with id
  useEffect(() => {
    getcategoriesproduts()
  }, [Inntoutcategories_productsid])
  let getcategoriesproduts = () => {
    try {
      let data = {
        "categoryId": Inntoutcategories_productsid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/categoryBase_productList`, data).then((res) => {
        setshow_allproducts_category(res?.data?.data)
        setfilterloader(true)
        setpricelowhigh(res?.data?.data)
      }).catch((error) => {

      })
    } catch (error) {
    }
  }


  // Singleproducts 
  let Singleproducts = (items) => {
    // console.log(items.venderId)
    // console.log(items.productId)
    secureLocalStorage.setItem("venderId", items?.venderId)
    secureLocalStorage.setItem("productId", items?.productId)
    navigate(`/singleprojects_inntout/${items?.productId}/${items?.venderId}`)
  }


  // price voice prroducts listing 
  let lowtohigh = (e) => {
    handleOpen()
    if (e.target.value === "low-high") {
      let newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => a.sale_price - b.sale_price);
      setshow_allproducts_category(newallshow_allproducts_category);
    } else if (e.target.value === "high-low") {
      let newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => b.sale_price - a.sale_price);
      setshow_allproducts_category(newallshow_allproducts_category);
    } else if (e.target.value === "a-z") {
      const newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => {
        return a.product_name.localeCompare(b.product_name);
      });
      setshow_allproducts_category(newallshow_allproducts_category);
    } else if (e.target.value === "z-a") {
      const newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => {
        return b.product_name.localeCompare(a.product_name);
      });
      setshow_allproducts_category(newallshow_allproducts_category);
    }
  }

  // price getlowhighhandel 
  let getlowhighhandel = (e) => {
    e.preventDefault()
    handleOpen()
    let newallshow_allproducts_category = pricelowhigh.filter((item) => item.sale_price >= getlowprice && item.sale_price <= gethighprice)
    setshow_allproducts_category(newallshow_allproducts_category);
  }

  // window loader 
  const [open1, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    autoclose()
  };
  let autoclose = () => {
    setTimeout(() => {
      setOpen(false)
    }, 500);
  }



  let inntoutcategory = secureLocalStorage.getItem("inntoutcategory")
  let [showcategoryinntout, setshowcategoryinntout] = useState([])

  useEffect(() => {
    try {
      let data = {
        "maincategoryId": inntoutcategory
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
        // console.log(res?.data?.data)
        setshowcategoryinntout(res?.data?.data)
      }).catch((error) => {

      })
    } catch (error) {
    }
  }, [0])

  // /categories_products"
  let Productslist = (allproductsid) => {
    // console.log(allproductsid)
    setfilterloader(false)
    secureLocalStorage.setItem("Inntoutcategories_productsid", allproductsid)
    navigate(`/Inntoutproductslist`)
    setVisibleBottom(false)
  }

  const fetchProducts = async () => {
    let data = {
      "maincategoryId": inntoutcategory
    }
    const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data);
    return response.data;
  };

  const { data: products, error, isLoading } = useQuery({
    queryKey: ['InntCategory_list'],
    queryFn: fetchProducts,
  });


  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [priceGap] = useState(500);

  const [min_distance, setmin_distance] = useState(0);
  const [max_distance, setmax_distance] = useState(1000);
  const [priceGap1] = useState(500);
  let [filterloader, setfilterloader] = useState(true)
  let [changeproducts, setchangeproducts] = useState(true)
  let [filter_products, setfilter_products] = useState([])
  let locationdata = JSON.parse(secureLocalStorage.getItem("latlon"))
  let [filtervalueobj, setfiltervalueobj] = useState(
    {
      "categoryId": Inntoutcategories_productsid,
      "price1": "0",
      "price": "0",
      "discount": "0",
      "rating": "0",
      "status": "2",// Add a vali,
      "lat": locationdata?.lat,
      "lon": locationdata?.lon,
      "distances1": "0",
      "distances2": "0"
    }
  )


  // price range handel
  const handlePriceInputChange = (e) => {
    const inputName = e.target.name;
    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 10000) {
      newValue = 10000;
    } else if (inputName === 'minPrice' && newValue > maxPrice - priceGap) {
      newValue = maxPrice - priceGap;
    } else if (inputName === 'maxPrice' && newValue < minPrice + priceGap) {
      newValue = minPrice + priceGap;
    }

    if (inputName === 'minPrice') {
      setMinPrice(newValue);
      filtervalueobj.price = newValue
    } else {
      setMaxPrice(newValue);
      filtervalueobj.price1 = newValue
    }
  };

  // destance min max handel 
  const distances_handlePriceInputChange = (e) => {
    const inputName = e.target.name;
    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 10000) {
      newValue = 10000;
    } else if (inputName === 'distances1' && newValue > max_distance - priceGap1) {
      newValue = max_distance - priceGap1;
    } else if (inputName === 'distances2' && newValue < min_distance + priceGap1) {
      newValue = min_distance + priceGap1;
    }

    if (inputName === 'distances1') {
      setmin_distance(newValue);
      filtervalueobj.distances1 = newValue
    } else {
      setmax_distance(newValue);
      filtervalueobj.distances2 = newValue
    }
  };

  // filter value handel
  let filtervalue = (event) => {
    // console.log(event.target.value)
    setfiltervalueobj({ ...filtervalueobj, [event.target.name]: event.target.value })
  }

  // filter products 
  let filterproducts = (event) => {
    event.preventDefault()
    setfilterloader(false)
    console.log("hello dataaa", filtervalueobj)
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntoutProductFilter`, filtervalueobj).then((res) => {
      console.log(res?.data)
      if (res?.data?.data?.length == 0) {
        setchangeproducts(true)
        setfilterloader(true)
        toast.error("Product data not found")
      } else {
        setfilter_products(res?.data?.data)
        setfilterloader(true)
        setchangeproducts(false)
      }

    }).catch((error) => {
      console.log(error)
      setchangeproducts(true)
      setfilterloader(true)
      toast.error(error?.response?.data?.message)
    })
  }



  return (
    <div>



      <div className="card">
        <PrimeSidebar style={{ height: "500px" }} visible={visibleBottom} position="bottom" onHide={() => setVisibleBottom(false)}>
          <div className="text-center mt-2">
            <form method='post'>
              <div className="__cate-side-title pt-0">
                <span className="widget-title font-semibold">Price </span>
              </div>
              <div className="d-flex justify-content-between align-items-center __cate-side-price">
                <div className="__w-35p">
                  <input required onChange={(e) => setgetlowprice(e.target.value)} className="bg-white cz-filter-search form-control form-control-sm appended-form-control" type="number" min={0} max={1000000} placeholder='Min' />
                </div>
                <div className="__w-10p">
                  <p className="m-0">To</p>
                </div>
                <div className="__w-35p">
                  <input required onChange={(e) => setgethighprice(e.target.value)} min={100} max={1000000} className="bg-white cz-filter-search form-control form-control-sm appended-form-control" type="number" placeholder='Max' />
                </div>
                <div className="d-flex justify-content-center align-items-center __number-filter-btn">
                  {getlowprice?.length > 0 && gethighprice?.length > 0 ? (
                    <button className='btn btn-info' onClick={getlowhighhandel} type='submit' >
                      <i className="__inline-37 czi-arrow-right" />
                    </button>
                  ) : (
                    <button className='btn btn-info' type='submit' style={{ backgroundColor: "gray" }} >
                      <i className="__inline-37 czi-arrow-right" />
                    </button>
                  )}

                </div>
              </div>
            </form>
          </div>
          <div className=" row justify-content-center">
            <div className='col-lg-12'>
              <Sidebar style={{ width: "100%" }}>
                <Menu>
                  {showcategoryinntout?.map((items, index) => {
                    return (
                      <MenuItem key={index} onClick={() => Productslist(items._id)}>{items.category_englishName}</MenuItem>
                    )
                  })}
                </Menu>
              </Sidebar>
            </div>
          </div>
        </PrimeSidebar>
      </div>

      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open1}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Toaster
      />

      <div className="container py-3" dir="ltr">
        <div className="search-page-header1">
          <div>
            <h5 className="font-semibold mb-1">Categories</h5>
            {/* <div className="view-page-item-count">53 Items found</div> */}
          </div>
          <form >
            <input hidden name="data_from" defaultValue="discounted" />
            <div className="sorting-item">
              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} viewBox="0 0 20 21" fill="none">
                <path d="M11.6667 7.80078L14.1667 5.30078L16.6667 7.80078" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.91675 4.46875H4.58341C4.3533 4.46875 4.16675 4.6553 4.16675 4.88542V8.21875C4.16675 8.44887 4.3533 8.63542 4.58341 8.63542H7.91675C8.14687 8.63542 8.33341 8.44887 8.33341 8.21875V4.88542C8.33341 4.6553 8.14687 4.46875 7.91675 4.46875Z" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.91675 11.9688H4.58341C4.3533 11.9688 4.16675 12.1553 4.16675 12.3854V15.7188C4.16675 15.9489 4.3533 16.1354 4.58341 16.1354H7.91675C8.14687 16.1354 8.33341 15.9489 8.33341 15.7188V12.3854C8.33341 12.1553 8.14687 11.9688 7.91675 11.9688Z" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.1667 5.30078V15.3008" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <label className="for-shoting" htmlFor="sorting">
                <span>Sort by</span>
              </label>
              <select onChange={lowtohigh}>
                <option value="latest">Latest</option>
                <option value="low-high">Low to High Price </option>
                <option value="high-low">High to Low Price</option>
                <option value="a-z">A to Z Order</option>
                <option value="z-a">Z to A Order</option>
              </select>
            </div>
          </form>
          <div className="d-lg-none">
            <div className="filter-show-btn btn btn--primary py-1 px-2 m-0">
              <i className="tio-filter" onClick={() => setVisibleBottom(true)} />
            </div>
          </div>
        </div>
        <div className="search-page-header">
          <div className='row justify-content-between'>
            <div className='col-lg-2   text-center'>
              <div className="sorting-item">
                <BiSolidCategory />

                <label className="for-shoting" htmlFor="sorting">
                  <span className="text-nowrap">Category</span>
                </label>
                <select onChange={filtervalue} name='categoryId'>
                  <option hidden>Select</option>
                  {products?.data?.map((items, index) => {
                    return (
                      <option key={index} value={items._id}>{items.category_englishName}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-lg-2   text-center'>
              <div className="sorting-item">
                <MdDiscount />
                <label className="for-shoting" htmlFor="sorting">
                  <span className="text-nowrap">Discount</span>
                </label>
                <select onChange={filtervalue} name='discount'>
                  <option hidden>Select</option>
                  <option value="5">5%</option>
                  <option value="10">10%</option>
                  <option value="15">15%</option>
                  <option value="20">20%</option>
                  <option value="25">25%</option>
                  <option value="30">30%</option>

                </select>
              </div>
            </div>
            <div className='col-lg-2 text-center'>
              <div className="sorting-item">
                <IoIosStar className="tio-star text-warning" />
                <label className="for-shoting" htmlFor="sorting">
                  <span className="text-nowrap">Reaview</span>
                </label>
                <select onChange={filtervalue} name='rating'>
                  <option value="latest" hidden>Select</option>
                  <option value="1">1<IoIosStar className="tio-star text-warning" /></option>
                  <option value="2">2<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                  <option value="3">3<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                  <option value="4">4<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                  <option value="5">5<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>

                </select>
              </div>
            </div>
            <div className='col-lg-2 text-center'>
              <small><b>Min Distances:</b> {min_distance}</small> <br />
              <input
                type="range"
                className="min-range"
                min="0"
                max="10000"
                value={min_distance}
                step="1"
                onChange={distances_handlePriceInputChange}
                name="distances1"
              />
            </div>
            <div className='col-lg-2 text-center'>
              <small><b>Max Distances:</b> {max_distance}</small><br />
              <input
                type="range"
                className="max-range"
                min="0"
                max="10000"
                value={max_distance}
                step="1"
                onChange={distances_handlePriceInputChange}
                name="distances2"
              />
            </div>
          </div>

          <div className='row mt-4 mb-2'>
            <div className='col-lg-2 text-center'>
              <small><b>Min Price:</b> {minPrice}</small> <br />
              <input
                type="range"
                className="min-range"
                min="0"
                max="10000"
                value={minPrice}
                step="1"
                onChange={handlePriceInputChange}
                name="minPrice"
              />
            </div>
            <div className='col-lg-2 text-center'>
              <small><b>Max Price:</b> {maxPrice}</small><br />
              <input
                type="range"
                className="max-range"
                min="0"
                max="10000"
                value={maxPrice}
                step="1"
                onChange={handlePriceInputChange}
                name="maxPrice"
              />
            </div>
            <div className='col-lg-2 text-center'>
              <form >

                <div className="text-start">
                  <button onClick={filterproducts} className="btn--primary btn">
                    Search <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
              </form>

            </div>
            <div>
            </div>
          </div>

        </div>
      </div>

      <div className="container pb-5 mb-2 mb-md-4 rtl __inline-35" dir="ltr">
        <div className="row">

          <aside className="col-lg-3 hidden-xs col-md-3 col-sm-4 SearchParameters __search-sidebar pr-2" id="SearchParameters">
            <div className="cz-sidebar __inline-35" id="shop-sidebar">
              <div className="cz-sidebar-header bg-light">
                <button className="close ml-auto" type="button" data-dismiss="sidebar" aria-label="Close">
                  <i className="tio-clear" />
                </button>
              </div>
              <div className="text-center">

                <form method='post mt-2 className="sorting-item"'>
                  <div className="">
                    <span className="widget-title font-semibold">Price </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center __cate-side-price">
                    <div className="__w-35p">
                      <input required onChange={(e) => setgetlowprice(e.target.value)} className="bg-white cz-filter-search form-control form-control-sm appended-form-control" type="number" min={0} max={1000000} placeholder='Min' />
                    </div>
                    <div className="__w-10p">
                      <p className="m-0">To</p>
                    </div>
                    <div className="__w-35p">
                      <input required onChange={(e) => setgethighprice(e.target.value)} min={100} max={1000000} className="bg-white cz-filter-search form-control form-control-sm appended-form-control" type="number" placeholder='Max' />
                    </div>
                    <div className="d-flex justify-content-center align-items-center __number-filter-btn">
                      {getlowprice?.length > 0 && gethighprice?.length > 0 ? (
                        <a onClick={getlowhighhandel} type='submit' >
                          <i className="__inline-37 czi-arrow-right" />
                        </a>
                      ) : (
                        <a type='submit' style={{ backgroundColor: "gray" }} >
                          <i className="__inline-37 czi-arrow-right" />
                        </a>
                      )}

                    </div>
                  </div>
                </form>

              </div>
              <div className=" row justify-content-center">
                <div className='col-lg-8'>
                  <Sidebar>
                    <Menu>
                      {showcategoryinntout?.map((items, index) => {
                        return (
                          <MenuItem key={index} onClick={() => Productslist(items._id)}>{items.category_englishName}</MenuItem>
                        )
                      })}
                    </Menu>
                  </Sidebar>
                </div>
              </div>
            </div>
          </aside>

          <section className="col-lg-9">
            {filterloader == true ? (
              <>
                {changeproducts == true ? (
                  <>
                    {show_allproducts_category?.length > 0 ? (
                      <div className="row" id="ajax-products">
                        {show_allproducts_category?.map((items) => {
                          return (
                            <div className=" col-lg-3 col-md-4 col-sm-4 col-6  p-2" onClick={() => Singleproducts(items)}>
                              <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                <div className="overflow-hidden position-relative">
                                  <div className=" inline_product clickable d-flex justify-content-center">
                                    {items.discount == 0 ? "" : (
                                      <span className="for-discoutn-value p-1">
                                        -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                      </span>
                                    )}
                                    <div className="pb-0">
                                      <a className="w-100">
                                        <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                      </a>
                                    </div>
                                    {/* <div className="quick-view">
                              <span onClick={() => getproductsdetails(items)}>
                                <i className="czi-eye align-middle" />
                              </span>
                            </div> */}
                                  </div>
                                  <div className="single-product-details">
                                    <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                      {items.product_name.slice(0, 20)}{items.product_name?.length > 23 ? "..." : ""}
                                    </div>

                                    <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                      <strike className="pl-1 pr-2" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                        CFA{items.unit_price}
                                      </strike>
                                      <span className="text-accent text-dark">
                                        CFA{items.sale_price}
                                      </span>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      loderstatus == true ? (
                        <div className='row justify-content-center mt-5'>
                          <div className="spinner-border text-primary"></div>
                        </div>
                      ) : (
                        <div className='col-12 row  justify-content-center mt-3'>
                          <div className='col-lg-4 col-12 text-center'>
                            <div>
                              <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                            </div>
                            <h6 className='mt-2'>Data Not Found</h6>
                          </div>
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <div className="row">
                      {filter_products?.map((items, index) => {
                        return (
                          <div onClick={() => Singleproducts(items)} key={index} className='col-lg-2 col-md-6 col-6'>
                            {items.discount == 0 ? "" : (
                              <span className="for-discoutn-value p-1">
                                -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                              </span>
                            )}
                            <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                              <div className="overflow-hidden position-relative">
                                <div className=" inline_product clickable d-flex justify-content-center">
                                  <div className=" pb-0">
                                    <a className="w-100">
                                      <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                    </a>
                                  </div>
                                  {/* <div className="quick-view">
                                                                        <span onClick={() => getproductsdetails(items)} className="btn-circle stopPropagation" data-toggle="modal" data-target="#todaydealsmyModal">
                                                                            <i className="czi-eye align-middle" />
                                                                        </span>
                                                                    </div> */}
                                </div>
                                <div className="single-product-details">
                                  <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                    {items?.product_name.slice(0, 17)}{items?.product_name?.length > 23 ? "..." : ""}
                                  </div>

                                  <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                    <span className="text-accent text-dark">
                                      CFA{items?.sale_price}
                                    </span>
                                    <strike className="pl-1" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                      CFA{items?.unit_price}
                                    </strike><br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className='row justify-content-center mt-5 mb-5'>
                <div className="spinner-border text-primary"></div>
              </div>
            )}



          </section>
        </div>
      </div>
      <Footer />
    </div>



  )
}

export default Categories_products_inntout
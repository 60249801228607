import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BsChatDots, BsQrCode } from "react-icons/bs";
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import moment from 'moment';
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";

import Footer from '../Home_page/Footer';
import { useQuery } from '@tanstack/react-query';
import QRCode from 'react-qr-code';
import { BsDownload } from "react-icons/bs";
import { PiShareFat } from "react-icons/pi";
import html2canvas from 'html2canvas';

const Products_order = () => {
    let navigate = useNavigate()
    let userid = secureLocalStorage.getItem("useid")
    let [showorderlist, setshoworderlist] = useState()
    let [loderstatus, setloderstatus] = useState(true)
    let [openqrcode, setopenqrcode] = useState("")
    let [orderId, setorderId] = useState(true)
    const qrRef = useRef(null);

    // get getorderlist list
    const fetchProducts = async () => {
        let data = {
            "userId": userid,
        }
        const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntorderList`, data)
        if (response?.status === 200) {
            if (response?.data.data?.length == 0) {
                setloderstatus(false)
            }
            return response.data?.data;
        }
    };

    const { data: products, error, isLoading } = useQuery({
        queryKey: ['InntorderList'],
        queryFn: fetchProducts,
    });

    // 
    useEffect(() => {
        getorderlist()
    }, [0])
    let getorderlist = () => {
        try {
            let data = {
                "userId": userid,
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/orderList`, data).then((res) => {
                console.log(res?.data?.data)
                if (res.status === 200) {
                    setshoworderlist(res?.data?.data)
                    if (res.data.data?.length == 0) {
                        setloderstatus(false)
                    }
                }
            }).catch((error) => {

            })
        } catch (error) {
        }
    }

    // order datails 
    let orderdetails = (orderid, itemsobj) => {
        secureLocalStorage.setItem("products_id", itemsobj?.productId)
        secureLocalStorage.setItem("order_id", orderid)
        navigate("/inntout_orderdetails")
    }

    // vendor chat handel
    let gotochat = (shopobj) => {
        // console.log("imgdata",shopobj?.shopId)
        let data = {
            "shopid": shopobj?.shopId?._id,
            "name": shopobj?.shopId?.firstName,
            "lastname": shopobj?.shopId?.lastName,
            "img": shopobj?.shopId?.shop_logo,
            "status": 1,
        }
        secureLocalStorage.setItem("chatvendorid", JSON.stringify(data))
        navigate("/inbox")
    }


    // image download qr code 
    const handleDownload = () => {
        const element = document.getElementById('screenshotContainer');
        html2canvas(element).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'qr-code-screenshot.png';
            link.click();
        });
    };

    return (
        <div>
            <div className="container py-4 rtl">
                <div className="row justify-content-center">
                    <section className="col-lg-10 __customer-profile">
                        <div className="d-flex align-items-start justify-content-between gap-2 pb-3">
                            <div>
                                <div className="d-flex align-items-center gap-2 text-capitalize">
                                    <h4 className="text-capitalize mb-0 mobile-fs-14">My Orders</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card __card  d-lg-flex" dir="ltr">
                            <div className="card-body ">
                                <div className='row justify-content-center'>
                                    {products?.length > 0 ? (
                                        products?.map((items_obj, index) => {
                                            console.log(items_obj)
                                            const formattedDateTime = moment(items_obj?.updatedAt).format('YYYY-MM-DD HH:mm:ss');
                                            const formattedDate = moment(items_obj?.updatedAt).format('MMMM D, YYYY');
                                            return (
                                                <div key={index} className='col-lg-12 col-md-12 col-12 mb-2 rounded' style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                    <div className="p-2  gap-2">
                                                        <div className=" mb-2">
                                                            {items_obj.products?.map((items, index) => {
                                                                return (
                                                                    <>
                                                                        <div key={index} className='row jsutify-content-between mb-2'>
                                                                            <div className='col-lg-4 text-center'>
                                                                                <img width={"40%"} onClick={() => orderdetails(items_obj._id, items)} className="border" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image}`} alt="img/products" />
                                                                            </div>
                                                                            <div className='col-lg-8'>

                                                                                <div className="cont text-start">
                                                                                    <h6 className="font-weight-bold mb-2 fs-14" style={{ float: "right", backgroundColor: "#e3e3ed", padding: "5px" }}>
                                                                                        <a>
                                                                                            Order Id: <span style={{ color: "gray" }}>{items_obj.orderId}</span>
                                                                                        </a>
                                                                                    </h6>
                                                                                    <h6 className="font-weight-bold m-t-2 mb-2 fs-14">
                                                                                        <a>
                                                                                            Order Date: <span style={{ color: "gray" }}>{formattedDateTime}</span>
                                                                                        </a>
                                                                                    </h6>
                                                                                    <h6 className="font-weight-bold m-t-2 mb-2 fs-14">
                                                                                        <a>
                                                                                            Delivery Status: <span style={{ color: "gray" }}>Ordered</span>
                                                                                        </a>
                                                                                    </h6>
                                                                                    <h6 className="font-weight-bold m-t-2 mb-2 fs-14">
                                                                                        <a>
                                                                                            Delivery Code: <span style={{ color: "gray" }}>{items_obj?.orderVerification_code}</span>
                                                                                        </a>
                                                                                    </h6>

                                                                                </div>
                                                                                <div className="wishlist-text">
                                                                                    <div className="font-name  mb-2">
                                                                                        <a> Product Name: <span style={{ color: "gray" }}>{items?.product_name}</span></a>
                                                                                    </div>

                                                                                    <h6 className="font-weight-bold m-t-2 mb-2 fs-14">
                                                                                        {items?.attributes?.map((items, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <a key={index} className='mb-2'>
                                                                                                        {items?.name}: <span style={{ color: "gray" }}>{items.value}</span>
                                                                                                    </a>
                                                                                                    <br />
                                                                                                </>
                                                                                            )
                                                                                        })}

                                                                                    </h6>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}

                                                            <div className='row justify-content-center mb-2' >
                                                                <div className="cont text-start mt-2 col-lg-9 col-md-8 col-12">
                                                                    <div>
                                                                        <div onClick={() => gotochat(items_obj)} style={{ border: "1px solid", cursor: "pointer" }} className="mr-1  badge text-info">
                                                                            <MdOutlineMarkUnreadChatAlt /> Chat
                                                                        </div>
                                                                        {/* <span style={{ border: "1px solid" }} className="mr-1 badge text-warning">

                                                  <IoIosStar style={{ marginBottom: "3px" }} /> Review
                                                </span> */}
                                                                        {/* <span style={{ border: "1px solid" }} className="mr-1 badge text-info">
                                                  <MdLiveTv style={{ marginBottom: "3px" }} /> Innt live
                                                </span> */}
                                                                        {/* <span onClick={() => orderagin(items)} style={{ border: "1px solid",cursor:"pointer" }} className="mr-1 badge  text-dark">
                                                  <HiOutlineArrowPathRoundedSquare style={{ marginBottom: "3px" }} /> Order Again
                                                </span> */}
                                                                        <div className="ml-2" data-toggle="modal" data-target="#myModalorderlistinntout" onClick={() => (setorderId(items_obj?.orderId), setopenqrcode(items_obj?.qrcodes))} style={{ float: "right", cursor: "pointer" }}>
                                                                            <BsQrCode style={{ color: "black" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    ) : (
                                        loderstatus == true ? (
                                            <div className='row justify-content-center'>
                                                <div className="spinner-border text-primary"></div>
                                            </div>
                                        ) : (
                                            <div className='col-12 row  justify-content-center mt-2'>
                                                <div className='col-lg-4 col-12 text-center'>
                                                    <div>
                                                        <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                                    </div>
                                                    <h6 className='mt-2'>Data Not Found</h6>
                                                </div>
                                            </div>
                                        )

                                    )}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />

            <div class="modal fade" id="myModalorderlistinntout">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">

                            <div id='screenshotContainer' className='p-3'>
                                <div className="cont text-center mb-2">
                                    <span className="font-weight-bold mb-2 fs-14" style={{ backgroundColor: "#e3e3ed", padding: "5px" }}>
                                        <span>
                                            Order Id: <span style={{ color: "gray" }}>{orderId}</span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }} ref={qrRef}>
                                    <QRCode
                                        size={256} // This defines the resolution of the QR code
                                        style={{ width: '100%', height: 'auto' }} // This makes it responsive
                                        value={openqrcode}
                                        viewBox="0 0 256 256"
                                    />
                                </div>
                            </div>

                            <div className='row justify-content-center col-12 mt-3'>
                                <div onClick={handleDownload} className='col-lg-6 col-6 text-center' >
                                    <BsDownload style={{ fontSize: "50px" }} /> <br />
                                    Save
                                </div>

                                {/* <div className='col-lg-6 col-6 text-center' >
    <PiShareFat style={{ fontSize: "50px" }} /> <br />
    Share
  </div> */}
                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </div >


    )
}

export default Products_order
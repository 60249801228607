import { Skeleton } from '@mui/material'
import React from 'react'

const Category_Sceleten = () => {
    return (
        <>
            <div className='col-lg-2 col-md-6 col-6 mb-2' >
                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                    <div className="overflow-hidden position-relative">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6 mb-2' >
                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                    <div className="overflow-hidden position-relative">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6 mb-2' >
                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                    <div className="overflow-hidden position-relative">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6 mb-2' >
                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                    <div className="overflow-hidden position-relative">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6 mb-2' >
                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                    <div className="overflow-hidden position-relative">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6 mb-2' >
                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                    <div className="overflow-hidden position-relative">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category_Sceleten
import React, { useEffect, useRef } from 'react'
import Footer from '../Home_page/Footer'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import { useState } from 'react'
import toast, { Toaster } from "react-hot-toast";
import ReactAudioPlayer from 'react-audio-player';

const Vendorchating = () => {
    let userid = secureLocalStorage.getItem("useid")
    let localdata = secureLocalStorage.getItem("chatvendorid")
    let getchatlistid = JSON.parse(localdata) || {}
    // console.log("getchatlistid", getchatlistid?.status == 1 ? "vendor" : "friend")
    // console.log("getchatlistid", typeof getchatlistid)


    let [chatlist, setchatlist] = useState()
    let [messagehandel, setmessagehandel] = useState(null)
    let [showvendor, setshowvendor] = useState({})
    const [file, setFile] = useState(null);
    const [imgvalue, setimgvalue] = useState(null);

    useEffect(() => {
        getvendorchatlisthandel()
    }, [getchatlistid?.status, chatlist]) // getchatlistid, chatlist


    // get vendor chat list 
    let getvendorchatlisthandel = () => {
        if (getchatlistid?.status == 1) {
            try {
                let data = {
                    // "venderId": getchatlistid?.shopid,
                    // "userId": userid

                    "userId": "663ccc1516080a087e7b3f22",
                    "venderId": "65f0232ff91f54c9cd41cab6"

                }
                console.log(data)
                axios.post(`${process.env.REACT_APP_SECRET_KEY}/userGet_message`, data).then((res) => {
                    if (res.status == 200) {
                        console.log("users list", res.data.data)
                        setshowvendor(res?.data?.data[0])
                        setchatlist(res?.data?.data)
                    }
                }).catch((error) => {
                    console.log(error)
                })
            } catch (error) {
            }
        } else {
            try {
                let data = {
                    "friendId": getchatlistid?.friendId,
                    "userId": userid
                }
                // console.log(data)
                axios.post(`${process.env.REACT_APP_SECRET_KEY}/userGet_message`, data).then((res) => {
                    if (res.status == 200) {
                        console.log("friends list", res.data.data)
                        setshowvendor(res?.data?.data[0])
                        setchatlist(res?.data?.data)
                    }
                }).catch((error) => {
                    // console.log(error)
                })
            } catch (error) {
            }

        }


    }

    // image file handel
    const handleFileChange = (event) => {
        setimgvalue(event.target.files[0])
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileUrl = URL.createObjectURL(selectedFile);
            setFile({ url: fileUrl, name: selectedFile.name, type: selectedFile.type });
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setimgvalue(null)
    };

    const renderFile = () => {
        if (!file) return null;

        const { url, name, type } = file;

        if (type.startsWith('image/')) {
            return <img src={url} alt={name} style={{ width: "100px", height: "100px", margin: "5px" }} />;
        } else if (type.startsWith('video/')) {
            return <video src={url} controls style={{ width: "200px", height: "150px", margin: "5px" }} />;
        } else {
            return (
                <a href={url} download={name} style={{ display: "block", margin: "5px" }}>
                    {name}
                </a>
            );
        }
    };


    // user send message 
    let sendmessagehandel = (event) => {
        event.preventDefault()
        if (getchatlistid.status == 1) {
            if (imgvalue != null || messagehandel != null) {
                try {
                    console.log("Vendorrrr")
                    let data = {
                        "venderId": getchatlistid?.shopid,
                        "userId": userid,
                        "text": imgvalue != null ? imgvalue : messagehandel,
                        "send_status": "1"
                    }
                    console.log(data)

                    let formdata = new FormData()
                    formdata.append("venderId", data.venderId)
                    formdata.append("userId", data.userId)
                    formdata.append("text", imgvalue != null ? imgvalue : messagehandel)
                    formdata.append("send_status", data.send_status)
                    // console.log(formdata)
                    // let form = e.target
                    // let formdata = new FormData(form)
                    let obj = Object.fromEntries(formdata.entries())
                    console.log(obj)

                    axios.post(`${process.env.REACT_APP_SECRET_KEY}/userSend_message`, formdata).then((res) => {
                        if (res.status == 200) {
                            console.log(res.data)
                            toast(res.data.message)
                            setmessagehandel("")
                            getvendorchatlisthandel()
                            setimgvalue(null)
                            // setchatlist(res?.data?.data)
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                } catch (error) {
                }
            } else {

            }
        } else {
            if (imgvalue != null || messagehandel != null) {
                try {
                    console.log("firendssssssss")
                    let data = {
                        "friendId": getchatlistid?.friendId,
                        "userId": userid,
                        "text": imgvalue != null ? imgvalue : messagehandel,
                        "send_status": "3"
                    }
                    console.log(data)
                    axios.post(`${process.env.REACT_APP_SECRET_KEY}/userSend_message`, data).then((res) => {
                        if (res.status == 200) {
                            // console.log(res.data)
                            toast(res.data.message)
                            setmessagehandel("")
                            getvendorchatlisthandel()
                            // setchatlist(res?.data?.data)
                        }
                    }).catch((error) => {
                        // console.log(error)
                    })
                } catch (error) {
                }
            } else {
                // console.log("not")
            }
        }


    }



    const scrollRef = useRef(null);
    const [messages, setMessages] = useState([
        { name: "user", status: 1 },
        { name: "user", status: 1 },
        { name: "vendor", status: 3 },
        { name: "user", status: 1 },
        { name: "vendor", status: 3 },
        { name: "vendor", status: 3 },
    ]);

    // Function to simulate adding a new message
    const addMessage = () => {
        // Simulate adding a message, alternating between user and vendor
        const newMessage = Math.random() > 0.5
            ? { name: "user", status: 1 }
            : { name: "vendor", status: 3 };
        setMessages([...messages, newMessage]);
    };

    useEffect(() => {
        // Scroll to the bottom of the div when the component mounts or messages change
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <>
            <Toaster />
            <div className="row justify-content-center" style={{ height: "500px" }}>
                <section className="msger">
                    <header className="msger-header">
                        <div className="msger-header-title">
                            <div className="col-12 position-relative">
                                <div to="#" className="d-block">
                                    <div className="d-flex __seller-author align-items-center">
                                        <div >
                                            {getchatlistid?.img == "" ? (
                                                <>
                                                    <img className="__img-60 img-circle" src="./imglist/chatlogo.jpg" /> <span> &nbsp;{getchatlistid.firstName} &nbsp; {getchatlistid.lastName}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <img className="__img-60 img-circle" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${getchatlistid?.img}`} /> &nbsp;<span>{getchatlistid.name} &nbsp; {getchatlistid.lastname}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="msger-header-options">

                        </div>
                    </header>
                    <main className="msger-chat" >
                        {getchatlistid?.status == 1 ? (
                            chatlist?.map((items, index) => {
                                {/* console.log(typeof items.status) */ }
                                return (
                                    <div key={index}>
                                        {items?.send_status === 1 ? (
                                            <div className="msg right-msg mt-1 mb-1">
                                                <div className="msg-bubble">
                                                    <div className="msg-info">
                                                        <div className="msg-info-name">User</div>
                                                        <div className="msg-info-time">12:46</div>
                                                    </div>
                                                    <div className="msg-text">
                                                        {
                                                            items?.text &&
                                                            items?.text &&
                                                            (items?.text?.endsWith(".jpg") ||
                                                                items?.text?.endsWith(".png") ||
                                                                items?.text?.endsWith(".jpeg") ||
                                                                items?.text?.endsWith(".bmp") ||
                                                                items?.text?.endsWith(
                                                                    ".webp"
                                                                ) ? (
                                                                <img
                                                                    className="rounded"
                                                                    src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                    width={120}
                                                                    alt="Image"
                                                                />
                                                            ) : items?.text?.endsWith(
                                                                ".wmv"
                                                            ) ||
                                                                items?.text?.endsWith(
                                                                    ".mp4"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mov"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".avi"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".flv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mkv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mts"
                                                                ) ? (
                                                                <video
                                                                    className="rounded"
                                                                    width={200}
                                                                    height={180}
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                        type="video/mp4"
                                                                    />
                                                                    Your browser does not support
                                                                    the video tag.
                                                                </video>
                                                            )
                                                                : items?.text?.endsWith(
                                                                    ".wmv"
                                                                ) ||
                                                                    items?.text?.endsWith(".mp3") ? (
                                                                    <ReactAudioPlayer
                                                                        className=" text-white rounded px-3 py-2 mb-1"
                                                                        width={200}
                                                                        height={180}
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}/${items?.text}`}
                                                                        controls
                                                                    />
                                                                )
                                                                    : (
                                                                        <p className="bg-chat rounded px-3 py-2 mb-1">
                                                                            {items?.text}
                                                                        </p>
                                                                    ))}
                                                        {/* <div className="wishlist-img position-relative">
                                                        <a className="d-block h-100">
                                                            <img className="__img-full" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`} alt="wishlist" />
                                                        </a>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="msg left-msg  mt-1 mb-1">

                                                <div className="msg-bubble">
                                                    <div className="msg-info">
                                                        <div className="msg-info-name">Vendor</div>
                                                        <div className="msg-info-time">12:45</div>
                                                    </div>
                                                    <div className="msg-text">
                                                        {items &&
                                                            items?.text &&
                                                            items?.text &&
                                                            (items?.text?.endsWith(".jpg") ||
                                                                items?.text?.endsWith(".png") ||
                                                                items?.text?.endsWith(".jpeg") ||
                                                                items?.text?.endsWith(".bmp") ||
                                                                items?.text?.endsWith(
                                                                    ".webp"
                                                                ) ? (
                                                                <img
                                                                    className="rounded"
                                                                    src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                    width={120}
                                                                    alt="Image"
                                                                />
                                                            ) : items?.text?.endsWith(
                                                                ".wmv"
                                                            ) ||
                                                                items?.text?.endsWith(
                                                                    ".mp4"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mov"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".avi"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".flv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mkv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mts"
                                                                ) ? (
                                                                <video
                                                                    className="rounded"
                                                                    width={200}
                                                                    height={180}
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                        type="video/mp4"
                                                                    />
                                                                    Your browser does not support
                                                                    the video tag.
                                                                </video>
                                                            ) :
                                                                items?.text?.endsWith(".mp3") ? (
                                                                    <ReactAudioPlayer
                                                                        className=" text-white rounded px-3 py-2 mb-1"
                                                                        width={200}
                                                                        height={180}
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}/${items?.text}`}
                                                                        controls
                                                                    />
                                                                )
                                                                    : (
                                                                        <p className="bg-chat rounded px-3 py-2 mb-1">
                                                                            {items?.text}
                                                                        </p>
                                                                    ))}
                                                        {/* <div className="wishlist-img position-relative">
                                                        <a className="d-block h-100">
                                                            <img className="__img-full" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`} alt="wishlist" />
                                                        </a>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        ) : (
                            chatlist?.map((items, index) => {
                                {/* console.log(typeof items.status) */ }
                                return (
                                    <div key={index}>
                                        {items?.send_status == "Sent" ? (
                                            <div className="msg right-msg mt-1 mb-1">
                                                <div className="msg-bubble">
                                                    <div className="msg-info">
                                                        <div className="msg-info-name">User</div>
                                                        <div className="msg-info-time">12:46</div>
                                                    </div>
                                                    <div className="msg-text">
                                                        {
                                                            items?.text &&
                                                            items?.text &&
                                                            (items?.text?.endsWith(".jpg") ||
                                                                items?.text?.endsWith(".png") ||
                                                                items?.text?.endsWith(".jpeg") ||
                                                                items?.text?.endsWith(".bmp") ||
                                                                items?.text?.endsWith(
                                                                    ".webp"
                                                                ) ? (
                                                                <img
                                                                    className="rounded"
                                                                    src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                    width={120}
                                                                    alt="Image"
                                                                />
                                                            ) : items?.text?.endsWith(
                                                                ".wmv"
                                                            ) ||
                                                                items?.text?.endsWith(
                                                                    ".mp4"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mov"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".avi"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".flv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mkv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mts"
                                                                ) ? (
                                                                <video
                                                                    className="rounded"
                                                                    width={200}
                                                                    height={180}
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                        type="video/mp4"
                                                                    />
                                                                    Your browser does not support
                                                                    the video tag.
                                                                </video>
                                                            )
                                                                : items?.text?.endsWith(
                                                                    ".wmv"
                                                                ) ||
                                                                    items?.text?.endsWith(".mp3") ? (
                                                                    <ReactAudioPlayer
                                                                        className=" text-white rounded px-3 py-2 mb-1"
                                                                        width={200}
                                                                        height={180}
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}/${items?.text}`}
                                                                        controls
                                                                    />
                                                                )
                                                                    : (
                                                                        <p className="bg-chat rounded px-3 py-2 mb-1">
                                                                            {items?.text}
                                                                        </p>
                                                                    ))}
                                                        {/* <div className="wishlist-img position-relative">
                                                        <a className="d-block h-100">
                                                            <img className="__img-full" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`} alt="wishlist" />
                                                        </a>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>


                                        ) : (
                                            <div className="msg left-msg  mt-1 mb-1">

                                                <div className="msg-bubble">
                                                    <div className="msg-info">
                                                        <div className="msg-info-name">Vendor</div>
                                                        <div className="msg-info-time">12:45</div>
                                                    </div>
                                                    <div className="msg-text">
                                                        {items &&
                                                            items?.text &&
                                                            items?.text &&
                                                            (items?.text?.endsWith(".jpg") ||
                                                                items?.text?.endsWith(".png") ||
                                                                items?.text?.endsWith(".jpeg") ||
                                                                items?.text?.endsWith(".bmp") ||
                                                                items?.text?.endsWith(
                                                                    ".webp"
                                                                ) ? (
                                                                <img
                                                                    className="rounded"
                                                                    src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                    width={120}
                                                                    alt="Image"
                                                                />
                                                            ) : items?.text?.endsWith(
                                                                ".wmv"
                                                            ) ||
                                                                items?.text?.endsWith(
                                                                    ".mp4"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mov"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".avi"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".flv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mkv"
                                                                ) ||
                                                                items?.text?.endsWith(
                                                                    ".mts"
                                                                ) ? (
                                                                <video
                                                                    className="rounded"
                                                                    width={200}
                                                                    height={180}
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.text}`}
                                                                        type="video/mp4"
                                                                    />
                                                                    Your browser does not support
                                                                    the video tag.
                                                                </video>
                                                            ) :
                                                                items?.text?.endsWith(".mp3") ? (
                                                                    <ReactAudioPlayer
                                                                        className=" text-white rounded px-3 py-2 mb-1"
                                                                        width={200}
                                                                        height={180}
                                                                        src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}/${items?.text}`}
                                                                        controls
                                                                    />
                                                                )
                                                                    : (
                                                                        <p className="bg-chat rounded px-3 py-2 mb-1">
                                                                            {items?.text}
                                                                        </p>
                                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        )}


                    </main>
                    <form className="msger-inputarea">
                        <input type="file" id="fileget" style={{ display: "none" }} multiple onChange={handleFileChange} />
                        <label htmlFor="fileget">
                            <i className="fa fa-plus-square-o" style={{ fontSize: "30px", cursor: "pointer" }} aria-hidden="true"></i>
                        </label>
                        {imgvalue != null ? (
                            <div className="msger-input" style={{ display: "inline-block", position: "relative" }}>
                                {renderFile()}
                                {file && (
                                    <i
                                        onClick={handleRemoveFile}
                                        style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
                                        className="fa fa-times"
                                        aria-hidden="true"
                                    ></i>
                                )}
                            </div>
                        ) : (
                            <input type="text" value={messagehandel} className="msger-input" onChange={(event) => setmessagehandel(event.target.value)} placeholder="Enter your message..." />
                        )}

                        {imgvalue != null || messagehandel != null ? (
                            <button type="submit" onClick={sendmessagehandel} className="msger-send-btn">Send</button>
                        ) : (
                            <button type="submit" disabled className="msger-send-btn">Send</button>
                        )}
                    </form>
                </section>

            </div>

            <div>
                <div
                    className="msger-chat"
                    ref={scrollRef}
                    style={{
                        border: "1px solid",
                        width: "300px",
                        height: "400px",
                        overflow: "scroll",
                        padding: "10px",
                    }}
                >
                    {messages.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                justifyContent: item.status === 1 ? "flex-end" : "flex-start",
                                marginBottom: "10px"
                            }}
                        >
                            <p
                                style={{
                                    backgroundColor: item.status === 1 ? "#e0e0e0" : "#c0c0c0",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    maxWidth: "200px",
                                    textAlign: "left"
                                }}
                            >
                                {item.name}
                            </p>
                        </div>
                    ))}
                </div>
                <button onClick={addMessage}>Add Message</button>
            </div>
            <Footer />
        </>
    )
}

export default Vendorchating
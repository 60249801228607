import React, { useEffect } from 'react'
import Footer from '../Home_page/Footer'
import axios from 'axios'
import { useState } from 'react'

const Refund_policy = () => {

  let [showdata, setshowdata] = useState({})
  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_SECRET_KEY}/refund_policy`).then((res) => {
        // console.log(res)
        if (res.data.result) {
          setshowdata(res.data.data[0])
        }
      }).catch((err) => {

      })

    } catch (error) {

    }
  })
  return (
    <div>
      <div className="__inline-60">
        <div className="container rtl">
          <div className="row">
            <div className="col-md-12 sidebar_heading text-center mb-2">
              <h1 className="h3  mb-0 folot-left headerTitle">{showdata?.title}</h1>
            </div>
          </div>
          <hr />
        </div>
        <div className="container pb-5 mb-2 mb-md-4 mt-3 rtl">
          <div className="row justify-content-center">
            <section className="col-lg-10 mt-3">
              <section className="container pt-4 pb-5 ">
                <div className="row pt-4" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: showdata?.title }} />
              </section>
            </section>
          </div>
        </div>
      </div>
      <Footer />


    </div>
  )
}

export default Refund_policy 
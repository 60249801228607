import { Skeleton } from '@mui/material'
import React from 'react'

const TopSellers_skeleten = () => {
    return (
        <div className='row'>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 '>
                <div className="others-store-card p-1 deal_of_the_day1 mb-2 ">
                    <Skeleton width="100%" height="200px"></Skeleton>
                </div>
            </div>

        </div>
    )
}

export default TopSellers_skeleten
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from './Footer';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FaHeart } from "react-icons/fa";
import { TfiLayoutMenuSeparated } from "react-icons/tfi";
import { FcSalesPerformance } from "react-icons/fc";
import { FaRegEye } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import toast, { Toaster } from "react-hot-toast";
import swal from 'sweetalert';
import { setcard_list, setwish_list } from '../../readux/arrSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IoIosStar } from "react-icons/io";
import { Backdrop, CircularProgress } from '@mui/material';

const Singleproducts = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  const { pid, vid } = useParams();


  let venderId = secureLocalStorage.getItem("venderId")
  let productId = secureLocalStorage.getItem("productId")
  let userid = secureLocalStorage.getItem("useid")
  let [showproductsdetails, setshowproductsdetails] = useState([])
  let [mainproductsimg, setmainproductsimg] = useState("")
  let [technicalissue, settechnicalissue] = useState(true)
  const [location, setLocation] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'popover-button' : undefined;

  useEffect(() => {
    getsingleproducs()
    // if (productId == null) {
    //   navigate("/")
    // }
  }, [venderId, productId, userid])

  // const locationdata = useSelector(state => state.arr);
  let locationdata = JSON.parse(secureLocalStorage.getItem("latlon"))
  let getsingleproducs = () => {
    try {

      let data = {
        "userId": userid,
        "productId": pid,
        "shopId": vid,
        "lat": locationdata?.lat,
        "lon": locationdata?.lon
      }

      axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, data).then((res) => {
        settechnicalissue(true)
        setattributes(res?.data?.data?.[0]?.attributes)
        setshowproductsdetails(res?.data?.data)
        setmainproductsimg(res?.data?.data[0]?.images?.[0])
      }).catch((error) => {
        settechnicalissue(false)
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  let [attributes, setattributes] = useState([])
  // get attribute handel 
  const [extractedValues, setExtractedValues] = useState([]);
  const getattribute = (newvalue, index, items) => {
    // console.log("items", items)
    const updatedValues = [...extractedValues];
    updatedValues[index].value = newvalue;
    // console.log(updatedValues[index].value)
    console.log("updatedValues", updatedValues)
    setExtractedValues(updatedValues);
  }
  useEffect(() => {
    const values = attributes.map(item => ({
      name: item.attribute_name,
      value: item.attribute_values[0]
    }));
    // console.log("extractedValues", extractedValues)
    setExtractedValues(values);
  }, [attributes]);

  // like dishlike handel
  let [like_status, setlike_status] = useState(true)
  let likedisclikehandel = (items) => {
    setlike_status(false)
    try {
      let data = {
        "userId": userid,
        "productId": items.productId,
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/like_dislike_product_api`, data).then((res) => {
        getsingleproducs()
        toast.success(`${res.data.message}`);
        setlike_status(true)
        let datawishlist = {
          "userId": userid
        }
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, datawishlist).then((res) => {
          if (res.status == 200) {
            dispatch(setwish_list(res.data.data));

          }
        }).catch((error) => {
          dispatch(setwish_list([]));
        })
      }).catch((error) => {
        setlike_status(true)
      })
    } catch (error) {
    }
  }

  let [storecolorvalue, setcolorhandel] = useState("")
  let [storsizevalue, setstorsizevalue] = useState("")
  let [storquntityvalue, setstorquntityvalue] = useState("")

  let [t, i18n] = useTranslation("global")
  // add to card addtocardhandele
  let addtocardhandele = (productId) => {
    handleOpen()
    try {
      let data = {
        "userId": userid,
        "productId": productId,
        "attributes": extractedValues,
        "qty": storquntityvalue !== "" ? (storquntityvalue) : ("1"),
        "status": "0",
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/Addcart`, data).then((res) => {
        // console.log(res)
        if (res.data.result) {
          autoclose()
          toast.success(`${res.data.message}`);
          let cartlistdata = {
            "userId": userid,
            "status": "0"
          }
          axios.post(`${process.env.REACT_APP_SECRET_KEY}/cartList`, cartlistdata).then((res) => {
            if (res.status === 200) {
              dispatch(setcard_list(res.data.data[0]?.products));
              autoclose()
            }
          }).catch((error) => {
            console.log(error)
            autoclose()
            dispatch(setcard_list([]));
          })
        }
      }).catch((error) => {
        autoclose()
        if (error.response && error.response.status === 400) {
          toast.error(`${error.response.data.message}`);
        }
      })
    } catch (error) {
    }
  }

  // buy products 
  let BuyNowproduct = (objitems) => {
    let Quantity = storquntityvalue !== "" ? (storquntityvalue) : 1
    let storeobj = []
    let productobj = {
      "productId": objitems[0]?.productId,
      "shopId": objitems[0]?.venderId,
      "image": objitems[0]?.images[0],
      "product_name": objitems[0]?.product_name,
      "shop_name": objitems[0]?.shop_name,
      "size": storsizevalue !== "" ? (storsizevalue) : (objitems?.[0]?.size[0]),
      "color": storecolorvalue !== "" ? (storecolorvalue) : (objitems?.[0]?.color[0]),
      "qty": storquntityvalue !== "" ? (storquntityvalue) : ("1"),
      "subtotal": objitems[0]?.sale_price * Quantity,
      "tax": objitems[0]?.Tax[0]?.tax_value,
      "discount": objitems[0]?.discount,
      "shipping_charge": "0",
      "total": objitems[0]?.sale_price * Quantity
    }
    storeobj.push(productobj)

    let s_price = objitems[0]?.sale_price * Quantity
    let m_price = objitems[0]?.mrp_price * Quantity
    let save_amount = s_price - m_price

    let data = {
      "userId": userid,
      "products": storeobj,
      "total_item": storquntityvalue !== "" ? (storquntityvalue) : 1,
      "save_amount": save_amount.toFixed(2),
      "total_price": objitems[0]?.sale_price * Quantity,
      "status": 0
    }

    axios.post(`${process.env.REACT_APP_SECRET_KEY}/checkOut`, data).then((res) => {
      if (res.status === 200) {
        let chakoutlist_obj = {
          "total": objitems[0]?.sale_price * Quantity,
          "subtotal": objitems[0]?.sale_price * Quantity,
          "discout": save_amount.toFixed(2),
          "delivery_charge": objitems[0]?.delivery_fee,
          "commission": "",
        }
        secureLocalStorage.setItem("checkoutlist", JSON.stringify(chakoutlist_obj))
        toast.success(`${res.data.message}`);
        setTimeout(() => {
          navigate("/chackoutpayment")
        }, 500);
      }

    }).catch((error) => {
    })



  }

  // login handel
  let UsersingnHandel = () => {
    swal({
      title: "Please Sign in ?",
      text: "You need to Sign in to view this feature",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      // content: {
      //     element: "input",
      //     attributes: {
      //         placeholder: "Enter your reason for deletion",
      //         type: "text",
      //     },
      // },
    }).then((willDelete) => {
      if (willDelete) {
        deleteaddress();
      } else {
      }
    });
    let deleteaddress = () => {
      navigate("/login")
    }
  }

  // shops details 
  let shopdetailshandel = (shopid) => {
    secureLocalStorage.setItem("shopid", shopid[0]?.venderId)
    navigate("/shopview")
  }

  // more products details
  let getproductsdetails = (obj) => {
    secureLocalStorage.setItem("productId", obj?.productId)
    secureLocalStorage.setItem("venderId", obj?.shopId)
    navigate(`/singleprojects_inntout/${obj?.productId}/${obj?.shopId}`)
    setshowproductsdetails([])

    // getsingleproducs()
    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }, 800);

  }


  const location1 = useLocation();

  const handleClick1 = () => {
    const { pathname, search, hash } = location1;
    alert(`Pathname: ${pathname}\nSearch: ${search}\nHash: ${hash}`);
  };

  const arrrdata = [
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
    { reaviewname: "hello" },
  ];

  // State to control the number of reviews shown
  const [visibleReviews, setVisibleReviews] = useState(3);

  // Function to toggle between 3 and all reviews
  const handleToggleReviews = () => {
    if (visibleReviews === 3) {
      setVisibleReviews(arrrdata.length); // Show all reviews
    } else {
      setVisibleReviews(3); // Show only 3 reviews
    }
  };

  // window loader
  const [windowloader, setwindowloader] = useState(false);
  const handleOpen = () => {
    setwindowloader(true);

  };
  let autoclose = () => {
    setTimeout(() => {
      setwindowloader(false)
    }, 500);
  }

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={windowloader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Toaster />
      {/* <div className='row justify-content-center'>
        <div className='mt-3'>
          <button className='btn btn-info' onClick={handleClick1}>
            Get Path
          </button>
        </div>
      </div> */}
      <>
        {showproductsdetails?.length > 0 ? (
          <>
            <div className="__inline-23">
              <div className="container mt-4 rtl" style={{ textAlign: 'left' }}>
                <div className="row ">
                  <div className="col-lg-9 col-12">
                    <div className="row">
                      <div className="col-lg-5 col-md-4 col-12">
                        <>
                          {/* <TfiLayoutMenuSeparated aria-describedby={id} variant="contained" onClick={handleClick} className='ml-2 mb-1' style={{ fontSize: "20px", cursor: "pointer" }} /> */}
                          {/* <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              <span className="st-shares">
                                <div className="d-flex justify-content-start align-items-center share-on-social">
                                  <a style={{ width: "35px" }} href="#" target="_blank">
                                    <img src="./logo/whatsapp.png" alt />
                                  </a>
                                  <a style={{ width: "35px" }} href="#" target="_blank">
                                    <img src="./logo/gmail.png" alt />
                                  </a>
                                  <a style={{ width: "35px" }} href="#" >
                                    <img src="./logo/share.png" alt />
                                  </a>
                                </div>
                              </span>
                            </Typography>
                          </Popover> */}
                        </>
                        {/* <i className="fa fa-heart-o wishlist_icon_1" style={{ color: 'red',backgroundColor:"red" }} id="wishlist_icon_1" aria-hidden="true" /> */}

                        {/* {userid?.length > 0 ? (
                          showproductsdetails?.[0]?.like_status == 0 ? (
                            <FavoriteBorderIcon onClick={() => likedisclikehandel(showproductsdetails[0])} className='mt-1 mr-2 mb-1' style={{ float: "right", fontSize: "20px", cursor: "pointer", zIndex: 0 }} />
                          ) : (
                            <FaHeart onClick={() => likedisclikehandel(showproductsdetails[0])} className='mt-1 mr-2 mb-1' style={{ float: "right", fontSize: "20px", cursor: "pointer", zIndex: 0, color: "red" }} />
                          )
                        ) : (
                          <FavoriteBorderIcon onClick={UsersingnHandel} data-dismiss="modal" className='mt-1 mr-2 mb-1' style={{ float: "right", fontSize: "20px", cursor: "pointer", zIndex: 0 }} />
                        )} */}

                        {userid?.length > 0 ? (
                          showproductsdetails?.[0]?.like_status == 0 ? (
                            <>
                              {like_status === true ? (
                                <FavoriteBorderIcon onClick={() => likedisclikehandel(showproductsdetails[0])} className='mt-1 mr-2 mb-1' style={{ float: "right", fontSize: "20px", cursor: "pointer", zIndex: 0 }} />
                              ) : (
                                <div style={{ float: "right" }} className="spinner-border spinner-border-sm text-primary mt-1 mr-2 mb-1"></div>
                              )}
                            </>
                          ) : (
                            <>
                              {like_status === true ? (
                                <FaHeart onClick={() => likedisclikehandel(showproductsdetails[0])} className='mt-1 mr-2 mb-1' style={{ float: "right", fontSize: "20px", cursor: "pointer", zIndex: 0, color: "red" }} />
                              ) : (
                                <div style={{ float: "right" }} className="spinner-border spinner-border-sm text-primary mt-1 mr-2 mb-1"></div>
                              )}
                            </>
                          )
                        ) : (
                          <FavoriteBorderIcon onClick={UsersingnHandel} data-dismiss="modal" aria-label="Close" className='mt-1 mr-2 mb-1' style={{ float: "right", fontSize: "20px", cursor: "pointer", zIndex: 0 }} />
                        )}


                        <div className='text-center'>
                          <img className="cz-image-zoom img-responsive w-70 __max-h-323px" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${mainproductsimg}`} alt="Product image" width />
                        </div>
                        <div className="table-responsive" style={{ scrollbarWidth: "none" }}>
                          <div className="d-flex justify-content-between">
                            {showproductsdetails[0]?.images?.map((items, index) => {
                              return (
                                <div key={index} onClick={() => setmainproductsimg(items)} className="cz-thumblist">
                                  <a className=" cz-thumblist-item d-flex align-items-center justify-content-center">
                                    <img className="click-img" id="preview-img0" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items}`} />
                                  </a>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-8 col-12 mt-md-0 mt-sm-3 pl-3" style={{ direction: 'ltr' }}>
                        <div className="details __h-100 pl-5">
                          <p className="mb-2 __inline-24">{showproductsdetails?.[0]?.product_name}</p>
                          <p style={{ fontSize: "15px", textAlign: "justify" }} className=" mb-2 ">{showproductsdetails?.[0]?.description}</p>
                          <div className="mb-3">
                            <span className="f-20 font-weight-normal text-accent ">
                              CFA{showproductsdetails?.[0]?.sale_price} <del className="align-middle fs-16 text-muted">CFA{showproductsdetails?.[0]?.mrp_price}</del> <span className='text-danger' style={{ fontSize: "22px" }}>
                                {showproductsdetails?.[0]?.discount == 0 ? (
                                  ""
                                ) : (
                                  <>(-{showproductsdetails?.[0]?.discount}{showproductsdetails?.[0]?.discountType === "Unit" ? "CFA" : "%"})</>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex flex-wrap align-items-center mb-2 pro">
                            <div className="star-rating  p-2 pl-3 pr-3" style={{ marginRight: 10, borderRadius: "10px", backgroundColor: "#ffb518" }}>
                              <IoIosStar className="text-white" /> <lapbel className="text-white" >{showproductsdetails?.[0]?.rating} ({showproductsdetails?.[0]?.total_rating})</lapbel>
                            </div>
                          </div>

                          <div className='row pl-1'>
                            {attributes?.map((items, index) => {
                              return (
                                <div key={index} className="form-group mr-1">
                                  <label>{items?.attribute_name}</label>
                                  <select
                                    onChange={(event) => getattribute(event.target.value, index, items)}
                                    className="form-control p-1"
                                    id="sel1"
                                    name="sellist1"
                                    style={{ cursor: "pointer" }}
                                    value={extractedValues[index]?.value || ''}
                                  >
                                    {items?.attribute_values?.map((item, idx) => {
                                      {/* console.log("inner itms", item) */ }
                                      return (
                                        <option key={idx} value={item}>{item}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              )
                            })}


                            {/* No Sleeve */}
                            {/* <div className="form-group mr-1">
                            <label>Sleeve</label>
                              <select className="form-control p-1" id="sel1" name="sellist1" style={{ cursor: "pointer" }}>
                                <option hidden>No Sleeve</option>
                                <option>Sleeve</option>
                              </select>
                            </div> */}

                            {/* Quantity */}
                            {/* <div className="form-group mr-1">
                              <label>Quantity</label>
                              <select onChange={(event) => setstorquntityvalue(event.target.value)} className="form-control p-1" id="sel1" name="sellist1" style={{ cursor: "pointer" }}>
                                <option hidden>Quantity</option>
                                <option value={"1"}>1</option>
                                <option value={"2"}>2</option>
                                <option value={"3"}>3</option>
                                <option value={"4"}>4</option>
                                <option value={"5"}>5</option>
                                <option value={"6"}>6</option>
                                <option value={"7"}>7</option>
                                <option value={"8"}>8</option>
                                <option value={"9"}>9</option>
                                <option value={"10"}>10</option>
                              </select>
                            </div> */}

                          </div>

                          <form id="add-to-cart-form" style={{ marginLeft: "0px" }} className="mb-2">
                            <div className="__btn-grp mt-2 mb-3 d-none d-sm-flex">
                              {/* <button onClick={() => BuyNowproduct(showproductsdetails)} className="btn btn-secondary element-center __iniline-26 btn-gap-right" onclick="buy_now()" type="button">
                                <span className="string-limit">Buy now</span>
                              </button> */}
                              {userid?.length > 0 ? (
                                showproductsdetails[0]?.cart_status == 1 ? (
                                  <button className="btn btn--primary element-center btn-gap-right" onclick="addToCart()" type="button">
                                    <span className="string-limit"><Link to="/addtocard">Go to cart</Link></span>
                                  </button>
                                ) : (
                                  <button onClick={() => addtocardhandele(showproductsdetails[0]?.productId)} className="btn btn--primary element-center btn-gap-right" onclick="addToCart()" type="button">
                                    <span className="string-limit">Add to cart</span>
                                  </button>
                                )
                              ) : (
                                <button onClick={UsersingnHandel} data-dismiss="modal" aria-label="Close" className="btn btn--primary element-center btn-gap-right" onclick="addToCart()" type="button">
                                  <span className="string-limit">Add to cart</span>
                                </button>
                              )}

                            </div>
                          </form>

                          <div className='mt-2 pl-1'>
                            <div className='row col-12 pl-0'>
                              <div className='col-lg-4 col-5 pt-1 pb-1 pl-0' >
                                <b>Brand <span style={{ float: "right", marginRight: "10px" }}>:</span></b>
                              </div>
                              <div className='col-lg-8 col-7 text-start' >
                                {showproductsdetails?.[0]?.brand_name}
                              </div>
                            </div>

                            <div className='row col-12 pl-0'>
                              <div className='col-lg-4 col-5 pt-1 pb-1 pl-0' >
                                <b>Warranty <span style={{ float: "right", marginRight: "10px" }}>:</span></b>
                              </div>
                              <div className='col-lg-8 col-7 text-start' >
                                {showproductsdetails?.[0]?.warranty}
                              </div>
                            </div>

                            <div className='row col-12 pl-0'>
                              <div className='col-lg-4 col-5 pt-1 pb-1 pl-0' >
                                <b>Note <span style={{ float: "right", marginRight: "10px" }}>:</span></b>
                              </div>
                              <div className='col-lg-8 col-7 text-start' >
                                <p>{showproductsdetails?.[0]?.note}</p>
                              </div>
                            </div>

                            <div className='row col-12 pl-0'>
                              <div className='col-lg-4 col-5 pt-1 pb-1 pl-0' >
                                <b>Product Weight <span style={{ float: "right", marginRight: "10px" }}>:</span></b>
                              </div>
                              <div className='col-lg-8 col-7 text-start' >
                                {showproductsdetails?.[0]?.product_weight}
                              </div>
                            </div>
                            {/* <div className='row col-12'>
                            <div className='col-lg-4 col-5 pt-1 pb-1 pl-0' >
                              <b>Delivery Type <span style={{float:"right",marginRight:"10px"}}>:</span></b>
                            </div>
                            <div className='col-lg-8 col-7 text-start' >
                              {showproductsdetails?.[0]?.deliveryType}
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-3">
                    <div className="product-details-shipping-details">
                      <div className="shipping-details-bottom-border">
                        <div className="px-3 py-3 row">
                          <div className='text-center'>
                            <img className="mr-2 __img-20" src="./imglist/delivery_info.png" alt />
                          </div>
                          <div className='pl-1'>
                            <span>Time: {showproductsdetails?.[0]?.delivery__time} <br />  Fess: {showproductsdetails?.[0]?.delivery_fee} CFA</span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="shipping-details-bottom-border">
                        <div className="px-3 py-3">
                          <img className="mr-2 __img-20" src="./imglist/safe_payment.png" alt />
                          <span>{t("navbar.safePayment")}</span>
                        </div>
                      </div> */}
                      <div className="shipping-details-bottom-border">
                        <div className="px-3 py-3">
                          <img className="mr-2 __img-20" src="./imglist/return_policy.png" alt />
                          {/* <span> 7 </span> */}
                          <span>{showproductsdetails?.[0]?.returnType?.[0]?.type == true ? (`Within ${showproductsdetails?.[0]?.returnType?.[0]?.value} Days Return Policy`) : ("No Return Policy")} </span>
                        </div>
                      </div>
                      {/* <div className="shipping-details-bottom-border">
                        <div className="px-3 py-3">
                          <img className="mr-2 __img-20" src="./imglist/authentic_product.png" alt />
                          <span>100% Authentic Products</span>
                        </div>
                      </div> */}
                    </div>
                    <div className="__inline-31">
                      <div className=" position-relative">
                        <div className="col-12 position-relative">
                          <div to="#" className="d-block">
                            <div className="d-flex __seller-author align-items-center">
                              <div onClick={() => shopdetailshandel(showproductsdetails)}>
                                {/* <img className="__img-60 img-circle" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${showproductsdetails?.[0]?.shop_logo}`} alt /> */}
                                {
                                  showproductsdetails?.[0]?.shop_logo === " " || showproductsdetails?.[0]?.shop_logo === undefined || showproductsdetails?.[0]?.shop_logo === null ? (
                                    <img
                                      src="./imglist/user.png"
                                      className="__img-60 img-circle"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${showproductsdetails?.[0]?.shop_logo}`}
                                      className="__img-60 img-circle"
                                      alt=""
                                    />
                                  )
                                }
                              </div>
                              <div className="ml-2 w-0 flex-grow">
                                <h6>
                                  {showproductsdetails?.[0]?.shop_name}
                                </h6>
                                <span><IoLocationOutline /> {showproductsdetails?.[0]?.shop_address}</span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive" style={{ scrollbarWidth: "thin" }}>
                          <div className="d-flex justify-content-between">
                            <div className="col-6 ">
                              <div className="d-flex justify-content-center align-items-center rounded __h-79px hr-right-before">
                                <div className="text-center">
                                  <div className="d-flex flex-wrap align-items-center mb-2 pro">

                                    {/*
                                      <div className="star-rating" style={{ marginRight: 10 }}>
                                      {showproductsdetails?.[0]?.average_rating === 1 ? (<i className="tio-star text-warning" />) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 2 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 3 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 4 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 5 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 1.5 ? (<><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 2.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 3.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                      {showproductsdetails?.[0]?.average_rating === 4.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                      </div>
                                    */}

                                  </div>
                                  <div className="__text-12px text-base text-center">
                                    <IoIosStar className="tio-star text-warning" style={{ fontSize: "20px" }} /> <br />
                                    <strong>{showproductsdetails?.[0]?.average_rating}</strong> Rating
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="d-flex justify-content-center align-items-center rounded __h-79px">
                                <div className="text-center">
                                  <img src="./imglist/productslogos.svg" className="mb-2" alt />
                                  <div className="__text-12px text-base">
                                    <strong>{showproductsdetails?.[0]?.total_product}</strong> Products
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="d-flex justify-content-center align-items-center rounded __h-79px">
                                <div className="text-center">
                                  <FcSalesPerformance className="mb-2" />
                                  <div className="__text-12px text-base">
                                    <strong>{showproductsdetails?.[0]?.total_sale}</strong> Sales
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="d-flex justify-content-center align-items-center rounded __h-79px">
                                <div className="text-center">
                                  <FaRegEye className="mb-2" />
                                  <div className="__text-12px text-base">
                                    <strong>{showproductsdetails?.[0]?.page__views}</strong> Page Views
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6 ">
                              <div className="d-flex justify-content-center align-items-center rounded __h-79px hr-right-before">
                                <div className="text-center">
                                  <FaHeart className="mb-2" />
                                  <div className="__text-12px text-base">
                                    <strong>{showproductsdetails?.[0]?.liked__time}</strong> Liked Items
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="col-12 position-static mt-3">
                          <div className="chat_with_seller-btns">
                            <button className="btn w-100 d-block text-center" onClick={() => shopdetailshandel(showproductsdetails)} style={{ background: '#1455ac', color: '#ffffff' }} data-toggle="modal" data-target="#chatting_modal">
                              <span className=" d-sm-inline-block">Visit store</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-sticky bg-white d-sm-none">
                <div className="d-flex flex-column gap-1 py-2">
                  {/* <div className="d-flex justify-content-center align-items-center fs-13">
                    <div className="product-description-label text-dark font-bold"><strong className="text-capitalize">Total price</strong> : </div>
                    &nbsp; <strong id="chosen_price_mobile" className="text-base" />
                    <small className="ml-2  font-regular">
                      (<small>Tax : </small>
                      <small id="set-tax-amount-mobile" />)
                    </small>
                  </div> */}
                  <div className="d-flex gap-3 justify-content-center">
                    {/* <button onClick={() => BuyNowproduct(showproductsdetails)} className="btn btn-secondary btn-sm btn-gap-right" type="button">
                      <span className="string-limit">Buy now</span>
                    </button> */}

                    {userid?.length > 0 ? (

                      showproductsdetails[0]?.cart_status == 1 ? (
                        <button className="btn btn--primary element-center btn-gap-right" type="button">
                          <span className="string-limit"><Link to="/addtocard">Go to cart</Link></span>
                        </button>
                      ) : (
                        <button onClick={() => addtocardhandele(showproductsdetails[0]?.productId)} className="btn btn--primary element-center btn-gap-right" onclick="addToCart()" type="button">
                          <span className="string-limit">Add to cart</span>
                        </button>
                      )

                    ) : (
                      <button onClick={UsersingnHandel} data-dismiss="modal" aria-label="Close" className="btn btn--primary element-center btn-gap-right" onclick="addToCart()" type="button">
                        <span className="string-limit">Add to cart</span>
                      </button>
                    )}

                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className="col-lg-12 col-12">
                <div>
                  <div className="px-4 pb-3 mb-3 mr-0 mr-md-2 bg-white __review-overview __rounded-10 pt-3">
                    {/* 
                    <div className='row col-12'>
                      <div className='col-lg-2 col-3'>
                        <p><b>Descripation</b></p>
                      </div>
                      <div className='col-lg-10 col-9'>
                        <p style={{ fontSize: "15px", textAlign: "justify" }} className=" mb-2 ">{showproductsdetails?.[0]?.description}</p>
                      </div>
                    </div> */}

                    {/* <ul className="nav nav-tabs nav--tabs d-flex justify-content-center mt-3" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link __inline-27 active " href="#overview" data-toggle="tab" role="tab">
                          Details
                        </a>
                      </li>

                    </ul> */}
                    <div className="tab-content px-lg-3">
                      <div className="tab-pane fade show active text-justify" id="overview" role="tabpanel">
                        <div className="row pt-2 specification">
                          <div className="text-body col-lg-12 col-md-12 overflow-scroll fs-13 text-justify">

                            {/* <div className="row pt-2 pb-3 mt-2">
                              <div className="col-lg-4 col-md-5 ">
                                <div className=" row d-flex justify-content-center align-items-center">
                                  <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h2 className="overall_review mb-2 __inline-28">
                                      {showproductsdetails?.[0]?.total_rater}


                                    </h2>
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center star-rating ">
                                    {showproductsdetails?.[0]?.total_rater === 1 ? (<i className="tio-star text-warning" />) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 2 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 3 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 4 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 5 ? (<> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /></>) : ("")}



                                    {showproductsdetails?.[0]?.total_rater === 1.5 ? (<><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 2.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 3.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                    {showproductsdetails?.[0]?.total_rater === 4.5 ? (<>  <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /> <i className="tio-star text-warning" /><i class="fa fa-star-half-o text-warning" aria-hidden="true"></i></>) : ("")}
                                  </div>
                                  <div className="col-12 d-flex justify-content-center align-items-center mt-2">
                                    <span className="text-center">Ratings</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-7 pt-sm-3 pt-md-0">
                                <div className="d-flex align-items-center mb-2 font-size-sm">
                                  <div className="__rev-txt">
                                    <span className="d-inline-block align-middle text-body">
                                      Excellent
                                    </span>
                                  </div>
                                  <div className="w-0 flex-grow">
                                    <div className="progress text-body __h-5px">
                                      <div
                                        className="progress-bar web--bg-primary"
                                        role="progressbar"
                                        style={{ width: "100%" }}
                                        aria-valuenow={60}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-1 text-body">
                                    <span className=" ml-3 float-right ">1</span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mb-2 text-body font-size-sm">
                                  <div className="__rev-txt">
                                    <span className="d-inline-block align-middle ">
                                      Good
                                    </span>
                                  </div>
                                  <div className="w-0 flex-grow">
                                    <div className="progress __h-5px">
                                      <div
                                        className="progress-bar web--bg-primary"
                                        role="progressbar"
                                        style={{
                                          width: "0%",
                                          backgroundColor: "#a7e453"
                                        }}
                                        aria-valuenow={27}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <span className="ml-3 float-right">0</span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mb-2 text-body font-size-sm">
                                  <div className="__rev-txt">
                                    <span className="d-inline-block align-middle ">
                                      Average
                                    </span>
                                  </div>
                                  <div className="w-0 flex-grow">
                                    <div className="progress __h-5px">
                                      <div
                                        className="progress-bar web--bg-primary"
                                        role="progressbar"
                                        style={{
                                          width: "0%",
                                          backgroundColor: "#ffda75"
                                        }}
                                        aria-valuenow={17}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <span className="ml-3 float-right">0</span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mb-2 text-body font-size-sm">
                                  <div className="__rev-txt ">
                                    <span className="d-inline-block align-middle">
                                      Below Average
                                    </span>
                                  </div>
                                  <div className="w-0 flex-grow">
                                    <div className="progress __h-5px">
                                      <div
                                        className="progress-bar web--bg-primary"
                                        role="progressbar"
                                        style={{
                                          width: "0%",
                                          backgroundColor: "#fea569"
                                        }}
                                        aria-valuenow={9}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <span className="ml-3 float-right">0</span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center text-body font-size-sm">
                                  <div className="__rev-txt">
                                    <span className="d-inline-block align-middle ">
                                      Poor
                                    </span>
                                  </div>
                                  <div className="w-0 flex-grow">
                                    <div className="progress __h-5px">
                                      <div
                                        className="progress-bar web--bg-primary"
                                        role="progressbar"
                                        style={{ width: "0%" }}
                                        aria-valuenow={4}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <span className="ml-3 float-right">0</span>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            <div className="row pb-4 mb-3">
                              <div className="__inline-30">
                                <span className="text-capitalize">
                                  Product review / Comment Section
                                </span>
                              </div>
                            </div>
                            <div className="row pb-4">

                              {showproductsdetails?.[0]?.reviews_list?.length > 0 ? (
                                <>
                                  {showproductsdetails?.[0]?.reviews_list.slice(0, visibleReviews)?.map((items, index) => {
                                    {/* console.log("items", items) */ }
                                    return (
                                      <div key={index} className="col-12" id="product-review-list">
                                        <div className="p-2 mb-2">
                                          <div className="row product-review d-flex ">

                                            <div className="col-md-4 d-flex mb-3">
                                              <div className="media media-ie-fix me-4">

                                                {/* <img
                                                  className="rounded-circle __img-64 object-cover"
                                                  src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.user_image}`} /> */}
                                                {
                                                  items?.user_image === " " || items?.user_image === undefined ? (
                                                    <img
                                                      src="./imglist/user.png"
                                                      className="avatar rounded-circle"
                                                      alt=""
                                                      width={40}
                                                    />
                                                  ) : items?.user_image?.startsWith("https://") ? (
                                                    <img
                                                      src={items?.user_image}
                                                      className="avatar rounded-circle"
                                                      alt=""
                                                      width={40}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}uploads/${items?.user_image}`}
                                                      className="avatar rounded-circle"
                                                      alt=""
                                                      width={40}
                                                    />
                                                  )
                                                }



                                                <div className="media-body pl-3 text-body">
                                                  <span className="mb-0 text-body font-semi-bold fs-13">
                                                    {items.user_namef}
                                                  </span>
                                                  <div className="d-flex ">

                                                    <div className="text-body text-nowrap">
                                                      {items.rating === "1" ? (<IoIosStar className="tio-star text-warning" />) : ("")}
                                                      {items.rating === "2" ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                      {items.rating === "3" ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                      {items.rating === "4" ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                      {items.rating === "5" ? (<> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}



                                                      {items.rating === "1.0" ? (<><IoIosStar className="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                      {items.rating === "1.5" ? (<><IoIosStar className="tio-star text-warning" /><IoIosStar className="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                      {items.rating === "2.0" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                      {items.rating === "2.5" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /><IoIosStar className="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                      {items.rating === "3.0" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                      {items.rating === "3.5" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /><IoIosStar className="fa fa-star-half-o text-warning" aria-hidden="true" /></>) : ("")}
                                                      {items.rating === "4.0" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                      {items.rating === "4.5" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /><IoIosStar className="fa fa-star-half-o text-warning" aria-hidden="true"></IoIosStar></>) : ("")}
                                                      {items.rating === "5.0" ? (<>  <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /> <IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></>) : ("")}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <p className="mb-3 text-body __text-sm text-break">
                                                <span><b>Comment :</b></span> {items.comment}
                                              </p>
                                            </div>
                                            <div className="col-md-2 text-body">
                                              {/* <span className="float-end font-semi-bold fs-13">
                                          Apr-13-2022
                                        </span> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}



                                </>
                              ) : (
                                <><div className='col-12 row  justify-content-center mt-5'>
                                  <div className='col-lg-4 col-12 text-center'>
                                    <div>
                                      <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                    </div>
                                    <h6 className='mt-2'>Data Not Found</h6>
                                  </div>
                                </div></>

                              )}
                            </div>
                            {showproductsdetails?.[0]?.reviews_list?.length > 3 ? (
                              <div className="row pb-4 mb-3">
                                <div className="__inline-30 text-center">
                                  <span className="text-capitalize text-info" type="button" onClick={handleToggleReviews}>
                                    {visibleReviews === 3 ? "Read More" : "Show Less"}
                                  </span>
                                </div>
                              </div>
                            ) : ("")}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row flex-between">
                <div style={{ marginLeft: 5 }}>
                  <h4 className="text-capitalize font-bold">More products</h4>
                </div>
              </div>
              <div className="row g-3 mt-1">
                {showproductsdetails?.[0]?.moreproducts?.map((items, index) => {
                  return (
                    <div className="col-xl-2 col-sm-3 col-6">
                      <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                        <div className="overflow-hidden position-relative">
                          <div className=" inline_product clickable d-flex justify-content-center">
                            <div className="p-10px pb-0">
                              <div onClick={() => getproductsdetails(items)} className="w-100">
                                <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.image}`} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}


              </div>
            </div>
            <Footer />
          </>
        ) : (
          <div className='row justify-content-center mt-5'>
            <div className="spinner-border text-primary"></div>
          </div>
        )}
      </>
    </>
  )
}

export default Singleproducts
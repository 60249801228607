import React, { useEffect } from 'react'
import Header from './Home_page/Header'
import { Link, useNavigate } from 'react-router-dom'
import Footer from './Home_page/Footer'
import { useState } from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';
import axios from 'axios';
import swal from 'sweetalert';
import { FaArrowLeftLong } from "react-icons/fa6";
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from "react-hot-toast";
import { setcard_list, setwish_list } from '../readux/arrSlice';
import { useDispatch } from 'react-redux';
// style otp auto foucs
const otpInputStyle = {
  width: "70%",
  height: "100px",
  marginBottom: "30px"
};
const inputStyle = {
  width: "70%",
  height: "50px",
  marginBottom: "10px",
  border: "1px solid gray",
};

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  let [uichangestatus, setuichangestatus] = useState(false)
  let [forgotemail_number, setforgotemail_number] = useState("")
  let [loaderstatus, setloaderstatus] = useState(true)
  let userid = secureLocalStorage.getItem("useid")


  const [rememberMe, setRememberMe] = useState(false);
  let [inputvalue, setinputvalue] = useState(
    {
      "mobile_number": "",
      "password": "",
    }
  )
  // remember me handel
  useEffect(() => {
    const rememberedEmail = secureLocalStorage.getItem('rememberedEmail');
    const rememberedPassword = secureLocalStorage.getItem('rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      inputvalue.mobile_number = rememberedEmail
      inputvalue.password = rememberedPassword
      setRememberMe(true);
    } else {
      // console.log("nottttt")
    }
  }, []);


  // show password icons
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };





  let inputhandel = (event) => {
    setinputvalue({ ...inputvalue, [event.target.name]: event.target.value })
  }

  const responseGoogle = (response) => {
    // Handle the response from Google Sign-In
  };

  const handleAppleLogin = (response) => {
    // Handle the Apple login response
  };

  const handleResponse = (data) => {
    // Handle the response from the Facebook login
  }

  // login user handel
  let loginuser = (event) => {
    event.preventDefault()
    setloaderstatus(false)
    let data = "hello"
    localStorage.setItem("loginstatus", data)
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/userLogin`, inputvalue).then((res) => {
      if (res.data.result == "true") {

        if (rememberMe) {
          secureLocalStorage.setItem('rememberedEmail', inputvalue.mobile_number);
          secureLocalStorage.setItem('rememberedPassword', inputvalue.password);
        } else {
          secureLocalStorage.removeItem('rememberedEmail');
          secureLocalStorage.removeItem('rememberedPassword');
        }


        secureLocalStorage.setItem("token", res.data.token)
        secureLocalStorage.setItem("useid", res?.data?.data?._id)
        axios.get(`${process.env.REACT_APP_SECRET_KEY}/InntoutCategory_list`).then((res) => {
          secureLocalStorage.setItem("inntcategory", res?.data?.data[0]?._id)
          secureLocalStorage.setItem("inntoutcategory", res?.data?.data[1]?._id)
          toast.success(`User Login sucessfully`)

          secureLocalStorage.setItem("login_status", true)
          secureLocalStorage.removeItem("mobile_number")



          setTimeout(() => {
            navigate("/")
          }, 500);

        }).catch((errror) => {

        })


      }
    }).catch((error) => {
      toast.error(`${error.response.data.message}`)
      setloaderstatus(true)
      setTimeout(() => {
        if (error.response.data.message === "Please verify your mobile number") {
          navigate("/verifyotp")
        }
      }, 1000);
    })
  }

  // forgatepassword button status ui change
  let forgatepasswordstatus = () => {
    setuichangestatus(!uichangestatus)
  }

  // forgate password handel
  let [otploaderstatus, setotploaderstatus] = useState(true)
  let forgatepasswordhandel = (event) => {
    event.preventDefault()
    setotploaderstatus(false)

    let data = {
      "mobile_number": forgotemail_number,
    }
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/forgotPassword`, data).then((res) => {
      if (res.data.result) {
        secureLocalStorage.setItem("forgot_email_number", forgotemail_number)
        secureLocalStorage.setItem("otp", res?.data?.data?.otp)
        toast.success(`Otp Send Sucessfully`);
        setotploaderstatus(true)
        setTimeout(() => {
          navigate("/forgotpassword")
        }, 1000);
      }
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`)
        setotploaderstatus(true)
      }
    })
  }


  // login_goto_singnup handel
  let login_goto_singnup = () => {
    navigate("/signup")
  }


  return (
    <div>
      <Toaster />
      <div className="container py-4 py-lg-5 my-4" style={{ textAlign: 'left' }}>
        <div className="login-card">

          {uichangestatus == false ? (
            <>
              <div className='text-center mb-4'>
                <span className='font-bold text-capitalize fs-18-mobile mr-5  pb-1 pr-3 pl-3' style={{ cursor: "pointer", borderBottom: "2px solid #1455ac" }} >Sign in</span>
                <span className='font-bold text-capitalize fs-18-mobile ml-5  pb-1 pr-3 pl-3' style={{ cursor: "pointer" }} onClick={login_goto_singnup}>Sign up</span>
              </div>
              <div className="mx-auto __max-w-360">
                <form className="needs-validation mt-2" onSubmit={loginuser} >

                  <div className="form-group">
                    <label className="form-label font-semibold">Email or Phone</label>
                    <input className="form-control borderinput" name='mobile_number' value={inputvalue.mobile_number} onChange={inputhandel} type="text" placeholder='Example@gmail.com' style={{ textAlign: 'left' }} />
                  </div>
                  <div className="form-group">
                    <label className="form-label font-semibold">Password</label>
                    <label onClick={forgatepasswordstatus} style={{ float: "right", cursor: "pointer" }} className="form-label font-semibold text-info"><small>Forgot Password ?</small></label>
                    <div className="password-toggle rtl">
                      <input className="form-control borderinput" value={inputvalue.password} onChange={inputhandel} name="password" type={showPassword ? 'text' : 'password'} id="si-password" style={{ textAlign: 'left' }} placeholder="Password" />
                      <span style={{ position: "absolute", top: "5px", right: "10px", fontSize: "20px", cursor: "pointer" }} onClick={togglePasswordVisibility}>
                        {showPassword ? '👁️' : '👁️‍🗨️'}
                      </span>
                    </div>
                    <div className='mt-2'>
                      <label className="form-label font-semibold">
                        <input
                          type="checkbox"
                          defaultValue=""
                          id="RememberMe"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)} /> Reamember Me</label>
                      {/* <label style={{ float: "right", cursor: "pointer" }} className="form-label font-semibold text-info"><small>Sign in as a guest</small></label> */}
                    </div>
                  </div>
                  {/* {inputvalue.mobile_number?.length > 0 && inputvalue.password?.length > 0 ? ( */}
                  {loaderstatus == true ? (
                    <button className="btn btn--primary btn-block btn-shadow" type="submit" >Continue</button>
                  ) : (
                    <div className='text-center'>
                      <div className="spinner-border text-primary"></div>
                    </div>
                  )}
                  {/* ) : (
                  <button className="btn btn--primary btn-block btn-shadow" disabled>Log in</button>
                )} */}
                </form>
                <div className='text-center pt-2'>
                  <p className=''>OR<small className='text-info'></small> </p>
                </div>
                <FacebookProvider appId="YOUR_APP_ID">
                  <LoginButton
                    scope="email"
                    className="btn btn--primary btn-block btn-shadow"
                    onCompleted={handleResponse}
                  >
                    <FontAwesomeIcon icon={faFacebook} /> Login with Facebook
                  </LoginButton>
                </FacebookProvider>

                <div className='mt-2' style={{ border: "1px solid" }}>
                  <GoogleLogin
                    className="btn btn-block btn-shadow"
                    clientId="YOUR_CLIENT_ID_HERE"
                    buttonText="Login with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}

                  />
                </div>
                <div>
                  <small>By Signing up, you agree to innt's <small ><Link className='text-info' to="/termsandcondition"> Trems & Condition</Link></small> and <small  ><Link className='text-info' to="/pricacyprolicy">Privacy Policy</Link></small></small>
                </div>

              </div>
            </>
          ) : (
            <div className="mx-auto __max-w-360">
              <FaArrowLeftLong onClick={forgatepasswordstatus} style={{ fontSize: "20px", cursor: "pointer" }} />
              <h2 className="text-center h4 mb-4 font-bold text-capitalize fs-18-mobile">Forgot Password</h2>
              <form className="needs-validation mt-2">
                <div className="form-group">
                  <label className="form-label font-semibold">Phone/Email</label>
                  <input className="form-control borderinput" onChange={(e) => setforgotemail_number(e.target.value)} type="text" style={{ textAlign: 'left' }} />
                </div>
                <div className='pb-2'>
                  <small>We'll send a verfication code to this email or phone number if it matches an exising inntaccount</small>
                </div>
                {forgotemail_number.length > 0 ? (
                  <>
                    {otploaderstatus == true ? (
                      <button className="btn btn--primary btn-block btn-shadow" type="submit" onClick={forgatepasswordhandel}>Continue</button>
                    ) : (
                      <div className='text-center'>
                        <div className="spinner-border text-primary"></div>
                      </div>
                    )}
                  </>

                ) : (
                  <button className="btn btn--primary btn-block btn-shadow" type="submit" disabled >Continue</button>

                )}
              </form>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Login
import React from 'react'

const Errorpage = () => {

  return (
    <div style={{ backgroundColor: "white", position: "absolute", top: "2px", zIndex: "999", width: "100%" }}>
      <div className='row justify-content-center'>
        <div className='col-lg-5'>
          <img src="./error.avif" alt="" />
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}

export default Errorpage
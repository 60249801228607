import React, { useEffect, useState } from 'react'
import Inntout_category from './Inntout_category'
import BannerSlider_inntout from './BannerSlider_inntout'
import Todayoffer_inntout from './Todayoffer_inntout'
import Footerbanner_inntout from './Footerbanner_inntout'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import Groceries from './Groceries'
import Top_sale_inntour from './Top_sale_inntour'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { address_list } from '../../readux/arrSlice'
import Footer from '../Home_page/Footer'
import TopSellers from '../Home_page/TopSellers'
import Sellers_inntout from './Sellers_inntout'

const InntoutHome = () => {

  let navigate = useNavigate()
  const dispatch = useDispatch();
  let token = secureLocalStorage.getItem("token");
  let userid = secureLocalStorage.getItem("useid");
  const [location, setLocation] = useState(null);
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const profiledata = useSelector(state => state.arr);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SECRET_KEY}/InntoutCategory_list`).then((res) => {
      // console.log(res)
      secureLocalStorage.setItem("inntcategory", res?.data?.data[0]?._id)
      secureLocalStorage.setItem("inntoutcategory", res?.data?.data[1]?._id)
    }).catch((errror) => {
    })
  }, [])

  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to update screen dimensions
  const updateScreenDimensions = () => {
    setScreenDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Update screen dimensions on mount
  useEffect(() => {
    updateScreenDimensions();
    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateScreenDimensions);
    };

  }, [screenDimensions?.width, screenDimensions?.height]); // Empty dependency array ensures the effect runs only once on mount

  // get address 
  useEffect(() => {
    getaddresshandel()
  }, [])
  const address = useSelector(state => state.arr);
  let getaddresshandel = () => {
    try {
      const options = {
        headers: {
          token: token
        },
      };
      let data = {
        "userId": userid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/getAddress`, data, options)
        .then((res) => {
          if (res.data.result) {
            dispatch(address_list(res?.data?.data));
          }
        })
        .catch((error) => {
          console.log("erer", error)
        });
    } catch (error) {
    }
  }

  // get current location 
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          // console.error('Error getting location:', error);
        }
      );
    } else {
      // console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  
  useEffect(() => {
    if (location) {
      const { latitude, longitude } = location;
      const API_KEY = 'AIzaSyDg64HvMk1LYbkHcnBMzndPu4EuHRjefAQ';

      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'OK') {
            const components = data.results[0].address_components;
            let city = '';
            let pincode = '';

            for (let component of components) {
              const types = component.types;
              if (types.includes('locality')) {
                city = component.long_name;
              } else if (types.includes('postal_code')) {
                pincode = component.long_name;
              }
            }

            setCity(city);
            setPincode(pincode);
          } else {
            // console.error('Error fetching address:', data.status);
          }
        })
        .catch((error) => {
          // console.error('Error fetching address:', error);
        });
    }
  }, [location]);



  return (
    <div className="__inline-61">
      <div style={{ backgroundColor: "#78a578" }}>

        {/* {screenDimensions.width < 767 ? (
          <div >
            <small className='text-dark pl-2 pt-4 pb-4'>Deliver to :
              {address?.addresdata?.length > 0 ? (
                <>{profiledata?.userprofiledata?.first_name}&nbsp;{address?.addresdata[0]?.city_name}&nbsp;{address?.addresdata[0]?.pin_code}</>
              ) : (
                <>{profiledata?.userprofiledata?.first_name}&nbsp;{city}&nbsp;{pincode}</>
              )}
            </small>
          </div>
        ) : ("")} */}

        <BannerSlider_inntout />
        <Inntout_category />
        <Todayoffer_inntout />
        <Top_sale_inntour />
        <div className='row justify-content-center mt-3'>
          <div className='col-lg-10'>
            <Sellers_inntout />
          </div>
        </div>
        <Footerbanner_inntout />
        <Footer />
      </div>
    </div>
  )
}

export default InntoutHome
import React, { useEffect, useState } from 'react'
import Footer from '../Home_page/Footer'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import { IoLocationOutline } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import { FaRegStar, FaStar, FaUserFriends } from 'react-icons/fa';
import { GoChevronRight } from "react-icons/go";
import swal from 'sweetalert'
import { HiLightBulb, HiOutlineArrowPathRoundedSquare } from 'react-icons/hi2'
import { MdOutlineLoyalty, MdOutlineMarkUnreadChatAlt } from 'react-icons/md'
import { BsChatDots } from 'react-icons/bs'
import { IoIosGift } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { inntout_setcard_list, setcard_list } from '../../readux/arrSlice'
import { TfiRuler } from 'react-icons/tfi'
import { Backdrop, CircularProgress } from '@mui/material'

const Inntout_orderdetails = () => {

  const dispatch = useDispatch();
  let navigate = useNavigate()
  let token = secureLocalStorage.getItem("token")
  let userid = secureLocalStorage.getItem("useid")
  let [showorderdetails, setshoworderdetails] = useState()
  let order_id = secureLocalStorage.getItem("order_id")
  let products_id = secureLocalStorage.getItem("products_id")
  let [showaddress, setshowaddress] = useState()
  let [storeaddressid, setstoreaddressid] = useState()
  let [checkoutId, setcheckoutId] = useState()
  let [loderstatus, setloderstatus] = useState(true)
  const [rating, setRating] = useState(0); // Initial rating state
  let [ratingitems, setratingitems] = useState({})
  let [comment, setcomment] = useState("")
  let [orderstatus, setorderstatus] = useState()
  let [btnloaderstatus, setbtnloaderstatus] = useState(false)
  let [detailsloaderstatus, setdetailsloaderstatus] = useState(true)

  useEffect(() => {
    getorderdetails()
  }, [0])
  let getorderdetails = () => {
    setdetailsloaderstatus(true)
    try {
      let data = {
        "orderId": order_id,
        "productId": products_id
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/orderDetails`, data).then((res) => {
        setorderstatus("hello")
        setstoreaddressid(res?.data?.data?.addressId)
        setcheckoutId(res?.data?.data?.checkoutId)
        if (res.status === 200) {
          setdetailsloaderstatus(false)
          setshoworderdetails(res?.data?.data)
          setloderstatus(false)
        }
      }).catch((error) => {
        setdetailsloaderstatus(TfiRuler)
      })
    } catch (error) {
    }
  }


  // get address 
  const address = useSelector(state => state.arr);
  // console.log("addressaddress",address)
  useEffect(() => {
    getaddresshandel()
  }, [])
  let getaddresshandel = () => {
    try {
      const options = {
        headers: {
          token: token
        },
      };
      let data = {
        "userId": userid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/getAddress`, data, options)
        .then((res) => {
          // console.log(res)
          if (res.data.result) {
            setshowaddress(res?.data?.data)
          }
        })
        .catch((error) => {
          // console.log("erer", error)
        });
    } catch (error) {
    }
  }

  // address change 
  let changeaddresshandel = (itemboj) => {
    // console.log(itemboj.addressId)
    // console.log("checkoutId", itemboj.checkoutId)
    // addressId
    // checkoutId
  }



  const handleCheckboxChange = (id) => {
    setstoreaddressid(id);
    // console.log(id)
    // console.log("checkoutId", checkoutId)
    try {

      let data = {
        "checkoutId": checkoutId,
        "addressId": id
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/changeAddress`, data)
        .then((res) => {
          // console.log(res)
          if (res.data.result) {
            getaddresshandel()
            getorderdetails()
            toast.success(`${res.data.message}`);

          }
        })
        .catch((error) => {
          // console.log("erer", error)
        });
    } catch (error) {
    }
  };


  // cencel order 
  let cencelorder = (obj) => {
    // console.log(obj)
    swal({
      title: "Are you sure?",
      text: "I want to cancel this order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      // content: {
      //     element: "input",
      //     attributes: {
      //         placeholder: "Enter your reason for deletion",
      //         type: "text",
      //     },
      // },
    }).then((willDelete) => {
      if (willDelete) {
        cencelorder();
      } else {
      }
    });
    let cencelorder = () => {
      try {
        let data = {
          "orderId": obj?._id,
          "productId": obj?.products?.productId,
          "status": "1"
        }
        // console.log(data)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/updateOrder_status`, data)
          .then((res) => {
            // console.log(res)
            if (res.status === 200) {
              getorderdetails()
              toast.success(`${res.data.message}`);
            }


          })
          .catch((error) => {
            // console.log("erer", error)
          });
      } catch (error) {
      }
    }


  };



  // rating star handel
  const handleClick = (index) => {
    // Set rating to index + 1 (since index is 0-based)
    setRating(index + 1);
  };


  // give rating id take 

  let Giveratinghadndel = (obj) => {
    let ratingobj = {}
    let data = {
      "venderId": obj?.shopId,
      "productId": obj?.products?.productId,
    }
    ratingobj = { ...ratingobj, data }
    setratingitems(ratingobj)
  }


  // send rating handel
  let [errorstatus, seterrorstatus] = useState({})
  let SendRating_handel = () => {
    setbtnloaderstatus(true)
    let errors = {}
    if (rating === 0) {
      errors = { ...errors, rating: "Rating is required..." }
      setbtnloaderstatus(false)
    } if (comment === "") {
      errors = { ...errors, comments: "Comment is required..." }
      setbtnloaderstatus(false)
    } else {
      try {
        let data = {
          "userId": userid,
          "venderId": ratingitems?.data?.venderId,
          "productId": ratingitems?.data?.productId,
          "rating": rating,
          "comment": comment
        }
        // console.log(data)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/givingRating_api`, data)
          .then((res) => {
            // console.log(res)
            if (res.status === 200) {
              setbtnloaderstatus(false)
              toast.success(`${res.data.message}`);
            }
          })
          .catch((error) => {
            setbtnloaderstatus(false)
            // console.log("erer", error)
          });
      } catch (error) {
      }

    }
    seterrorstatus(errors)
  }


  // order agin 
  let orderagin = (obj) => {
    // console.log(obj)
    handleOpen()
    let data = {
      "userId": userid,
      "productId": obj?.products?.productId,
      "size": obj?.products?.size,
      "color": obj?.products?.color,
      "qty": obj?.products?.qty,
      "status": "1",
    }

    axios.post(`${process.env.REACT_APP_SECRET_KEY}/AddInntoutCart`, data).then((res) => {
      // console.log(res)
      if (res.data.result) {
        autoclose()
        toast.success(`${res.data.message}`);
        try {
          let data = {
            "userId": userid,
          }
          axios.post(`${process.env.REACT_APP_SECRET_KEY}/InnoutcartList`, data).then((res) => {
            if (res.status === 200) {
              autoclose()
              dispatch(inntout_setcard_list(res.data.data[0]?.products));
            }
          }).catch((error) => {
            autoclose()
          })
        } catch (error) {
          autoclose()
        }
      }
    }).catch((error) => {
      // console.log(error)
      autoclose()
      if (error.response && error.response.status === 400) {
        toast.error(`${error.response.data.message}`);
      }
    })
  }


  // go to chat 
  let gotochat = (obj) => {
    // console.log("hello", obj)
    let data = {
      "shopid": obj?.shopId,
      "name": obj?.shop_name,
      "lastname": obj?.shop_venderlastName,
      "img": obj?.shop_image,
      "status": 1,
    }
    secureLocalStorage.setItem("chatvendorid", JSON.stringify(data))
    navigate("/inbox")
  }


  // window loader
  const [windowloader, setwindowloader] = useState(false);
  const handleOpen = () => {
    setwindowloader(true);

  };
  let autoclose = () => {
    setTimeout(() => {
      setwindowloader(false)
    }, 500);
  }
  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={windowloader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Toaster />
      <div className="container pb-5 mb-2 mb-md-4 mt-3 rtl __inline-47" style={{ textAlign: 'left' }}>
        <div className="row justify-content-center g-3">
          <section className="col-lg-10">
            <div className="d-flex align-items-start justify-content-between gap-2 pb-3">
              <div>
                <div className="d-flex align-items-center gap-2 text-capitalize">
                  <h4 className="text-capitalize mb-0 mobile-fs-14">Order Details</h4>
                </div>
              </div>
            </div>

            {detailsloaderstatus == true ? (
              <div className="bg-white border-lg rounded mobile-full pt-5" style={{ height: "300px" }}>
                <div className='row justify-content-center mt-5'>
                  <div className="spinner-border text-primary"></div>
                </div>
              </div>

            ) : (
              <div className="bg-white border-lg rounded mobile-full">
                <div className="p-lg-3 p-0">
                  <div className="d-flex justify-content-between gap-2 flex-wrap mb-3">
                    <div className="d-flex gap-3 flex-wrap">
                      <div className="bg-light rounded py-2 px-3 d-flex align-items-center">
                        <div className="fs-14 text-capitalize">
                          Order Id   : <strong className="text-base">{showorderdetails?.orderId}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-start gap-2">
                      {showorderdetails?.products?.order_status === 1 ? (
                        <>
                          <span><b>
                            Order Status :
                          </b></span> <span className='text-danger'>Cancel</span>
                        </>
                      ) : (
                        <></>
                      )}

                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 col-6 row justify-content-center' >
                      <div className='col-lg-4 col-md-12'>
                        <div className="">
                          <img className="d-block" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${showorderdetails?.products?.image}`} width={100} />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-12'>
                        <a>
                          Name:   {showorderdetails?.products?.product_name} <br />
                          {showorderdetails?.products?.attributes?.map((items, index) => {
                            return (
                              <>{items.name}:&nbsp;{items?.value}, &nbsp;</>
                            )
                          })} <br />
                          Qty:    {showorderdetails?.products?.qty} <br />
                          Price:  {showorderdetails?.products?.total / showorderdetails?.products?.qty}
                        </a>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6 '>
                      <div className=''>
                        <span onClick={() => orderagin(showorderdetails)} style={{ border: "1px solid", cursor: "pointer" }} className="mr-1 badge  text-dark">
                          <HiOutlineArrowPathRoundedSquare style={{ marginBottom: "3px" }} /> Order Again
                        </span>
                        <span onClick={() => gotochat(showorderdetails)} style={{ border: "1px solid", cursor: "pointer" }} className="mr-1  badge text-info">
                          <MdOutlineMarkUnreadChatAlt /> Chat
                        </span>
                      </div>

                      <div style={{ cursor: "pointer" }} className='mt-4'>
                        {[...Array(5)].map((_, index) => (
                          <span
                            key={index}
                            style={{ cursor: 'pointer' }}
                          >
                            <FaStar onClick={() => Giveratinghadndel(showorderdetails)}
                              data-toggle="modal" data-target="#ratingModal"
                              color={'#e4e5e9'} // Change star color based on index and rating
                              size={20} // Adjust size as needed
                            />
                          </span>
                        ))}
                        <GoChevronRight onClick={() => Giveratinghadndel(showorderdetails)} data-toggle="modal" data-target="#ratingModal" style={{ cursor: "pointer" }} className='mt-1 ml-2' />
                      </div>
                    </div>
                  </div>

                  {showorderdetails?.products?.order_status === 1 ? (
                    ""
                  ) : (
                    <>
                      {/* Order Placed */}
                      {showorderdetails?.products?.order_status == 0 ? (
                        <div className="card border-0">
                          <div className="card-body">
                            <div className="d-flex gap-3 flex-wrap mb-4">
                              <div className="bg-light rounded py-2 px-3 d-flex align-items-center">
                                <div className="fs-14">
                                  Order Traking
                                </div>
                              </div>
                            </div>
                            <ul className="nav nav-tabs media-tabs nav-justified order-track-info">
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mx-sm-auto mb-3">
                                      <img src="./imglist/order-placed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order placed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item ">
                                <div className="nav-link">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/order-confirmed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order confirmed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/shipment.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Preparing shipment</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/on-the-way.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14"> Not Delivered</h6>
                                      </div>
                                      <div className="mt-1">
                                        <span className="d-flex justify-content-sm-center text-nowrap">
                                          {/* <span className="text-muted fs-12 text-capitalize">Your deliveryman is coming</span> */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/delivered.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14">Order Shipped</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : ("")}

                      {/* Order Confirmed */}
                      {showorderdetails?.products?.order_status == 2 ? (
                        <div className="card border-0">
                          <div className="card-body">
                            <div className="d-flex gap-3 flex-wrap mb-4">
                              <div className="bg-light rounded py-2 px-3 d-flex align-items-center">
                                <div className="fs-14">
                                  Order Traking
                                </div>
                              </div>
                            </div>
                            <ul className="nav nav-tabs media-tabs nav-justified order-track-info">
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mx-sm-auto mb-3">
                                      <img src="./imglist/order-placed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order placed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item ">
                                <div className="nav-link  active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/order-confirmed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order confirmed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/shipment.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Preparing shipment</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/on-the-way.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14"> Not Delivered</h6>
                                      </div>
                                      <div className="mt-1">
                                        <span className="d-flex justify-content-sm-center text-nowrap">
                                          {/* <span className="text-muted fs-12 text-capitalize">Your deliveryman is coming</span> */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/delivered.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14">Order Shipped</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : ("")}
                      {/* Preparing Shipment */}
                      {showorderdetails?.products?.order_status == 3 ? (
                        <div className="card border-0">
                          <div className="card-body">
                            <div className="d-flex gap-3 flex-wrap mb-4">
                              <div className="bg-light rounded py-2 px-3 d-flex align-items-center">
                                <div className="fs-14">
                                  Order Traking
                                </div>
                              </div>
                            </div>
                            <ul className="nav nav-tabs media-tabs nav-justified order-track-info">
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mx-sm-auto mb-3">
                                      <img src="./imglist/order-placed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order placed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item ">
                                <div className="nav-link  active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/order-confirmed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order confirmed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/shipment.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Preparing shipment</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/on-the-way.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14"> Not Delivered</h6>
                                      </div>
                                      <div className="mt-1">
                                        <span className="d-flex justify-content-sm-center text-nowrap">
                                          {/* <span className="text-muted fs-12 text-capitalize">Your deliveryman is coming</span> */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/delivered.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14">Order Shipped</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : ("")}
                      {/* not delivered */}
                      {showorderdetails?.products?.order_status == 4 ? (
                        <div className="card border-0">
                          <div className="card-body">
                            <div className="d-flex gap-3 flex-wrap mb-4">
                              <div className="bg-light rounded py-2 px-3 d-flex align-items-center">
                                <div className="fs-14">
                                  Order Traking
                                </div>
                              </div>
                            </div>
                            <ul className="nav nav-tabs media-tabs nav-justified order-track-info">
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mx-sm-auto mb-3">
                                      <img src="./imglist/order-placed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order placed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item ">
                                <div className="nav-link  active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/order-confirmed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order confirmed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/shipment.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Preparing shipment</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/on-the-way.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14"> Not Delivered</h6>
                                      </div>
                                      <div className="mt-1">
                                        <span className="d-flex justify-content-sm-center text-nowrap">
                                          {/* <span className="text-muted fs-12 text-capitalize">Your deliveryman is coming</span> */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link ">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/delivered.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14">Order Shipped</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : ("")}
                      {/* Order Shipped */}
                      {showorderdetails?.products?.order_status == 5 ? (
                        <div className="card border-0">
                          <div className="card-body">
                            <div className="d-flex gap-3 flex-wrap mb-4">
                              <div className="bg-light rounded py-2 px-3 d-flex align-items-center">
                                <div className="fs-14">
                                  Order Traking
                                </div>
                              </div>
                            </div>
                            <ul className="nav nav-tabs media-tabs nav-justified order-track-info">
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mx-sm-auto mb-3">
                                      <img src="./imglist/order-placed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order placed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item ">
                                <div className="nav-link  active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/order-confirmed.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Order confirmed</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/shipment.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 text-capitalize fs-14">Preparing shipment</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/on-the-way.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14"> Not Delivered</h6>
                                      </div>
                                      <div className="mt-1">
                                        <span className="d-flex justify-content-sm-center text-nowrap">
                                          {/* <span className="text-muted fs-12 text-capitalize">Your deliveryman is coming</span> */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="nav-item">
                                <div className="nav-link active-status">
                                  <div className="d-flex flex-sm-column gap-3 gap-sm-0">
                                    <div className="media-tab-media mb-3 mx-sm-auto">
                                      <img src="./imglist/delivered.png" alt />
                                    </div>
                                    <div className="media-body">
                                      <div className="text-sm-center">
                                        <h6 className="media-tab-title text-nowrap mb-0 fs-14">Order Shipped</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : ("")}

                    </>
                  )}


                  <div className="card border-sm">
                    <div className="p-lg-3">

                      <div className="border-lg rounded payment mb-lg-3 table-responsive">
                        <table className="table table-borderless mb-0">
                          <thead>
                            <tr className="order_table_tr">
                              {/* <td className="order_table_td">
                                <div className>
                                  <div className="_1 py-2 d-flex justify-content-between align-items-center">
                                    <h6 className="font-weight-bold text-capitalize">Payment info
                                    </h6>
                                    <button type="button" className="btn btn-square d-sm-none" >
                                    </button>
                                  </div>
                                  <div className="fs-12">
                                    <span className="text-muted text-capitalize">Payment status</span> : <span className="text-danger text-capitalize">{showorderdetails?.products?.payment_status}</span>
                                  </div>
                                  <div className="mt-2 fs-12">
                                    <span className="text-muted text-capitalize">Payment method</span> : <span className="text-primary text-capitalize">{showorderdetails?.paymentMode}</span>
                                  </div>
                                </div>
                              </td> */}
                              <td className="order_table_td">
                                <div className>
                                  <div className=" py-2">
                                    <h6 className="font-weight-bold text-capitalize">{showorderdetails?.delivery_place === "Address" ? "Delivery" : "Pickup"}  Address </h6>
                                  </div>
                                  {showorderdetails?.delivery_place === "Address" ? (
                                    <div>
                                      <span style={{ float: "right" }}>
                                        {showorderdetails?.products?.order_status === 1 ? (
                                          <>

                                          </>
                                        ) : (
                                          <button className='btn btn-info' data-toggle="modal" data-target="#changeModal" onClick={() => changeaddresshandel(showorderdetails)}>Change Address</button>
                                        )}

                                      </span>
                                      <span className="text-capitalize fs-12">
                                        <span className="text-capitalize">
                                          <span className="min-w-60px">Building No.</span>  :&nbsp; {showorderdetails?.building_no}
                                        </span>
                                        <br />
                                        <span className="text-capitalize">
                                          <span className="min-w-60px">Location</span> &nbsp;&nbsp;:&nbsp; {showorderdetails?.landmark},
                                        </span>
                                        <br />
                                        <span className="text-capitalize">
                                          <span className="min-w-60px">City</span> &nbsp;&nbsp;:&nbsp;
                                          {showorderdetails?.city_name},
                                          {showorderdetails?.pin_code}
                                        </span>
                                      </span>

                                    </div>
                                  ) :

                                    (
                                      <span className="min-w-60px"><IoLocationOutline />{showorderdetails?.shop_name} {showorderdetails?.shop_address}</span>
                                    )
                                  }

                                </div>
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <div className="">
                        <table className="table table-borderless" >
                          <thead className="thead-light text-capitalize">
                            <tr>
                              <th>Payment Details</th>
                            </tr>
                          </thead>
                        </table>

                        <div className="mb-3 col-lg-6">

                          <div className='row mb-2'>
                            <div className='col-lg-6'>
                              <span className="product-qty ">Item</span>
                            </div>
                            <div className='col-lg-6'>
                              <span>{showorderdetails?.products?.qty}</span>
                            </div>
                          </div>


                          <div className='row mb-2'>
                            <div className='col-lg-6'>
                              <span className="product-qty ">Product price</span>
                            </div>
                            <div className='col-lg-6'>
                              <span>{showorderdetails?.products?.subtotal}</span>
                            </div>
                          </div>


                          <div className='row mb-2'>
                            <div className='col-lg-6'>
                              <span className="font-weight-bold"><strong>Total</strong></span>
                            </div>
                            <div className='col-lg-6'>
                              <span className="font-weight-bold amount">{showorderdetails?.products?.total}</span>
                            </div>
                          </div>


                          {/* <tr>
                            <td>
                              <div className="text-left"><span className="product-qty ">Tax fee</span>
                              </div>
                            </td>
                            <td>
                              <div className="text-right">
                                <span>CFA{showorderdetails?.products?.tax}</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="text-left"><span className="product-qty ">Shipping Fee</span>
                              </div>
                            </td>
                            <td>
                              <div className="text-right">
                                <span>CFA{showorderdetails?.products?.shipping_charge}</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="text-left"><span className="product-qty ">Discount on product</span>
                              </div>
                            </td>
                            <td>
                              <div className="text-right">
                                <span>-CFA{showorderdetails?.products?.discount}</span>
                              </div>
                            </td>
                          </tr> */}
                          <tr className="border-top mt-1 mb-2">

                          </tr>
                        </div>

                        <div className="d-flex justify-content-end align-items-end gap-2 mb-3">
                          {showorderdetails?.products?.order_status === 1 ? (
                            <>

                            </>
                          ) : (
                            <button type="button" className="btn btn-danger " onClick={() => cencelorder(showorderdetails)} >
                              Cancel Order
                            </button>
                          )}
                        </div>


                      </div>

                      <div className="mb-3 ">
                        <div className="">
                          <div className='row justify-content-between'>
                            <table className="table table-borderless" >
                              <thead className="thead-light text-capitalize">
                                <tr>
                                  <th>Others Products</th>
                                </tr>
                              </thead>
                            </table>
                            {showorderdetails?.other_products?.length > 0 ? (
                              showorderdetails?.other_products?.map((items) => {
                                {/* console.log(items) */ }
                                return (
                                  <div className='media-body row media gap-3 align-items-center col-lg-4' style={{ boxShadow: "0px 0px 3px 0px", borderRadius: "10px" }}>
                                    <div className="position-relative">
                                      <img className="d-block" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image}`} width={100} />
                                    </div>
                                    <div className="media-body">
                                      <a>
                                        Name:   {items?.product_name} <br />
                                        Qty:    {items?.qty}  <br />
                                        Price:  {items?.total / items?.qty}
                                      </a>

                                    </div>

                                  </div>
                                )
                              })
                            ) : (
                              <div className='col-12 row  justify-content-center mt-2'>
                                <div className='col-lg-4 col-12 text-center'>
                                  <div>
                                    <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                  </div>
                                  <h6 className='mt-2'>Data Not Found</h6>
                                </div>
                              </div>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
      <Footer />


      {/* change address order */}
      <div className="modal fade rtl" style={{ textAlign: 'left' }} id="changeModal" tabIndex={-1} role="dialog" aria-labelledby="changeModal" aria-hidden="true">
        <div className="modal-dialog  modal-lg" role="document">
          <div className="modal-content">
            <div className='model-header'>
              <h3 className='mr-2 mt-2' style={{ float: "right" }} data-dismiss="modal"><RxCross2 /></h3>
            </div>
            <div className="modal-body">
              <div className="row g-3" style={{ height: "300px", overflow: "scroll" }}>
                {address?.addresdata?.length > 0 ? (
                  address?.addresdata?.map((items, index) => {
                    return (
                      <section key={index} className="col-lg-6 col-md-6">
                        <div className="card __shadow h-100">
                          <div className="card-header d-flex justify-content-between d-flex align-items-center bg-aliceblue border-0">
                            <div className="w-0 flex-grow-1">
                              <h6 className="mb-0 fw-semibold">Shipping Address</h6>
                            </div>
                            <div className="d-flex justify-content-between gap-2 align-items-center">
                              <button className="no-gutter remove-address-by-modal">
                                <input type="checkbox" onChange={() => handleCheckboxChange(items._id)} checked={storeaddressid === items._id} />
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">Building No.</span> :&nbsp; {items.building_no}</span>
                            </div>
                            {/* <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">Phone </span> :&nbsp; ;{items.landmark}</span> */}
                            {/* </div> */}
                            <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">City </span> :&nbsp; {items.city_name}</span>
                            </div>
                            <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">Place-Type </span> :&nbsp; {items.place_type}</span>
                            </div>
                            <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">Zip code </span> :&nbsp; {items.pin_code}</span>
                            </div>
                            <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">Address </span> :&nbsp; {items.landmark}</span>
                            </div>
                            <div><span className="font-nameA"> <span className="fw-semibold min-w-60px">Country </span> :&nbsp; {items.state}</span>
                            </div>
                          </div>
                        </div>
                      </section>
                    )
                  })
                ) : (
                  <div className='col-12 row  justify-content-center mt-5'>
                    <div className='col-lg-4 col-12 text-center'>
                      <div>
                        <img style={{ borderRadius: "80%" }} width={"70%"} className='img-fluid' src="./imglist/datanotfound.jpg" alt />
                      </div>
                      <h6 className='mt-2'>Data Not Found</h6>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>


      {/* rating model */}
      <div className="modal fade rtl" style={{ textAlign: 'left' }} id="ratingModal" tabIndex={-1} role="dialog" aria-labelledby="changeModal" aria-hidden="true">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className='model-header'>
              <h3 className='mr-2 mt-2' style={{ float: "right" }} data-dismiss="modal"><RxCross2 /></h3>
            </div>
            <div className="modal-body">
              <div>
                <b>Rate Product</b>
              </div>
              <div className='text-center'>
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    onClick={() => handleClick(index)} // Handle click event
                    style={{ cursor: 'pointer' }}
                  >
                    <FaStar
                      color={index < rating ? '#ffc107' : '#e4e5e9'} // Change star color based on index and rating
                      size={20} // Adjust size as needed
                    />
                  </span>
                ))} <br />
              </div>
              <small className='text-danger'>{errorstatus.rating && <>{errorstatus.rating}</>}</small>
              <div className='mt-2 mb-1'>
                <b>Comment</b>
              </div>
              <div>
                <div className="form-group">
                  <input onChange={(e) => setcomment(e.target.value)} className="form-control" placeholder='Tell.....' type="text" style={{ textAlign: 'left' }} />
                  <small className='text-danger'>{errorstatus.comments && <>{errorstatus.comments}</>}</small>
                </div>
              </div>
              <div className="col-12 text-center  d-md-block">
                {btnloaderstatus == false ? (<button style={{ width: "100%" }} className='btn btn-info' onClick={SendRating_handel}>Submit</button>) : (<div className="spinner-border text-primary"></div>)}
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default Inntout_orderdetails
import React from 'react'
import { Link } from 'react-router-dom'

const Static_page = () => {
  return (
    <div>
{/*     
    <div className="row mt-3 text-center" style={{ background: '#1455ac10', padding: 20 }}>
    <div className="col-md-2 col-lg-2 col-4 ">
      <div>
        <Link to="/aboutcompany">
          <div className="text-center">
            <img className="size-60" src="https://6valley.6amtech.com/public/assets/front-end/png/about company.png" alt />
          </div>
          <div className="text-center">
            <p className="m-0">
              About Company
            </p>
          </div>
        </Link>
      </div>
    </div>
    <div className="col-md-2 col-lg-2 col-4 ">
      <div>
        <Link to="/contact">
          <div className="text-center">
            <img className="size-60" src="https://6valley.6amtech.com/public/assets/front-end/png/contact us.png" alt />
          </div>
          <div className="text-center">
            <p className="m-0">
              Contact Us
            </p>
          </div>
        </Link>
      </div>
    </div>
    <div className="col-md-2 col-lg-2 col-4 ">
      <div>
        <Link to="/faq">
          <div className="text-center">
            <img className="size-60" src="https://6valley.6amtech.com/public/assets/front-end/png/faq.png" alt />
          </div>
          <div className="text-center">
            <p className="m-0">
              FAQ
            </p>
          </div>
        </Link>
      </div>
    </div>
    <div className="col-md-2 col-lg-2 col-4 ">
    <div>
      <Link to="/faq">
        <div className="text-center">
          <img className="size-60" src="https://w7.pngwing.com/pngs/439/345/png-transparent-privacy-policy-general-data-protection-regulation-personally-identifiable-information-information-privacy-others-logo-computer-wallpaper-business.png" alt />
        </div>
        <div className="text-center">
          <p className="m-0">
            Privacy Policy
          </p>
        </div>
      </Link>
    </div>
  </div>
  <div className="col-md-2 col-lg-2 col-4 ">
  <div>
    <Link to="/termsandcondition">
      <div className="text-center">
        <img className="size-60" src="https://png.pngtree.com/png-vector/20220227/ourmid/pngtree-accept-terms-and-conditions-office-trust-accepted-vector-png-image_16164568.jpg" alt />
      </div>
      <div className="text-center">
        <p className="m-0">
        Terms and conditions 
        </p>
      </div>
    </Link>
  </div>
</div>
<div className="col-md-2 col-lg-2 col-4 ">
<div>
  <Link to="/pricacyprolicy">
    <div className="text-center">
      <img className="size-60" src="https://w7.pngwing.com/pngs/439/345/png-transparent-privacy-policy-general-data-protection-regulation-personally-identifiable-information-information-privacy-others-logo-computer-wallpaper-business.png" alt />
    </div>
    <div className="text-center">
      <p className="m-0">
        Privacy Policy
      </p>
    </div>
  </Link>
</div>
</div>

  </div> */}
    </div>
  )
}

export default Static_page
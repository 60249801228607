import React from 'react'
import Header from './Home_page/Header'
import { useNavigate } from 'react-router-dom'
import Footer from './Home_page/Footer'
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { AiOutlineMail } from "react-icons/ai";
import { LiaSmsSolid } from "react-icons/lia";
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import swal from 'sweetalert';

import toast, { Toaster } from "react-hot-toast";
// style otp auto foucs
const otpInputStyle = {
    width: "70%",
    height: "100px",
    marginBottom: "30px"
};
const inputStyle = {
    width: "70%",
    height: "50px",
    marginBottom: "10px",
    border: "1px solid gray",
};
const inputProps = {
    type: "number",
};

const Verifyotp = () => {
    let navigate = useNavigate()
    let mobilenumber = secureLocalStorage.getItem("mobile_number")

    let email = secureLocalStorage.getItem("email")
    let [otpvalue, setotpvalue] = useState(
        () => {
            let localotp = secureLocalStorage.getItem("otp")
            if (localotp != null) {
                return localotp
            } else {
                return ""
            }
        }
    )
    const [otp, setOtp] = useState('');
    const [btstatus, setbtstatus] = useState(false);

    let optbuttonhandel = () => {
        setbtstatus(true)
    }

    // verifyotp handel 
    let [otploader_status, setotploader_status] = useState(true)
    let Verifyotphandel = (event) => {
        event.preventDefault()
        setotploader_status(false)
        let data = {
            "mobile_number": mobilenumber,
            "otp": otp
        }
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/verifyOtp`, data).then((res) => {
            if (res.data.result) {
                secureLocalStorage.setItem("token", res.data.token)
                secureLocalStorage.setItem("useid", res?.data?.data?._id)
                secureLocalStorage.removeItem("mobile_number")
                secureLocalStorage.removeItem("email")
                secureLocalStorage.removeItem("otp")
                toast.success("User Signup Successfully")
                secureLocalStorage.setItem("login_status", true)
                setTimeout(() => {
                    // window.location.reload()
                    navigate("/")
                }, 500);
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                toast.error(`${error.response.data.message}`)
            }
        })
    }
    // resend otp handel
    let resendotphandel = (otpstatus) => {
        let emailotp = { "email": email, "choose_status": otpstatus }
        let mobileotp = { "mobile_number": mobilenumber, "choose_status": otpstatus }
        let dataobj = otpstatus == "1" ? (mobileotp) : (emailotp)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/resendOtp`, dataobj).then((res) => {
            if (res.data.result) {
                setotpvalue(res?.data?.data?.otp)
                secureLocalStorage.setItem("otp", res?.data?.data?.otp)
                toast.success(`Otp Resend sucessfully On ${otpstatus == "1" ? "SMS" : "Gmail"}`);
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                toast.error(`${error.response.data.message}`);

            }
        })
    }

    return (
        <div>
            <Toaster />
            <div>
                <Header />
                <div className="container py-4 py-lg-5 my-4" style={{ textAlign: 'left' }}>
                    <div className="login-card">
                        <div className="mx-auto __max-w-360">
                            <h6 className="text-center h5 mb-2 font-bold text-capitalize fs-18-mobile">Verify Your Mobile Number Or Email</h6>
                            <form className="needs-validation mt-2"  >
                                <div className="form-group text-center">
                                    <div>
                                        <small>Please Enter The Code Send To</small>
                                    </div>
                                    <div>
                                        <small>{mobilenumber}</small>
                                    </div>
                                    <div>
                                        <small><b>{otpvalue}</b></small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="password-toggle rtl">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span>-</span>}
                                            inputStyle={inputStyle}
                                            style={otpInputStyle}
                                            renderInput={(props) => <input {...props} />}
                                            inputProps={inputProps}
                                        />
                                    </div>
                                </div>
                                {btstatus == false ? ("") :
                                    (
                                        <div className='text-center'>
                                            <small className='pl-4 pt-2 pb-2 pr-4 mr-1 text-info' onClick={() => resendotphandel("2")} style={{ cursor: "pointer", border: "1px solid", borderRadius: "5px" }}><AiOutlineMail /> Email</small>
                                            <small className='pl-4 pt-2 pb-2 pr-4 ml-1 text-info' onClick={() => resendotphandel("1")} style={{ cursor: "pointer", border: "1px solid", borderRadius: "5px" }}><LiaSmsSolid /> SMS</small>
                                        </div>
                                    )
                                }
                                <div className="text-black-50 mt-3 text-center">
                                    {btstatus == true ? ("") : (

                                        <small style={{ cursor: "pointer" }} onClick={optbuttonhandel}>
                                            <p className="text-primary text-underline">
                                                Resend OTP via
                                            </p>
                                        </small>

                                    )}
                                </div>

                            </form>
                            <div>
                                {otploader_status === true ? (
                                    <button className="btn btn--primary btn-block btn-shadow" type="submit" onClick={Verifyotphandel}>Verify</button>
                                ) : (
                                    <div className='text-center'>
                                        <div className="spinner-border text-primary"></div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        </div>
    )
}

export default Verifyotp
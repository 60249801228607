import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../Home_page/Footer'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import toast, { Toaster } from "react-hot-toast";
import { Backdrop, CircularProgress, Fab, Popover, Typography } from '@mui/material'
import { MdDelete } from 'react-icons/md'
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import swal from 'sweetalert'
import UseFetch from '../customehooks/UseFetch'
import { setcard_list, setwish_list } from '../../readux/arrSlice'
import { useDispatch } from 'react-redux'

const Addtocard = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  let userid = secureLocalStorage.getItem("useid")
  let cardstatus = secureLocalStorage.getItem("cardstatus")
  let [cardlist, setcardlist] = useState()
  // console.log("cartlist", cardlist)
  let [card_id, setcard_id] = useState("")
  const [selectedValues, setSelectedValues] = useState('');
  const [multipledeltechangestatus, setmultipledeltechangestatus] = useState(false);
  let [store_subtotal_pricevalue, set_subtotal_storepricevalue] = useState(0)
  let [store_total_pricevalue, setstore_total_pricevalue] = useState(0)
  let [discountpricevalue, setdiscountpricevalue] = useState(0)
  let [tax_value, settax_value] = useState(0)
  let [loderstatus, setloderstatus] = useState(true)
  let [confirmstatus, setconfirmstatus] = useState(true)
  let [chackout_price, setchackout_price] = useState({})
  let [attributes, setattributes] = useState([])



  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setmultipledeltechangestatus(true)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'popover-button' : undefined;

  // get products with id
  useEffect(() => {
    if (userid != null) {
      getcardlist()
    }
  }, []) // cardlist
  let getcardlist = () => {
    try {
      let data = {
        "userId": userid,
        "status": "0"
      }
      // console.log(data)
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/website_InnoutcartList`, data).then((res) => {
        if (res.status === 200) {
          console.log("cardlist", res.data.data[0]?.products)
          setattributes(res.data.data[0]?.products[0]?.attributes)
          setcardlist(res.data.data[0]?.products)
          setcard_id(res.data.data[0]?._id)
          let storetotalpricevalue = 0
          let mainprice = 0
          let store_sub_totalpricevalue = 0
          let store_tax = 0
          for (let x of res.data.data[0]?.products) {
            store_tax += x?.productId?.Tax[0]?.tax_value
            storetotalpricevalue += x.productId.sale_price * x?.qty
            mainprice += x.productId.sale_price * x?.qty
            store_sub_totalpricevalue += x.productId.unit_price * x?.qty
          }

          console.log("mainprice", store_sub_totalpricevalue)

          setstore_total_pricevalue(storetotalpricevalue.toFixed(2))
          set_subtotal_storepricevalue(store_sub_totalpricevalue.toFixed(2))
          setdiscountpricevalue((storetotalpricevalue - store_sub_totalpricevalue).toFixed(2))
          settax_value(store_tax)


          let discount_value = storetotalpricevalue - store_sub_totalpricevalue
          let chakoutlist_obj = {
            "mainprice": store_sub_totalpricevalue,
            "total": storetotalpricevalue,
            "subtotal": store_sub_totalpricevalue,
            "discout": discount_value.toFixed(2),
            "delivery_charge": res.data.charge?.delivery_charge,
            "commission": res.data.charge?.commission,
            "tax": store_tax,
          }
          setchackout_price({ ...chackout_price, chakoutlist_obj })
          // secureLocalStorage.setItem("checkoutlist", JSON.stringify(chakoutlist_obj))
        }
      }).catch((error) => {
        setcardlist([])
        setTimeout(() => {
          setloderstatus(false)
        }, 1000);
      })
    } catch (error) {
    }
  }
  // remove card list  handel
  let deletecardlist = (items) => {
    swal({
      title: "Are you sure?",
      text: "Want you remove card list this product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteaddress();
      } else {
      }
    });
    let deleteaddress = () => {
      handleOpen()
      try {
        let data = {
          "userId": userid,
          "productId": items?.productId?._id,
          "status": "0"
        }
        // console.log(data)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/deleteinntoutCart`, data).then((res) => {
          if (res.status === 200) {
            autoclose()
            getcardlist()
            toast.success(`${res.data.message}`);
            try {
              let data = {
                "userId": userid,
                "status": "0"
              }
              axios.post(`${process.env.REACT_APP_SECRET_KEY}/InnoutcartList`, data).then((res) => {
                if (res.status === 200) {
                  autoclose()
                  dispatch(setcard_list(res.data.data[0]?.products));
                }
              }).catch((error) => {
                autoclose()
                dispatch(setcard_list([]));
              })
            } catch (error) {
              autoclose()
            }
          }

        }).catch((error) => {
          autoclose()
        })
      } catch (error) {
      }
    }
  }

  // delete card multiple 
  let deletecardmultiple = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedValues(prevValues => prevValues === '' ? `${value}` : prevValues + `,${value}`);
    } else {
      setSelectedValues(prevValues => prevValues.split(`,${value}`).join('').split(`${value},`).join('').split(`${value}`).join(''));
    }
  }

  // multiple card delate 
  let [deleteloder, setdeleteloder] = useState(true)
  let chkcarddeletehandel = () => {
    if (selectedValues !== '') {
      setdeleteloder(false)
      try {
        let data = {
          "userId": userid,
          "productids": selectedValues,
          "status": "0"
        }
        // console.log(data)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/deleteMultipleCart`, data).then((res) => {
          // console.log(res)
          if (res.status === 200) {
            setdeleteloder(true)
            setSelectedValues('')
            setmultipledeltechangestatus(false)
            getcardlist()
            toast.success(`${res.data.message}`);
            let data = {
              "userId": userid,
              "status": "0"
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/InnoutcartList`, data).then((res) => {
              if (res.status === 200) {
                dispatch(setcard_list(res.data.data[0]?.products));
              }
            }).catch((error) => {
              dispatch(setcard_list([]));
            })
          }

        }).catch((error) => {
        })
      } catch (error) {
      }
    } else {
      toast.error(`Pleace Select Card`);
    }
    setAnchorEl(null);
  }

  // add multi chkwishlisthandel
  let [wishloder, setwishloder] = useState(true)
  let chkwishlisthandel = () => {
    if (selectedValues !== '') {
      setwishloder(false)
      try {
        let data = {
          "userId": userid,
          "productIds": selectedValues.split(','),
          // "status": "0"
        }
        // console.log(data)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeMultipleProducts`, data).then((res) => {
          // console.log(res)
          if (res.status === 200) {
            setwishloder(true)
            setSelectedValues('')
            setmultipledeltechangestatus(false)
            getcardlist()
            toast.success(`${res.data.message}`);
            let datawishlist = {
              "userId": userid
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, datawishlist).then((res) => {
              if (res.status == 200) {
                dispatch(setwish_list(res.data.data));
              }
            }).catch((error) => {
              dispatch(setwish_list([]));
            })
          }

        }).catch((error) => {
        })
      } catch (error) {
      }
    } else {
      toast.error(`Pleace Select Card`,);
    }
    setAnchorEl(null);
  }

  // multipledeltechange status changes
  let multipledeltechange = () => {
    setmultipledeltechangestatus(true)
  }

  // close delete status 
  let closecarddeletestatus = () => {
    setAnchorEl(null);
    setmultipledeltechangestatus(false)
  }

  // single producs 
  let sigleproducts = (items) => {
    secureLocalStorage.setItem("productId", items?.productId?._id)
    secureLocalStorage.setItem("venderId", items?.productId?.venderId?._id)
    navigate(`/singleprojects_inntout/${items?.productId?._id}/${items?.productId?.venderId?._id}`)
  }

  // update card handel
  let [empty, setempty] = useState("")
  let updatecardhandel = (event, mainindex, innrindex, c_items) => {

    if (event.target.name == "qty") {
      let data = {
        "userId": userid,
        "cartId": card_id,
        "products": [
          {
            "productId": c_items?.productId?._id,
            "attributes": cardlist[mainindex].attributes,
            "qty": event.target.value
          }
        ]
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutUpdatecarts`, data).then((res) => {
        if (res.status === 200) {
          // console.log("qty", res)
          getcardlist()
          toast.success(`${res.data.message}`);
          setempty("")
        }

      }).catch((error) => {
      })

    } else {

      let storecartdata = cardlist[mainindex].attributes
      storecartdata[innrindex].value = event.target.value
      let data = {
        "userId": userid,
        "cartId": card_id,
        "products": [
          {
            "productId": c_items?.productId?._id,
            "attributes": storecartdata,
            "qty": cardlist[mainindex].qty
          }
        ]
      }
      // console.log(data)
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutUpdatecarts`, data).then((res) => {
        if (res.status === 200) {
          // console.log("qty", res)
          getcardlist()
          toast.success(`${res.data.message}`);
          setempty("")
        }

      }).catch((error) => {
      })
    }

  }

  // check out with chk
  let [chekoutloder, setchekoutloder] = useState(true)
  let chekout_multiple = () => {
    if (selectedValues?.length > 0) {
      setchekoutloder(false)
      const foundObjects = selectedValues.split(",").map(id => cardlist.find(item => item.productId._id === id));
      let storeobj = []
      let unit_price = 0
      let total_price = 0
      for (let x of foundObjects) {
        unit_price += x?.productId?.unit_price * x?.qty
        total_price += x?.productId?.sale_price * x?.qty
        let saleprice = x?.productId?.sale_price * x?.qty
        let unitprice = x?.productId?.unit_price * x?.qty
        let saveprice = unitprice - saleprice

        let data = {
          "productId": x?.productId?._id,
          "shopId": x.productId?.venderId._id,
          "image": x?.productId?.image1,
          "product_name": x?.productId?.product_name,
          "shop_name": x.productId?.venderId.shop_name,
          "size": x?.size,
          "color": x?.color,
          "qty": x?.qty,
          "subtotal": saleprice,
          "tax": x?.productId?.Tax[0]?.tax_value,
          "discount": saveprice.toFixed(2),
          "shipping_charge": "0",
          "total": saleprice.toFixed(2),
        }
        storeobj.push(data)
      }

      let save_amount = unit_price - total_price
      let data = {
        // "venderId": "65dc2a9bf5d388de357de67e",
        "userId": userid,
        "products": storeobj,
        "total_item": storeobj?.length,
        "save_amount": save_amount.toFixed(2),
        "total_price": total_price.toFixed(2)
      }

      let chakoutlist_obj = {
        "total": total_price,
        "subtotal": unit_price,
        "discout": save_amount?.toFixed(2),
        "delivery_charge": chackout_price?.chakoutlist_obj?.delivery_charge,
        "commission": chackout_price?.chakoutlist_obj?.commission,
        "tax": chackout_price?.chakoutlist_obj?.tax,
        "mainprice": chackout_price?.chakoutlist_obj?.mainprice,
      }
      // console.log(chakoutlist_obj)
      // secureLocalStorage.setItem("checkoutlist", JSON.stringify(chakoutlist_obj))
      // navigate("/chackoutpayment")
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/checkOut`, data).then((res) => {
        // console.log(res)
        if (res.status === 200) {
          setchekoutloder(true)
          getcardlist()
          toast.success(`${res.data.message}`);
          secureLocalStorage.setItem("checkoutlist", JSON.stringify(chakoutlist_obj))
          setTimeout(() => {
            navigate("/chackoutpayment")
          }, 500);
        }

      }).catch((error) => {
      })
    } else {
      toast.error(`Pleace Select Card`,);
    }
  }

  // chakouthandel all one time 
  let chakouthandel = () => {
    setconfirmstatus(false)
    let storeobj = []
    let attributestore = []
    let unit_price = 0
    let total_price = 0
    for (let x of cardlist) {
      let newmap = x.productId.product_variation.map((items) => {
        return { "name": items.attribute_name, "value": items.attribute_values[0] }
      })
      attributestore.push(newmap)
      unit_price += x?.productId?.unit_price * x?.qty
      total_price += x?.productId?.sale_price * x?.qty
      let saleprice = x?.productId?.sale_price * x?.qty
      let unitprice = x?.productId?.unit_price * x?.qty
      let saveprice = unitprice - saleprice
      let data = {
        "productId": x?.productId?._id,
        "shopId": x.productId?.venderId._id,
        "image": x?.productId?.image1,
        "product_name": x?.productId?.product_name,
        "shop_name": x.productId?.venderId.shop_name,
        "attributes": attributestore[0],
        "qty": x?.qty,
        "subtotal": saleprice,
        "tax": x?.productId?.Tax[0]?.tax_value,
        "discount": saveprice.toFixed(2),
        "shipping_charge": "0",
        "total": saleprice.toFixed(2),
      }
      storeobj.push(data)
    }
    let save_amount = unit_price - total_price
    let data = {
      "userId": userid,
      "products": storeobj,
      "total_item": storeobj?.length,
      "save_amount": save_amount.toFixed(2),
      "total_price": total_price.toFixed(2),
      "status": 1
    }


    axios.post(`${process.env.REACT_APP_SECRET_KEY}/checkOut`, data).then((res) => {
      console.log(res)
      if (res.status === 200) {
        setconfirmstatus(true)
        getcardlist()
        secureLocalStorage.setItem("checkoutid", res?.data?.data?._id)
        secureLocalStorage.setItem("checkoutlist", JSON.stringify(chackout_price?.chakoutlist_obj))
        navigate("/select_address_inntout")
        // chackoutpayment
      }

    }).catch((error) => {
    })
  }

  // window loader
  const [windowloader, setwindowloader] = useState(false);
  const handleOpen = () => {
    setwindowloader(true);

  };
  let autoclose = () => {
    setTimeout(() => {
      setwindowloader(false)
    }, 500);
  }


  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={windowloader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Toaster />
      {cardlist?.length > 0 ? (
        <div>
          <div className="container mt-3 rtl px-0 px-md-3 mb-3" style={{ textAlign: 'left' }} id="cart-summary">
            {/* <h3 className="mt-4 mb-3 text-center text-lg-left mobile-fs-20">Shopping cart</h3> */}
            <div className="row g-3 mx-max-md-0">
              <section className="col-lg-8 px-max-md-0">
                <div className="table-responsive d-none d-lg-block">

                  <div className="card __card cart_information __cart-table mb-3">
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center gap-2 px-0">
                      <a className="text-primary d-flex align-items-center gap-2 pl-2">
                        {/* <img src="https://6valley.6amtech.com/public/assets/front-end/img/cart-store.png" alt /> */}
                        Cart List
                      </a>
                      <div className=" bg-white select-method-border rounded" style={{ display: "none" }}>
                        {multipledeltechangestatus == false ? (
                          <>
                            <HiOutlineDotsHorizontal onClick={handleClick} aria-describedby={id} variant="contained" className='mr-2' style={{ float: "right", fontSize: "25px", cursor: "pointer" }} />
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >

                              <Typography sx={{ p: 2 }}>
                                <div>
                                  <h6 onClick={multipledeltechange} style={{ cursor: "pointer" }}>Select Card</h6>
                                </div>
                              </Typography>
                            </Popover>
                          </>
                        ) : (
                          <>
                            <RxCross2 onClick={closecarddeletestatus} className='mr-2' style={{ float: "right", fontSize: "25px", cursor: "pointer" }} />
                            {deleteloder == true ? (
                              <h6 className='mr-2 btn btn-danger' style={{ float: "right", cursor: "pointer" }} onClick={chkcarddeletehandel}>Delete</h6>
                            ) : (
                              <h6 className=' m-2' style={{ float: "right", cursor: "pointer" }}>
                                <div className='row justify-content-center'>
                                  <div className="spinner-border text-primary"></div>
                                </div>
                              </h6>
                            )}
                            {wishloder == true ? (
                              <></>
                            ) : (
                              <h6 className='m-2 mr-2' style={{ float: "right", cursor: "pointer" }}>
                                <div className='row justify-content-center'>
                                  <div className="spinner-border text-primary"></div>
                                </div>
                              </h6>
                            )}
                            {chekoutloder == true ? (
                              <h6 className='mr-2 btn btn-info' style={{ float: "right", cursor: "pointer", display: "none" }} onClick={chekout_multiple}>Check out</h6>
                            ) : (
                              <h6 className='m-2 mr-3' style={{ float: "right", cursor: "pointer" }}>
                                <div className='row justify-content-center'>
                                  <div className="spinner-border text-primary"></div>
                                </div>
                              </h6>
                            )}
                          </>
                        )}

                        <div >
                          {/* <span className="font-weight-bold">Total shipping cost</span> : <span>$10.00</span> */}
                        </div>
                      </div>
                    </div>
                    <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table __cart-table">
                      <tbody>
                        {cardlist?.length > 0 ? (
                          cardlist?.map((c_items, mainindex) => {
                            let salesdatanum = c_items?.productId?.sale_price * c_items.qty
                            let salesprice_num = Math.trunc(salesdatanum * 100) / 100;
                            let unitdatanum = c_items?.productId?.unit_price * c_items.qty
                            let unit_price_num = Math.trunc(unitdatanum * 100) / 100;
                            return (
                              <tr >
                                <td className="__w-45">
                                  <div className="d-flex gap-3">
                                    <div className>
                                      <a onClick={() => sigleproducts(c_items)} className="position-relative overflow-hidden">
                                        <img className="rounded __img-62 " src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${c_items?.productId?.image1}`} />
                                      </a>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                      <div className="text-break __line-2 __w-18rem ">
                                        <a>{c_items?.productId?.product_name}</a>
                                      </div>
                                      <div className="d-flex flex-wrap gap-2 ">
                                      </div>
                                      <div className="d-flex flex-wrap gap-2 ">
                                        <span>{c_items?.productId?.venderId?.shop_name}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className=" __w-15p">
                                  <div className="text-center">
                                    <div style={{ lineHeight: "18px" }} className="fw-semibold"> CFA{salesprice_num}<span> (-{c_items?.productId?.discount[0]?.discount_value}{c_items?.productId?.discount[0]?.discount_type === "Unit" ? "CFA" : "%"}) </span> <br /> <del className="align-middle fs-16 text-muted">CFA{unit_price_num}</del>  </div>
                                    <span className="text-nowrap fs-10">
                                      (Tax :{c_items?.productId?.Tax[0]?.tax_name} CFA{c_items?.productId?.Tax[0]?.tax_value}%)
                                    </span>
                                  </div>
                                </td>
                                <td className="__w-15p" style={{ paddingLeft: "40px" }} >
                                  <div className='row  mb-2'>
                                    <div className="form-group mr-1">

                                      {c_items?.productId?.product_variation?.map((items, innrindex) => {
                                        return (
                                          <select onChange={(event) => updatecardhandel(event, mainindex, innrindex, c_items)} value={empty} className="form-control p-1 mt-1" style={{ cursor: "pointer" }}>
                                            {items?.attribute_values.map((itm, ind) => {
                                              return (
                                                <>
                                                  {/* <option hidden>{items?.attribute_values[0]}</option> */}
                                                  <option key={ind} value={itm}>{itm}</option>
                                                </>
                                              )
                                            })}
                                          </select>
                                        )
                                      })}

                                      <select onChange={(event) => updatecardhandel(event, mainindex, "innrindex", c_items)} name="qty" value={empty} className="form-control p-1 mt-1" style={{ cursor: "pointer" }}>
                                        <option hidden>{c_items.qty}</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>

                                      </select>

                                    </div>
                                  </div>

                                </td>
                                <td className="__w-15p text-end">
                                  {multipledeltechangestatus == false ? (
                                    <div>
                                      <Fab size="small" style={{ zIndex: 0 }} aria-label="edit" onClick={() => deletecardlist(c_items)}>
                                        <MdDelete style={{ fontSize: "20px", color: "red" }} />
                                      </Fab>
                                    </div>
                                  ) : (
                                    <div>
                                      <input onClick={deletecardmultiple} value={c_items?.productId?._id} className='' type="checkbox" name="" for="hello" style={{ width: "50%", height: "16px", cursor: "pointer" }} />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <div className='col-12 row  justify-content-center mt-5 mb-5  '>
                            <div className='col-lg-4 col-12 text-center'>
                              <div>
                                <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                              </div>
                              <h6 className='mt-2'>Data Not Found</h6>
                              {/* <div>
                                       <button className='btn btn-info mt-2'><Link to="/">Back to shopping</Link></button>
                              </div> */}
                            </div>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <div className="cart_information mb-3 pb-3 w-100 d-lg-none">

                <div className=" bg-white select-method-border rounded">
                  {multipledeltechangestatus == false ? (
                    <>
                      {/* <HiOutlineDotsHorizontal onClick={handleClick} aria-describedby={id} variant="contained" className='mr-4' style={{ float: "right", fontSize: "25px", cursor: "pointer" }} /> */}
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >

                        <Typography sx={{ p: 2 }}>
                          <div>
                            <h6 onClick={multipledeltechange} style={{ cursor: "pointer" }}>Select Card</h6>
                          </div>
                        </Typography>
                      </Popover>
                    </>
                  ) : (
                    <>
                      {/* <RxCross2 onClick={closecarddeletestatus} className='mr-4' style={{ float: "right", fontSize: "25px", cursor: "pointer" }} /> */}
                      {deleteloder == true ? (
                        <h6 className='mr-2 btn btn-danger' style={{ float: "right", cursor: "pointer" }} onClick={chkcarddeletehandel}>Delete</h6>
                      ) : (
                        <h6 className=' m-2' style={{ float: "right", cursor: "pointer" }}>
                          <div className='row justify-content-center'>
                            <div className="spinner-border text-primary"></div>
                          </div>
                        </h6>
                      )}
                      {wishloder == true ? (
                        <h6 className='mr-2 btn btn-info' style={{ float: "right", cursor: "pointer" }} onClick={chkwishlisthandel}>Add Wish List</h6>
                      ) : (
                        <h6 className='m-2 mr-2' style={{ float: "right", cursor: "pointer" }}>
                          <div className='row justify-content-center'>
                            <div className="spinner-border text-primary"></div>
                          </div>
                        </h6>
                      )}
                      {chekoutloder == true ? (
                        <h6 className='mr-2 btn btn-info' style={{ float: "right", cursor: "pointer", display: "none" }} onClick={chekout_multiple}>Check out</h6>
                      ) : (
                        <h6 className='m-2 mr-3' style={{ float: "right", cursor: "pointer" }}>
                          <div className='row justify-content-center'>
                            <div className="spinner-border text-primary"></div>
                          </div>
                        </h6>
                      )}
                    </>
                  )}
                  <div >
                  </div>
                </div>
                <div className="gap-3 p-3 fs-12  ">
                  <div className=" gap-3">
                    {
                      cardlist?.map((c_items, mainindex) => {
                        let salesdatanum = c_items?.productId?.sale_price * c_items.qty
                        let salesprice_num = Math.trunc(salesdatanum * 100) / 100;
                        let unitdatanum = c_items?.productId?.unit_price * c_items.qty
                        let unit_price_num = Math.trunc(unitdatanum * 100) / 100;


                        return (
                          <div>
                            <div className='row col-12'>
                              <div className='col-5 mt-2'>
                                <a onClick={() => sigleproducts(c_items)} className="position-relative overflow-hidden">
                                  <img className="rounded __img-62 " src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${c_items?.productId?.image1}`} />
                                </a>
                              </div>
                              <div className='col-7'>
                                {multipledeltechangestatus == false ? (
                                  <div>
                                    <Fab size="small" style={{ zIndex: 0, float: "right" }} aria-label="edit" className='mt-2' onClick={() => deletecardlist(c_items)}>
                                      <MdDelete style={{ fontSize: "20px", color: "red" }} />
                                    </Fab>
                                  </div>
                                ) : (
                                  <div>
                                    <input onClick={deletecardmultiple} value={c_items?.productId?._id} className='mt-2' type="checkbox" name="" for="hello" style={{ width: "50%", height: "16px", cursor: "pointer", float: "right" }} />
                                  </div>
                                )}
                              </div>
                            </div>


                            <div className="d-flex flex-column gap-1" style={{ borderBottom: "1px solid gray" }}>
                              <div className="text-break __line-2 ">
                                <a>{c_items?.productId?.product_name}</a>
                                <a>{c_items?.productId?.venderId?.shop_name}</a>
                              </div>

                              <div className='row justify-content-center'>
                                <div className="form-group mr-1 row">
                                  {c_items?.productId?.product_variation?.map((items, innrindex) => {
                                    return (
                                      <select onChange={(event) => updatecardhandel(event, mainindex, innrindex, c_items)} value={empty} className="form-control p-1 mt-1" style={{ cursor: "pointer" }}>
                                        {items?.attribute_values.map((itm, ind) => {
                                          return (
                                            <>
                                              <option value={itm}>{itm}</option>
                                            </>
                                          )
                                        })}
                                      </select>
                                    )

                                  })}

                                  <select onChange={(event) => updatecardhandel(event, mainindex, "innrindex", c_items)} name="qty" value={empty} className="form-control p-1 mt-1" style={{ cursor: "pointer" }}>
                                    <option hidden>{c_items.qty}</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                  </select>
                                </div>
                              </div>

                              <div className="d-flex flex-wrap column-gap-2 mb-3 ">
                                <div className="text-nowrap text-muted">Sale price :</div>
                                <div className="text-start d-flex gap-1 flex-wrap">
                                  <div className="fw-semibold">CFA{salesprice_num}  <del className="">CFA{unit_price_num}</del>  ({c_items?.productId?.discount[0]?.discount_value}%)</div>
                                </div>
                              </div>

                            </div>

                          </div>
                        )
                      })
                    }
                  </div>

                </div>

              </div>
              <aside className="col-lg-4 pt-4 pt-lg-2 px-max-md-0">
                <div className="__cart-total __cart-total_sticky">
                  <div className="cart_total p-0">
                    <h6 className="text-center text-primary mb-4 d-flex align-items-center justify-content-center gap-2">
                      <img src="./logo/offer.svg" alt />
                      You have Saved <strong>CFA{discountpricevalue}!</strong>
                    </h6>
                    <div className="d-flex justify-content-between">
                      <span className="cart_title">Sub total</span>
                      <span className="cart_value">
                        {store_subtotal_pricevalue}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between">
                      <span className="cart_title">Discount</span>
                      <span className="cart_value">
                        {discountpricevalue}
                      </span>
                    </div>
                    {/* <div className="pt-2">
                      <form className="needs-validation coupon-code-form" action="javascript:" method="post" noValidate id="coupon-code-ajax">
                        <div className="d-flex form-control rounded-pill pl-3 p-1">
                          <img width={24} src="./logo/coupon.svg" alt />
                          <input className="input_code border-0 px-2 text-dark bg-transparent outline-0 w-100" type="text" name="code" placeholder="Coupon code" required />
                          <button className="btn btn--primary rounded-pill text-uppercase py-1 fs-12" type="button" onclick="couponCode()">Apply</button>
                        </div>
                        <div className="invalid-feedback">Please provide coupon code</div>
                      </form>
                    </div> */}
                    <hr className="my-2" />
                    <div className="d-flex justify-content-between">
                      <span className="cart_title text-primary font-weight-bold">Total</span>
                      <span className="cart_value">
                        {store_total_pricevalue}
                      </span>
                    </div>
                  </div>
                  <div className="mt-4">

                    {confirmstatus == true ?
                      (<div onClick={chakouthandel} className="btn btn--primary btn-block proceed_to_next_button ">Check out</div>)
                      : (
                        <div className='row justify-content-center'>
                          <div className="spinner-border text-primary"></div>
                        </div>
                      )}

                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link to="/inntoutHome" className="d-flex align-items-center gap-2 text-primary font-weight-bold">
                      <i className="tio-back-ui fs-12" /> Continue Shopping
                    </Link>
                  </div>
                </div>
              </aside>
              {/* <div className="bottom-sticky3 bg-white p-3 shadow-sm w-100 d-lg-none">
                <div className="d-flex justify-content-center align-items-center fs-14 mb-2">
                  <div className="product-description-label fw-semibold text-capitalize">Total price : </div>
                  &nbsp; <strong className="text-base">$3,524.10</strong>
                </div>
                <a onclick="checkout()" className="btn btn--primary btn-block proceed_to_next_button text-capitalize ">Proceed to next</a>
              </div> */}
            </div>
          </div>
        </div >
      ) : (
        loderstatus == true ? (
          <div className='text-center mt-5 mb-5'>
            <div className='row justify-content-center mt-5'>
              <div className="spinner-border text-primary"></div>
            </div>
          </div>
        ) : (
          <div className='col-12 row  justify-content-center mt-5 mb-5  '>
            <div className='col-lg-4 col-12 text-center'>
              <div>
                <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
              </div>
              <h6 className='mt-2'>Data Not Found</h6>
              {/* <div>
                <button className='btn btn-info mt-2'><Link to="/">Back to shopping</Link></button>
              </div> */}
            </div>
          </div>
        )
      )
      }
      <Footer />
    </ >
  )
}

export default Addtocard
import React from 'react'
import Header from './Home_page/Header'
import { useNavigate } from 'react-router-dom'
import Footer from './Home_page/Footer'
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { AiOutlineMail } from "react-icons/ai";
import { LiaSmsSolid } from "react-icons/lia";
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
// style otp auto foucs
const otpInputStyle = {
    width: "70%",
    height: "100px",
    marginBottom: "30px"
};
const inputStyle = {
    width: "70%",
    height: "50px",
    marginBottom: "10px",
    border: "1px solid gray",
};
const inputProps = {
    type: "number",
};

const Forgotpassword = () => {
    let navigate = useNavigate()
    let forgot_email_number = secureLocalStorage.getItem("forgot_email_number")
    let [otpvalue, setotpvalue] = useState(
        () => {
            let localotp = secureLocalStorage.getItem("otp")
            if (localotp != null) {
                return localotp
            } else {
                return ""
            }
        }
    )
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [otp, setOtp] = useState('');
    const [forgotpass_status, setforgotpass_status] = useState(false);
    let [inputpassword, setinputpassword] = useState("")
    let [inputconfirmpassword, setinputconfirmpassword] = useState("")
    let [status, setstatus] = useState("")

    // show password icons
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    // verifyotp handel 
    let [otploaderstatus, setotploaderstatus] = useState(true)
    let Verifyotphandel = (event) => {
        event.preventDefault()
        setotploaderstatus(false)
        let data = {
            "key": forgot_email_number,
            "otp": otp
        }
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/verifyEmail_andMobile`, data).then((res) => {
            if (res.data.result) {
                setotploaderstatus(true)
                setforgotpass_status(true)
                swal({
                    text: `Otp verify sucessfully`,
                    icon: "success",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            // navigate(`/`)
                        }
                    });
            }
        }).catch((error) => {
            setotploaderstatus(true)
            if (error.response && error.response.status === 400) {
                swal({
                    text: `${error.response.data.message}`,
                    icon: "error",
                    dangerMode: false,
                })
            }
        })
    }

    // reset password 
    let [resetloaderstatus, setresetloaderstatus] = useState(true)
    let resetpassword = (evnet) => {
        evnet.preventDefault()
        
        if (inputpassword === inputconfirmpassword) {
            setresetloaderstatus(false)
            let data = {
                "key": forgot_email_number,
                "password": inputconfirmpassword
            }
            setstatus("")
            
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/resetPassword`, data).then((res) => {
                if (res.data.result) {
                    setresetloaderstatus(true)
                    secureLocalStorage.setItem("token", res.data.token)
                    secureLocalStorage.setItem("useid", res?.data?.data?._id)
                    secureLocalStorage.removeItem("forgot_email_number")
                    secureLocalStorage.removeItem("otp")
                    swal({
                        text: `Password Reset Sucessfully`,
                        icon: "success",
                        dangerMode: true,
                    })
                        .then(willDelete => {
                            if (willDelete) {
                                navigate(`/login`)
                            }
                        });
                }
            }).catch((error) => {
                setresetloaderstatus(true)
                if (error.response && error.response.status === 400) {
                    swal({
                        text: `${error.response.data.message}`,
                        icon: "error",
                        dangerMode: false,
                    })
                }
            })


        } else {
            setstatus("Not Match Password")
        }
    }
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                // transition:Flip
                theme="colored"
            />
            <div>
                <Header />
                <div className="container py-4 py-lg-5 my-4" style={{ textAlign: 'left' }}>
                    <div className="login-card">
                        <div className="mx-auto __max-w-360">
                            {forgotpass_status == false ? (
                                <>
                                    <h6 className="text-center h5 mb-2 font-bold text-capitalize fs-18-mobile">Forgot Password</h6>
                                    <form className="needs-validation mt-2"  >
                                        <div className="form-group text-center">
                                            <div>
                                                <small>Please Enter The Code Send To</small>
                                            </div>
                                            <div>
                                                <small>{forgot_email_number}</small>
                                            </div>
                                            <div>
                                                <small><b>{otpvalue}</b></small>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="password-toggle rtl">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={4}
                                                    renderSeparator={<span>-</span>}
                                                    inputStyle={inputStyle}
                                                    style={otpInputStyle}
                                                    renderInput={(props) => <input {...props} />}
                                                    inputProps={inputProps}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                    {otploaderstatus == true ? (
                                        <button className="btn btn--primary btn-block btn-shadow" type="submit" onClick={Verifyotphandel}>Verify</button>
                                    ) : (
                                        <div className='text-center'>
                                            <div className="spinner-border text-primary"></div>
                                        </div>
                                    )}

                                </>
                            ) : (
                                <form className="needs-validation mt-2"  >
                                    <div className="form-group">
                                        <small className='text-danger'>{status}</small><br />
                                        <label className="form-label font-semibold">Enter your new password</label>
                                        <div className="password-toggle rtl">
                                            <input className="form-control borderinput" name="password" onChange={(e) => setinputpassword(e.target.value)} type={showPassword ? 'text' : 'password'} id="si-password" style={{ textAlign: 'left' }} />
                                            <span style={{ position: "absolute", top: "5px", right: "10px", fontSize: "20px", cursor: "pointer" }} onClick={togglePasswordVisibility} >
                                                {showPassword ? '👁️' : '👁️‍🗨️'}
                                            </span>
                                        </div>
                                        <div className='mt-2'>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label font-semibold">Confirm your new password</label>
                                        <div className="password-toggle rtl">
                                            <input className="form-control borderinput" name="password" onChange={(e) => setinputconfirmpassword(e.target.value)} type={showPassword1 ? 'text' : 'password'} id="si-password" style={{ textAlign: 'left' }} />
                                            <span style={{ position: "absolute", top: "5px", right: "10px", fontSize: "20px", cursor: "pointer" }} onClick={togglePasswordVisibility1} >
                                                {showPassword1 ? '👁️' : '👁️‍🗨️'}
                                            </span>
                                        </div>
                                        <div className='mt-2'>
                                        </div>
                                    </div>
                                    {inputpassword?.length > 0 && inputconfirmpassword?.length > 0 ? (
                                        <>{resetloaderstatus == true ? (
                                            <button className="btn btn--primary btn-block btn-shadow" onClick={resetpassword} >Continue</button>
                                    ) : (
                                        <div className='text-center'>
                                            <div className="spinner-border text-primary"></div>
                                        </div>
                                    )}</>
                                     
                                    ) : (
                                        <button className="btn btn--primary btn-block btn-shadow" disabled >Continue</button>
                                    )}
                                </form>
                            )}





                        </div>
                    </div>


                </div>



                <Footer />
            </div>


        </div>
    )
}

export default Forgotpassword
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet'; // Import Icon from Leaflet
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png'; // Import default marker icon image
import axios from 'axios'

// Create a Leaflet icon instance using the default marker icon image
const defaultIcon = new Icon({
    iconUrl: markerIcon,
    iconSize: [25, 41], // Default icon size
    iconAnchor: [12, 41], // Icon anchor point
});

const Allshopsinntout = () => {
    const [location, setLocation] = useState(null);
    // console.log(location)
    let leti = 22.7475436
    let longi = 75.8963415
    const [show_shoplist, setshow_shoplist] = useState();
    // get current location 
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    // console.error('Error getting location:', error);
                }
            );
        } else {
            // console.error('Geolocation is not supported by this browser.');
        }
    }, []);


    // get wish list 
    useEffect(() => {
        getinnt_shoplist()
    }, [0])
    let getinnt_shoplist = () => {
        try {
            let data = {
                "latitude": "22.719568",
                "longitude": "75.857727"
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutShop_list`, data).then((res) => {
                // console.log(res.data.data)
                if (res.status == 200) {
                    setshow_shoplist(res.data.data)
                }
            }).catch((error) => {
                // console.log(error)

            })
        } catch (error) {
        }
    }

    return (
        <div className="container pb-5 mb-2 mb-md-4 mt-3 rtl" style={{ textAlign: 'left' }}>
            <div className='row' >
                <div className='col-lg-7 row justify-content-center' >
                    {show_shoplist?.map((items, index) => {
                        return (
                            <div key={index} className='col-lg-10 col-md-10 col-12 mb-2 rounded shadow' style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                <div className="p-2 justif  y-content-between gap-2">
                                    <div >
                                        <div className=" mb-2">
                                            <div className="">
                                                <>
                                                    <div className='row jsutify-content-between mb-2'>
                                                        {/* {`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${""}`}  */}
                                                        <div className='col-lg-5 text-center'>
                                                            <img width={"100%"} style={{ maxHeight: "200px" }} className="border" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items.shop_logo}`} alt="img/products" />
                                                        </div>
                                                        <div className='col-lg-7'>

                                                            <div className="cont text-start">
                                                                <h6 className="font-weight-bold mb-2 mt-3 fs-14">
                                                                    <a>
                                                                        Shop Name: <span style={{ color: "gray" }}>{items.shop_name}</span>
                                                                    </a>
                                                                </h6>
                                                                <h6 className="font-weight-bold m-t-2 mb-2 mt-3 fs-14">
                                                                    <a>
                                                                        Address: <span style={{ color: "gray" }}>{items.shop_address}</span>
                                                                    </a>
                                                                </h6>
                                                                <h6 className="font-weight-bold m-t-2 mb-2 mt-3 fs-14">
                                                                    <a>
                                                                        City: <span style={{ color: "gray" }}>{items.city}</span> 
                                                                    </a>
                                                                </h6>
                                                                <h6 className="font-weight-bold m-t-2 mb-2 mt-3 fs-14">
                                                                    <a>
                                                                        Open Time: <span style={{ color: "gray" }}>{items?.workHours[0]?.open_time}</span>
                                                                    </a>
                                                                </h6>
                                                                <h6 className="font-weight-bold m-t-2 mb-2 mt-3 fs-14">
                                                                    <a>
                                                                        Closed Time: <span style={{ color: "gray" }}>{items?.workHours[0]?.close_time}</span>
                                                                    </a>
                                                                </h6>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </>

                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='col-lg-5' >
                    <div>
                        <MapContainer
                            center={[leti, longi]}
                            zoom={10}
                            style={{ height: '400px', width: '100%', zIndex: 0 }}
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            {show_shoplist?.map((location, index) => {
                                return (
                                    <Marker
                                        key={index}
                                        position={[location?.location?.coordinates[1], location?.location?.coordinates[0]]}
                                        icon={defaultIcon} // Use the default icon
                                    >
                                        <Popup style={{ width: '100%', cursor: "pointer" }}>
                                            <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${location.shop_logo}`} width={"100%"} className='img-fluid' alt="" />
                                            {location.shop_name}
                                        </Popup>
                                    </Marker>
                                )
                            })}
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Allshopsinntout
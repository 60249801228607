import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import './header.css'
import Auth_header_inntout from '../Inntout/Auth_header_inntout'
import Auth_header_innt from './Auth_header_innt'
import secureLocalStorage from 'react-secure-storage'
import { useSelector } from 'react-redux'

const Auth_header = () => {
  let location = useLocation();
  let getlocalpath = secureLocalStorage.getItem("pagepath")
  const pathname = useSelector(state => state.arr);
  // console.log("pathnameredux",pathname?.pathnameinntout)

  useEffect(() => {
    const intervalId = setInterval(() => {
    }, 0);
    return () => clearInterval(intervalId);
  }, [getlocalpath]);
  console.log("headerinntourpath", getlocalpath)


  let componentToRender;

  switch (location.pathname) {
    case '/inntoutHome':
    case '/Inntoutproductslist':
    case '/inntOutcheckoutList':
    case '/addtocardlist_inntout':
    case '/inntoutorderlist':
    case '/inntout_searchlist':
    case '/allshopsinntout':
    case '/mobileproductsearch_inntout':
    case '/inntout_orderdetails':
    case '/Orderstatus_inntout':
    case '/todaysOffers_all':
    case '/topSales_all':
    case '/select_address_inntout':
    case '/discountProduct_list_inntout':
    case '/topsaller_details_inntout':
    case '/shopview_inntout':
    case `${getlocalpath}`:
      componentToRender = <Auth_header_inntout />;
      break;
    default:
      componentToRender = <Auth_header_innt />;
      break;
  }


  return (
    <div>
      {componentToRender}
    </div>
  )
}

export default Auth_header;

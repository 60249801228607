import React from 'react'
import Footer from '../Home_page/Footer'
import { Link } from 'react-router-dom'

const Restaurantdetails = () => {
  return (
    <div>
      {/* <span id="authentication-status" data-auth="false" /> */}
      {/* <div className="row">
        <div className="col-12" style={{ marginTop: '10rem', position: 'fixed', zIndex: 9999 }}>
          <div id="loading" style={{ display: 'none' }}>
            <center>
              <img width={200} src="https://thumbs.dreamstime.com/b/cozy-restaurant-tables-ready-dinner-39875776.jpg" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/loader.gif'" />
            </center>
          </div>
        </div>
      </div> */}
      <div className="container py-4 __inline-67">
        <div className="rtl">
          <div className="bg-white __shop-banner-main">
            <img className="__shop-page-banner" src="https://thumbs.dreamstime.com/b/cozy-restaurant-tables-ready-dinner-39875776.jpg" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/image-place-holder.png'" alt />
            <div className="position-relatve z-index-99 rtl w-100" style={{ textAlign: 'left' }}>
              <div className="__rounded-10 bg-white position-relative">
                <div className="d-flex flex-wrap justify-content-between seller-details">
                  <div className="d-flex align-items-center p-2 flex-grow-1">
                    <div className>
                      <div className="position-relative">
                        <img className="__inline-68" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLaZXJihWyYrsvdYnGsnjZh_I_v1I-NmVM3YOpaO8I1w&s" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/image-place-holder.png'" alt />
                      </div>
                    </div>
                    <div className="__w-100px flex-grow-1  pl-2 pl-sm-4">
                      <span className="font-weight-bold ">
                        Mart Morning
                      </span>
                      <div className>
                        <div className="d-flex flex-wrap __text-12px py-1 fw-bold" style={{ color: '#1455ac' }}>
                          <span className="text-nowrap">2 Reviews </span>
                          <span className="__inline-69" />
                          <span className="text-nowrap">23 Orders</span>
                        </div>
                        <div>
                          <i className="tio-star text-warning" />
                          <i className="tio-star text-warning" />
                          <i className="tio-star text-warning" />
                          <i className="tio-star text-warning" />
                          <i className="tio-star-half text-warning" />
                          (<span className="ml-1">4.5</span>)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mr-sm-4">
                      <div className="d-flex">
                        <Link to="/inbox" className="btn btn--primary __inline-70 rounded-10  text-capitalize chat-with-seller-button">
                          <img src="https://6valley.6amtech.com/public/assets/front-end/img/shopview-chat.png" loading="eager" className alt />
                          <span className="d-none d-sm-inline-block">
                            Chat with seller
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-3 justify-content-sm-between py-4" dir="left">
          <div className="d-flex flex-wrap justify-content-between align-items-center w-max-md-100 me-auto gap-3">
            <h3 className="widget-title align-self-center font-bold __text-18px my-0">Categories</h3>
            <div className="filter-ico-button btn btn--primary p-2 m-0 d-lg-none d-flex align-items-center">
              <i className="tio-filter" />
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row gap-3">
            <form>
              <div className="sorting-item">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} viewBox="0 0 20 21" fill="none">
                  <path d="M11.6667 7.80078L14.1667 5.30078L16.6667 7.80078" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.91675 4.46875H4.58341C4.3533 4.46875 4.16675 4.6553 4.16675 4.88542V8.21875C4.16675 8.44887 4.3533 8.63542 4.58341 8.63542H7.91675C8.14687 8.63542 8.33341 8.44887 8.33341 8.21875V4.88542C8.33341 4.6553 8.14687 4.46875 7.91675 4.46875Z" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.91675 11.9688H4.58341C4.3533 11.9688 4.16675 12.1553 4.16675 12.3854V15.7188C4.16675 15.9489 4.3533 16.1354 4.58341 16.1354H7.91675C8.14687 16.1354 8.33341 15.9489 8.33341 15.7188V12.3854C8.33341 12.1553 8.14687 11.9688 7.91675 11.9688Z" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14.1667 5.30078V15.3008" stroke="#D9D9D9" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <label className="for-shoting" htmlFor="sorting">
                  <span className="text-nowrap">Sort by</span>
                </label>
                <select onchange="sort_by_data(this.value)">
                  <option value="latest">Latest</option>
                  <option value="low-high">Low to High Price </option>
                  <option value="high-low">High to Low Price</option>
                  <option value="a-z">A to Z Order</option>
                  <option value="z-a">Z to A Order</option>
                </select>
              </div>
            </form>
            <form method="get" action="https://6valley.6amtech.com/shopView/2">
              <div className="search_form input-group search-form-input-group">
                <input type="hidden" name="category_id" defaultValue />
                <input type="hidden" name="sub_category_id" defaultValue />
                <input type="hidden" name="sub_sub_category_id" defaultValue />
                <input type="text" className="form-control rounded-left" name="product_name" style={{ textAlign: 'left' }} defaultValue placeholder="Search products from this store" />
                <button type="submit" className="btn--primary btn">
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row rtl">
          <div className="col-lg-3 mr-0 pr-4">
            <aside className="SearchParameters" id="SearchParameters">
              <div className="__shop-page-sidebar">
                <div className="cz-sidebar-header">
                  <button className="close ml-auto" type="button" data-dismiss="sidebar" aria-label="Close">
                    <i className="tio-clear" />
                  </button>
                </div>
                <div className="accordion __cate-side-arrordion">
                  <div className="menu--caret-accordion">
                    <div className="card-header flex-between">
                      <div>
                        <label className="for-hover-lable cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?category_id=28'">
                          Phones &amp; Telecom
                        </label>
                      </div>
                      <div className="px-2 cursor-pointer menu--caret">
                        <strong className="pull-right for-brand-hover">
                          <i className="tio-next-ui fs-13" />
                        </strong>
                      </div>
                    </div>
                    <div className="card-body p-0 ml-2" id="collapse-28" style={{ display: 'none' }}>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=52'">
                              Mobile Accessories
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-52" style={{ display: 'none' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu--caret-accordion">
                    <div className="card-header flex-between">
                      <div>
                        <label className="for-hover-lable cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?category_id=11'">
                          Home Improvement &amp; Tools
                        </label>
                      </div>
                      <div className="px-2 cursor-pointer menu--caret">
                        <strong className="pull-right for-brand-hover">
                          <i className="tio-next-ui fs-13" />
                        </strong>
                      </div>
                    </div>
                    <div className="card-body p-0 ml-2" id="collapse-11" style={{ display: 'none' }}>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=41'">
                              Home Tools
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-41" style={{ display: 'none' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu--caret-accordion">
                    <div className="card-header flex-between">
                      <div>
                        <label className="for-hover-lable cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?category_id=16'">
                          ebook
                        </label>
                      </div>
                      <div className="px-2 cursor-pointer menu--caret">
                        <strong className="pull-right for-brand-hover">
                        </strong>
                      </div>
                    </div>
                    <div className="card-body p-0 ml-2" id="collapse-16" style={{ display: 'none' }}>
                    </div>
                  </div>
                  <div className="menu--caret-accordion">
                    <div className="card-header flex-between">
                      <div>
                        <label className="for-hover-lable cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?category_id=15'">
                          Women's fashion
                        </label>
                      </div>
                      <div className="px-2 cursor-pointer menu--caret">
                        <strong className="pull-right for-brand-hover">
                          <i className="tio-next-ui fs-13" />
                        </strong>
                      </div>
                    </div>
                    <div className="card-body p-0 ml-2" id="collapse-15" style={{ display: 'none' }}>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=45'">
                              Bags &amp; Shoes
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                              <i className="tio-next-ui fs-13" />
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-45" style={{ display: 'none' }}>
                          <div className="card-header">
                            <label className="for-hover-lable d-block cursor-pointer text-left" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_sub_category_id=61'">
                              Hand Bag
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=46'">
                              Clothing
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-46" style={{ display: 'none' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu--caret-accordion">
                    <div className="card-header flex-between">
                      <div>
                        <label className="for-hover-lable cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?category_id=34'">
                          Home, Pet &amp; Appliances
                        </label>
                      </div>
                      <div className="px-2 cursor-pointer menu--caret">
                        <strong className="pull-right for-brand-hover">
                          <i className="tio-next-ui fs-13" />
                        </strong>
                      </div>
                    </div>
                    <div className="card-body p-0 ml-2" id="collapse-34" style={{ display: 'none' }}>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=54'">
                              Pet Appliances
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                              <i className="tio-next-ui fs-13" />
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-54" style={{ display: 'none' }}>
                          <div className="card-header">
                            <label className="for-hover-lable d-block cursor-pointer text-left" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_sub_category_id=59'">
                              Dog Food
                            </label>
                          </div>
                          <div className="card-header">
                            <label className="for-hover-lable d-block cursor-pointer text-left" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_sub_category_id=60'">
                              Cat Food
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=53'">
                              Home Appliances
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-53" style={{ display: 'none' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu--caret-accordion">
                    <div className="card-header flex-between">
                      <div>
                        <label className="for-hover-lable cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?category_id=25'">
                          Beauty, Health &amp; Hair
                        </label>
                      </div>
                      <div className="px-2 cursor-pointer menu--caret">
                        <strong className="pull-right for-brand-hover">
                          <i className="tio-next-ui fs-13" />
                        </strong>
                      </div>
                    </div>
                    <div className="card-body p-0 ml-2" id="collapse-25" style={{ display: 'none' }}>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=49'">
                              Beauty Products
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-49" style={{ display: 'none' }}>
                        </div>
                      </div>
                      <div className="menu--caret-accordion">
                        <div className="for-hover-lable card-header flex-between">
                          <div>
                            <label className="cursor-pointer" onclick="location.href='https://6valley.6amtech.com/shopView/2?sub_category_id=50'">
                              Hair Care
                            </label>
                          </div>
                          <div className="px-2 cursor-pointer menu--caret">
                            <strong className="pull-right">
                            </strong>
                          </div>
                        </div>
                        <div className="card-body p-0 ml-2" id="collapse-50" style={{ display: 'none' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <div className="col-lg-9 product-div">
            <div className="row g-3" id="ajax-products">
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-2 col-md-3 col-sm-4 col-6 col-lg-3 col-md-4 col-sm-4 col-6 p-2">
                <div className="product-single-hover style--card">
                  <div className="overflow-hidden position-relative">
                    <div className=" inline_product clickable d-flex justify-content-center">
                      <span className="for-discoutn-value p-1 pl-2 pr-2">
                        10%
                        Off
                      </span>
                      <div className="p-10px pb-0">
                        <Link to="/singleprojects" className="w-100">
                          <img src="https://images.unsplash.com/photo-1598514982901-ae62764ae75e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        </Link>
                      </div>
                      <div className="quick-view">
                        <a className="btn-circle stopPropagation" href="javascript:" onclick="quickView('81')">
                          <i className="czi-eye align-middle" />
                        </a>
                      </div>
                    </div>
                    <div className="single-product-details">
                      <div className="rating-show justify-content-between text-center">
                        <span className="d-inline-block font-size-sm text-body">
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <i className="tio-star-outlined text-warning" />
                          <label className="badge-style">( 0 )</label>
                        </span>
                      </div>
                      <div className="text-center">
                        <a href="#">
                          Manchurian
                        </a>
                      </div>
                      <div className="justify-content-between text-center">
                        <div className="product-price text-center d-flex flex-wrap justify-content-center align-items-center gap-8">
                          <strike style={{ fontSize: '12px', color: '#9B9B9B' }}>
                            $5,000.00
                          </strike><br />
                          <span className="text-accent text-dark">
                            $4,500.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation" id="paginator-ajax">
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="filter_url" data-url="https://6valley.6amtech.com/shopView/2" />
      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-faded-info">
              <h5 className="modal-title" id="exampleModalLongTitle">Send Message to seller</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="https://6valley.6amtech.com/messages-store" method="post" id="chat-form">
                <input type="hidden" name="_token" defaultValue="nYVNGcTo4sGmUohKgT9JFnGi2HhEmmUi2GCTeoUb" /> <input defaultValue={2} name="shop_id" hidden />
                <input defaultValue="2}" name="seller_id" hidden />
                <textarea name="message" className="form-control" required placeholder="Write here..." defaultValue={""} />
                <br />
                <div className="justify-content-end gap-2 d-flex flex-wrap">
                  <a href="https://6valley.6amtech.com/chat/seller" className="btn btn-soft-primary bg--secondary border">
                    Go to chatbox
                  </a>
                  <button className="btn btn--primary text-white">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Restaurantdetails

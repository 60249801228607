import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Category_Sceleten from '../Skeleten/Category_Sceleten'

const Categories = () => {

  let navigate = useNavigate()
  let [showcategorylist, setshowcategorylist] = useState()
  let inntcategory = secureLocalStorage.getItem("inntcategory")
  let language = secureLocalStorage.getItem("language")
  let [t, i18n] = useTranslation("global")

  useEffect(() => {
    try {
      let data = {
        "maincategoryId": inntcategory
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
        // console.log(res.data.data)
        setshowcategorylist(res?.data?.data)
      }).catch((error) => {

      })
    } catch (error) {
    }
  }, [])

  // get seller list 
  const fetchProducts = async () => {
    let data = {
      "maincategoryId": inntcategory
    }
    const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data);
    return response.data;
  };

  const { data: products, error, isLoading } = useQuery({
    queryKey: ['InntCategory_list'],
    queryFn: fetchProducts,
    onError: (error) => {
      console.error("Error fetching products:", error);
    }
  });

  // categories_products
  let Productslist = (allproductsid) => {
    secureLocalStorage.setItem("categories_productsid", allproductsid)
    navigate(`/categories_products`)
  }

  return (
    <div className='mt-3'>
      <section className="pb-4 rtl">
        <div className="container">
          <div>
            <div className="card __shadow h-100 max-md-shadow-0" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
              <div className="card-body">
                <div className="feature-product-title mt-0 ml-1 mb-3" style={{ color: 'black', textAlign: "start" }}>
                  {t("navbar.shopbyCategories")}
                </div>

                <div className="row ">
                  {products?.data?.length > 0 ? (
                    <>
                      {products?.data?.map((items) => {
                        return (
                          <div className='col-lg-2 col-md-6 col-6 mb-2' onClick={() => Productslist(items._id)}>
                            <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                              <div className="overflow-hidden position-relative">
                                <p className="text-center small mt-2">{language == "fa" ? (items.category_frenchName) : (items.category_englishName)}</p>
                                <div className="text-center __m-5px __cate-item">
                                  <a>
                                    <div className="__img">
                                      <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.category_image}`} />
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <Category_Sceleten />
                  )}



                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Categories
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import Slider from 'react-slick';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from 'primereact/skeleton';

const Bannerslider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 700, // Increase the speed for smoother transitions
    arrows: false, // Hide the previous and next arrows skyer light 
    autoplay: true, // Enable autoplay
    slidesToShow: 1, // Initially, show 4 slides
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Adjust the settings for screens with width less than 1024px
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, // Adjust the settings for screens with width less than 768px
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, // Adjust the settings for screens with width less than 480px
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const fetchProducts = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/banner_list`);
    return response.data;
  };
  const { data: products, error, isLoading } = useQuery({
    queryKey: ['products'],
    queryFn: fetchProducts,
  });


  return (
    <>
      {products?.data?.length > 0 ? (
        <div className="container">
          <div className='container' style={{ backgroundColor: "#c4c4c4" }}>
            <div className='row justify-content-center' >
              <div className='col-lg-10'>
                <Slider {...settings}>
                  {products?.data?.map((item, index) => {
                    const isImage = item?.banner_image.endsWith(".png") || item?.banner_image.endsWith(".jpg");
                    const isVideo = item?.banner_image.endsWith(".mp4");
                    return (
                      <div key={index}>
                        {isImage && <img className="w-100 __slide-img img-fluid" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${item?.banner_image}`} alt={`Slide ${index}`} />}
                        {isVideo && (
                          <>
                            <video style={{ objectFit: "cover" }} className="w-100 __slide-img img-fluid" autoPlay
                              muted
                              loop>
                              <source className="w-100 __slide-img img-fluid" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${item?.banner_image}`} type="video/mp4" />
                            </video>
                            <style>
                              {`
          video::-internal-media-controls-overlay-cast-button {
            display: none;
          }
          video::-webkit-media-controls-enclosure {
            display: none;
          }
          video::-webkit-media-controls-panel {
            display: none;
          }
        `}
                            </style>
                          </>
                        )}
                      </div>
                    );
                  })}
                </Slider>

              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton width="100%" height="25rem"></Skeleton>
      )}

    </>
  )
}

export default Bannerslider
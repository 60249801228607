import React, { useEffect, useState } from 'react'
import Header from './Header'
import Slider from 'react-slick';
import Featured_products from './Tranding_products';
import Newarrivals from './Newarrivals';
import Brands from './Brands';
import LatestProducts from './LatestProducts';
import TopSellers from './TopSellers';
import Trending from './Trending';
import Auth_header from '../Auth_pages/Auth_header';
import Footer from './Footer';
import Tranding_products from './Tranding_products';
import Bannerslider from '../Auth_pages/Bannerslider';
import { Link } from 'react-router-dom';
import Categories from '../Auth_pages/Categories';
import Dealofdays from './Dealofdays';
import Todaysdeals from './Todaysdeals';
import Customerfevorites from './Customerfevorites';
import Suggestedproducts from './Suggestedproducts';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Footerbanner from './Footerbanner';
import Innt_Inntout_page from './Innt_Inntout_page';
import Footerbanner_inntout from '../Inntout/Footerbanner_inntout';
import { locationdata, setcard_list, setwish_list, user_profile } from '../../readux/arrSlice';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
  const dispatch = useDispatch();
  let token = secureLocalStorage.getItem("token")
  let [fevoraiteProduct, getfevoraiteProduct] = useState([])
  let [SuggestedProduct, setSuggestedProduct] = useState([])
  let userid = secureLocalStorage.getItem("useid")

  // get categoryid
  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_SECRET_KEY}/InntoutCategory_list`).then((res) => {
        secureLocalStorage.setItem("inntcategory", res?.data?.data[0]?._id)
        secureLocalStorage.setItem("inntoutcategory", res?.data?.data[1]?._id)
      }).catch((errror) => {
      })
    } catch (error) {

    }
  }, [])


  // get customer fevoraites list 
  useEffect(() => {
    getnewArrival_Product_list()
    suggested_ProductList()
  }, [0])
  let getnewArrival_Product_list = () => {
    try {
      let data = {
        "userId": userid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, data).then((res) => {
        if (res.status == 200) {
          getfevoraiteProduct(res.data.data)
        }
      }).catch((error) => {
      })
    } catch (error) {
    }
  }

  // suggeste products list
  let suggested_ProductList = () => {
    try {
      let data = {
        "userId": userid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/suggested_ProductList`, data).then((res) => {
        if (res.status == 200) {
          setSuggestedProduct(res.data.data)
        }
      }).catch((error) => {
      })
    } catch (error) {
    }
  }

  // products like dislike 
  useEffect(() => {
    let datawishlist = {
      "userId": userid
    }
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, datawishlist).then((res) => {
      if (res.status == 200) {
        dispatch(setwish_list(res.data.data));
      }
    }).catch((error) => {
      dispatch(setwish_list([]));
    })


    let cartlistdata = {
      "userId": userid,
      "status": "0"
    }
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/cartList`, cartlistdata).then((res) => {
      if (res.status === 200) {
        dispatch(setcard_list(res.data.data[0]?.products));
      }
    }).catch((error) => {
      dispatch(setcard_list([]));
    })
  }, [])

  // get user profile
  useEffect(() => {
    getprofileuser()
  }, [])
  const getprofileuser = () => {
    try {
      let dataAbout = {
        userId: userid
      }
      const options = {
        headers: {
          token: token
        },
      };
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/getUser_profile`, dataAbout, options)
        .then((res) => {
          if (res.data.result) {
            dispatch(user_profile(res?.data?.data?.[0]));
          }
        })
        .catch((error) => {
        });
    } catch (error) {
    }
  };

  // // get locatiom longitude and lattitude 
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         let data = {
  //           "lat": position.coords.latitude,
  //           "lon": position.coords.longitude
  //         }
  //         console.log(data)

  //         dispatch(locationdata(data))
  //       },
  //       (error) => {
  //       }
  //     );
  //   }
  // }, [])



  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to update screen dimensions
  const updateScreenDimensions = () => {
    setScreenDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Update screen dimensions on resize
  useEffect(() => {
    window.addEventListener('resize', updateScreenDimensions);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateScreenDimensions);
    };
  }, []); // No dependency array means this runs once on mount and sets the event listener
  //   console.log("screenDimensions.width", screenDimensions.width <=500 ? "true":"false");


  return (
    <>
      <div className="__inline-61">
        {screenDimensions.width < 767 ? (
          <Innt_Inntout_page />
        ) : ("")}
        <Bannerslider />
        <Todaysdeals />
        <Newarrivals />
        {/* {userid != "" ? (
          fevoraiteProduct?.length > 0 ? ( */}
        <Customerfevorites />
        {/* ) : (
            ""
          )
        ) : ("")} */}
        <Footerbanner />
        <Tranding_products />
        {/* {userid != "" ? (
          fevoraiteProduct?.length > 0 ? (
            <Suggestedproducts />
          ) : (
            ""
          )
        ) : ("")} */}
        {/* <Dealofdays /> */}
        <TopSellers />
        <Categories />
      </div>
      <Footer />
    </>
  )
}

export default Home
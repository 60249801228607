import Footer from '../Home_page/Footer'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import { IoLocationOutline } from 'react-icons/io5';
import { Popover, Typography } from '@mui/material'
import { BsThreeDots } from 'react-icons/bs';
import { CiChat1 } from 'react-icons/ci';
import { IoIosStar, IoMdShareAlt } from 'react-icons/io';
import { FaRegStar } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

const Topsaller_details_inntout = () => {
  let navigate = useNavigate()
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  let [showallshops, setshowallshops] = useState()
  let [loaderstatus, setloaderstatus] = useState(true)
  let userid = secureLocalStorage.getItem("useid")
  let [stats, setstats] = useState(true)


  // social media toggel
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'popover-button' : undefined;
  const [age, setAge] = useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };


  let locationdata = JSON.parse(secureLocalStorage.getItem("latlon"))
  let getSellerList = () => {
    try {
      let data = {
        "lat": locationdata?.lat,
        "lon": locationdata?.lon
      }
      // console.log(data)
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutShop_list`, data)
        .then((res) => {
          // console.log(res)
          setshowallshops(res?.data?.data)
          if (res.data.result) {
            // Do something with the result
          }
        })
        .catch((error) => {
          // console.log("Error:", error)
        });
    } catch (error) {
      // Handle error
    }
  }

  let [showshoplist, setshowshoplist] = useState([])

  // get seller list 
  const fetchProducts = async () => {
    let data = {
      "lat": locationdata?.lat,
      "lon": locationdata?.lon
    }
    const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutShop_list`, data);
    return response.data;
  };

  const { data: products, error, isLoading } = useQuery({
    queryKey: ['inntOutShop_list'],
    queryFn: fetchProducts,
    onError: (error) => {
      console.error("Error fetching products:", error);
    }
  });

  // useEffect(() => {
  //   // setshowshoplist(products?.data)
  //   console.log("showshoplist", showshoplist)
  // }, [showshoplist])


  useEffect(() => {
    if (coordinates.latitude !== null && coordinates.longitude !== null) {
      getSellerList();
    }
  }, [coordinates]);

  // shopdetails handel
  let shopsdetails = (objitems) => {
    // console.log(objitems)
    secureLocalStorage.setItem("shopid", objitems?.shopId)
    navigate("/shopview_inntout")
  }

  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(1);
  const [priceGap] = useState(1);

  let [filtervalueobj, setfiltervalueobj] = useState(
    {
      "userId": userid,
      "currentlat": locationdata?.lat.toString(),
      "currentlon": locationdata?.lon.toString(),
      "rating": "",
      "distances1": "",
      "distances2": "",
    }
  )

  // filter value handel
  let filtervalue = (event) => {
    setfiltervalueobj({ ...filtervalueobj, [event.target.name]: event.target.value })
  }

  // price range handel
  const handlePriceInputChange = (e) => {
    const inputName = e.target.name;
    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 10000) {
      newValue = 10000;
    } else if (inputName === 'distances1' && newValue > maxPrice - priceGap) {
      newValue = maxPrice - priceGap;
    } else if (inputName === 'distances2' && newValue < minPrice + priceGap) {
      newValue = minPrice + priceGap;
    }

    if (inputName === 'distances1') {
      setMinPrice(newValue);
      filtervalueobj.distances1 = newValue.toString()
    } else {
      setMaxPrice(newValue);
      filtervalueobj.distances2 = newValue.toString()
    }
  };

  // filter products 
  let filterproducts = (event) => {
    event.preventDefault()
    setloaderstatus(false)
    setstats(false)
    // let data =    {
    //   "userId":filtervalueobj?.userId?.toString(),
    //   "currentlat":filtervalueobj?.currentlat?.toString(),
    //   "currentlon":filtervalueobj?.currentlon?.toString(),
    //   "rating":filtervalueobj?.rating?.toString(),
    //   "distances1":filtervalueobj?.distances1?.toString(),
    //   "distances2":filtervalueobj?.distances2?.toString(),
    // }
    console.log("hello data", filtervalueobj)
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/filterInnoutShop`, filtervalueobj).then((res) => {
      console.log(res?.data)
      if (res?.data?.result == 'true') {
        setloaderstatus(true)
        setshowshoplist(res?.data?.data)
      }
    }).catch((error) => {
      console.log(error)
      setstats(true)
    })
  }

  return (
    <div>
      {products?.data?.length > 0 ? (
        <div className="container mb-md-4  __inline-65 mt-3">
          <div className="__inline-62 p-4">
            <div className="feature-product-title mt-0 ml-1 mb-3" style={{ color: "black", fontWeight: "700", textAlign: "start" }}>
              All Shops
            </div>
            <div className='row mt-3'>
              <div className='col-lg-2 text-center'>
                <div className="sorting-item">
                  <IoIosStar className="tio-star text-warning" />
                  <label className="for-shoting" htmlFor="sorting">
                    <span className="text-nowrap">Reaview</span>
                  </label>
                  <select onChange={filtervalue} name='rating'>
                    <option value="latest" hidden>Select</option>
                    <option value="1">1<IoIosStar className="tio-star text-warning" /></option>
                    <option value="2">2<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                    <option value="3">3<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                    <option value="4">4<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                    <option value="5">5<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                  </select>
                </div>
              </div>
              <div className='col-lg-2 text-center'>
                <small><b>Min:</b> {minPrice}</small> <br />
                <input
                  type="range"
                  className="min-range"
                  min="0"
                  max="10000"
                  step="1"
                  onChange={handlePriceInputChange}
                  name="distances1"
                />
              </div>
              <div className='col-lg-2 text-center'>
                <small><b>Max:</b> {maxPrice}</small><br />
                <input
                  type="range"
                  className="max-range"
                  min="0"
                  max="10000"
                  step="1"
                  onChange={handlePriceInputChange}
                  name="distances2"
                />
              </div>
              <div className='col-lg-2 text-center'>
                <form >

                  <div className="text-start">
                    <button onClick={filterproducts} className="btn--primary btn">
                      Search <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </div>
                </form>

              </div>
              <div>

              </div>
            </div>
          </div>

          <div className="row">
            <section className="col-lg-12">
              {stats == true ? (
                <div className="row mx-n2 __min-h-200px">
                  {products?.data?.map((items, index) => {
                    return (
                      <div key={index} className="col-lg-3 col-md-6 col-sm-12 px-2 pb-4 text-center">
                        <div className="others-store-card p-1">
                          <div style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                            <div className="overflow-hidden other-store-banner">
                              <img style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.frontImage}`} className="w-100 h-100 object-cover" alt />
                            </div>
                            <div className="name-area">
                              <div className="position-relative">
                                <div className="overflow-hidden other-store-logo rounded-full">
                                  {/* <img style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} className="rounded-full" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`} /> */}
                                  {
                                    items?.logo === " " || items?.logo === null || items?.logo === undefined ? (
                                      <img
                                        src="./imglist/user.png"
                                        className="avatar rounded-circle"
                                        alt=""
                                        width={40}
                                      />
                                    ) : items?.logo?.startsWith("https://") ? (
                                      <img
                                        onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`}
                                        className="avatar rounded-circle"
                                        alt=""
                                        width={40}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`}
                                        className="avatar rounded-circle"
                                        alt=""
                                        width={40}
                                      />
                                    )
                                  }
                                </div>
                              </div>
                              <div className="info pt-2 ">
                                <div style={{ textAlign: "start" }}>
                                  <h5 style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} className='mt-2'>{items?.shop_name}</h5>
                                  <small className=''><IoLocationOutline /> {items?.shop_address.slice(0, 20)}{items?.shop_address?.length >= 20 ? "..." : ""}</small> <br />
                                  <small className=''>{items.distances}KM</small>
                                </div>
                              </div>
                            </div>
                            <div className="info-area">
                              <div className="info-item">
                                <h6 style={{ color: '#1455ac' }}>{items.raters}</h6>
                                <span>Raters</span>
                              </div>
                              <div className="info-item">
                                <h6 style={{ color: '#1455ac' }}>{items.sales}</h6>
                                <span>Sales</span>
                              </div>

                              {/* <>
                              <BsThreeDots onClick={handleClick} aria-describedby={id} className='mr-2' style={{ fontSize: "25px", cursor: "pointer" }} />
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                PaperProps={{ style: { boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)' } }}
                              >

                                <Typography sx={{ p: 2 }}>
                                  <div>
                                    <p onClick={() => chathandel(items)} style={{ cursor: "pointer" }}><CiChat1 className='mr-2' />Chat</p>
                                    <p style={{ cursor: "pointer" }}><IoMdShareAlt className='mr-2' />Share</p>
                                    <p style={{ cursor: "pointer" }}><FaRegStar className='mr-2' />Add Fevorite</p>
                                    <p style={{ cursor: "pointer" }}><MdReportProblem className='mr-2' />Report</p>
                                  </div>
                                </Typography>
                              </Popover>
                            </> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <>
                  {loaderstatus == true ? (
                    <div className="row mx-n2 __min-h-200px">
                      {showshoplist?.map((items, index) => {
                        return (
                          <div key={index} className="col-lg-3 col-md-6 col-sm-12 px-2 pb-4 text-center">
                            <div className="others-store-card p-1">
                              <div style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                <div className="overflow-hidden other-store-banner">
                                  <img style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.frontImage}`} className="w-100 h-100 object-cover" alt />
                                </div>
                                <div className="name-area">
                                  <div className="position-relative">
                                    <div className="overflow-hidden other-store-logo rounded-full">
                                      {/* <img style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} className="rounded-full" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`} /> */}
                                      {
                                        items?.logo === " " || items?.logo === null || items?.logo === undefined ? (
                                          <img
                                            src="./imglist/user.png"
                                            className="avatar rounded-circle"
                                            alt=""
                                            width={40}
                                          />
                                        ) : items?.logo?.startsWith("https://") ? (
                                          <img
                                            onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`}
                                            className="avatar rounded-circle"
                                            alt=""
                                            width={40}
                                          />
                                        ) : (
                                          <img
                                            onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`}
                                            className="avatar rounded-circle"
                                            alt=""
                                            width={40}
                                          />
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="info pt-2 ">
                                    <div style={{ textAlign: "start" }}>
                                      <h5 style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} className='mt-2'>{items?.shop_name}</h5>
                                      <small className=''><IoLocationOutline /> {items?.shop_address.slice(0, 20)}{items?.shop_address?.length >= 20 ? "..." : ""}</small> <br />
                                      <small className=''>{items.distances}KM</small>
                                    </div>
                                  </div>
                                </div>
                                <div className="info-area">
                                  <div className="info-item">
                                    <h6 style={{ color: '#1455ac' }}>{items.raters}</h6>
                                    <span>Raters</span>
                                  </div>
                                  <div className="info-item">
                                    <h6 style={{ color: '#1455ac' }}>{items.sales}</h6>
                                    <span>Sales</span>
                                  </div>

                                  {/* <>
                              <BsThreeDots onClick={handleClick} aria-describedby={id} className='mr-2' style={{ fontSize: "25px", cursor: "pointer" }} />
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                PaperProps={{ style: { boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)' } }}
                              >

                                <Typography sx={{ p: 2 }}>
                                  <div>
                                    <p onClick={() => chathandel(items)} style={{ cursor: "pointer" }}><CiChat1 className='mr-2' />Chat</p>
                                    <p style={{ cursor: "pointer" }}><IoMdShareAlt className='mr-2' />Share</p>
                                    <p style={{ cursor: "pointer" }}><FaRegStar className='mr-2' />Add Fevorite</p>
                                    <p style={{ cursor: "pointer" }}><MdReportProblem className='mr-2' />Report</p>
                                  </div>
                                </Typography>
                              </Popover>
                            </> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='row justify-content-center m-5'>
                      <div className="spinner-border text-primary"></div>
                    </div>
                  )}
                </>

              )}
            </section>
          </div>
        </div>
      ) : (
        <div className='row justify-content-center m-5'>
          <div className="spinner-border text-primary"></div>
        </div>
      )}

      <Footer />
    </div>
  )
}

export default Topsaller_details_inntout
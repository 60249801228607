import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { address_list } from '../../readux/arrSlice';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const Innt_Inntout_page = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch();
    let token = secureLocalStorage.getItem("token");
    let userid = secureLocalStorage.getItem("useid");
    const [location, setLocation] = useState(null);
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const profiledata = useSelector(state => state.arr);




    // get address 
    useEffect(() => {
        getaddresshandel()
    }, [])
    const address = useSelector(state => state.arr);
    // console.log("address", address?.addresdata[0]) //
    let getaddresshandel = () => {
        try {
            const options = {
                headers: {
                    token: token
                },
            };
            let data = {
                "userId": userid
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/getAddress`, data, options)
                .then((res) => {
                    if (res.data.result) {
                        dispatch(address_list(res?.data?.data));
                    }
                })
                .catch((error) => {
                    console.log("erer", error)
                });
        } catch (error) {
        }
    }

    // get current location 
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    // console.error('Error getting location:', error);
                }
            );
        } else {
            // console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        if (location) {
            const { latitude, longitude } = location;
            const API_KEY = 'AIzaSyDg64HvMk1LYbkHcnBMzndPu4EuHRjefAQ';

            fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'OK') {
                        const components = data.results[0].address_components;
                        let city = '';
                        let pincode = '';

                        for (let component of components) {
                            const types = component.types;
                            if (types.includes('locality')) {
                                city = component.long_name;
                            } else if (types.includes('postal_code')) {
                                pincode = component.long_name;
                            }
                        }

                        setCity(city);
                        setPincode(pincode);
                    } else {
                        // console.error('Error fetching address:', data.status);
                    }
                })
                .catch((error) => {
                    // console.error('Error fetching address:', error);
                });
        }
    }, [location]);

    // goto  innt out 
    let inntout = () => {
        navigate('/inntoutHome')
    }
    return (
        <>


            <div className='mt-3'>
                <small className='text-dark pl-2 pt-4 pb-4'>Deliver to :
                    {userid?.length > 0 ? (
                        address?.addresdata?.length > 0 ? (
                            <>{profiledata?.userprofiledata?.first_name}&nbsp;{address?.addresdata[0]?.city_name}&nbsp;{address?.addresdata[0]?.pin_code}</>
                        ) : (
                            <>{profiledata?.userprofiledata?.first_name}&nbsp;{city}&nbsp;{pincode}</>
                        )
                    ) : ( " burkina faso")}
                </small>
            </div>

            <div className='mt-1' onClick={inntout} style={{ backgroundColor: "gray", cursor: "pointer" }}>
                <h6 className='p-2'>Innt Out</h6>
            </div>
        </>

    )
}

export default Innt_Inntout_page
import React, { useEffect, useState } from 'react'
import Footer from '../Home_page/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { TfiLayoutMenuSeparated } from "react-icons/tfi";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FaHeart } from "react-icons/fa";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import toast, { Toaster } from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { setcard_list, setwish_list } from '../../readux/arrSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';


const Search_Products = () => {
    const dispatch = useDispatch();
    let userid = secureLocalStorage.getItem("useid")
    let inntcategory = secureLocalStorage.getItem("inntcategory")
    let categories_productsid = secureLocalStorage.getItem("categories_productsid")
    let subcate_productsid = secureLocalStorage.getItem("subcate_productsid")
    let [showcategory, setshowcategory] = useState([])
    let [showSubcategory, setshowSubcategory] = useState([])
    let [showSub_subcategory, setshowSub_subcategory] = useState([])
    let [show_allproducts_category, setshow_allproducts_category] = useState([])
    let [showproductsdetails, setshowproductsdetails] = useState([])
    const [visibleBottom, setVisibleBottom] = useState(false);
    let [attributes, setattributes] = useState([])

    let [pricelowhigh, setpricelowhigh] = useState([])
    let [mainproductsimg, setmainproductsimg] = useState("")
    let [count, setcount] = useState(1)
    let [gethighprice, setgethighprice] = useState()
    let [getlowprice, setgetlowprice] = useState()
    let [loderstatus, setloderstatus] = useState(true)
    let navigate = useNavigate()

    // social media toggel
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'popover-button' : undefined;
    const [age, setAge] = useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };


    // main category list
    useEffect(() => {
        try {
            let data = {
                "maincategoryId": inntcategory
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
                // console.log(res.data.data)
                setshowcategory(res?.data?.data)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }, [0])

    // sub category list
    let getSubcategory = (subid) => {
        try {
            let data = {
                "categoryId": subid
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/subCategory_list`, data).then((res) => {
                // console.log(res.data)
                setshowSubcategory(res?.data?.data)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }

    //sub  sub category list 
    let getSub_sub_category = (subsubcateid) => {
        try {
            let data = {
                "subcategoryId": subsubcateid
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/subSubcategory_list`, data).then((res) => {
                // console.log(res.data.data)
                setshowSub_subcategory(res?.data?.data)
                // setshowSubcategory(res?.data?.data)
            }).catch((error) => {

            })
        } catch (error) {

        }

    }

    // get all category wise products
    let getallproducts_category = (allcate_productid) => {
        secureLocalStorage.setItem("categories_productsid", "")
        secureLocalStorage.setItem("subcate_productsid", allcate_productid)
        try {
            let data = {
                "sub_subcategoryId": allcate_productid
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/sub_subcategoryBase_productList`, data).then((res) => {
                // console.log(res.data.data)
                setshow_allproducts_category(res?.data?.data)
                setpricelowhigh(res?.data?.data)
                setVisibleBottom(false)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }

    // get products with id
    useEffect(() => {
        getcategoriesproduts()
    }, [0])
    let getcategoriesproduts = () => {
        if (categories_productsid !== null) {
            try {
                let data = {
                    "categoryId": categories_productsid
                }
                axios.post(`${process.env.REACT_APP_SECRET_KEY}/categoryBase_productList`, data).then((res) => {
                    // console.log("res", res)
                    setshow_allproducts_category(res?.data?.data)
                    setpricelowhigh(res?.data?.data)
                })
            } catch (error) {
                // console.log("err", error)
            }

        } else {
            try {
                let data = {
                    "sub_subcategoryId": subcate_productsid
                }
                axios.post(`${process.env.REACT_APP_SECRET_KEY}/sub_subcategoryBase_productList`, data).then((res) => {
                    // console.log("readata", res?.data?.data)
                    if (res?.data?.data?.length > 0) {
                        setshow_allproducts_category(res?.data?.data)
                        setpricelowhigh(res?.data?.data)
                        setloderstatus(false)
                    } else {
                        setloderstatus(false)
                    }


                }).catch((error) => {
                }).catch((error) => {

                })
            } catch (error) {
            }
        }

    }

    // get products details
    const locationdata = useSelector(state => state.arr);
    let getproductsdetails = (items) => {
        // console.log("hello", hello)
        try {
            let data = {
                "userId": userid,
                "productId": items?.productId,
                "shopId": items?.venderId,
                "lat": locationdata.locationlist.lat,
                "lon": locationdata.locationlist.lon
            }
            // console.log(data)
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, data).then((res) => {
                setshowproductsdetails(res?.data?.data)
                setmainproductsimg(res?.data?.data[0]?.images?.[0])
                setattributes(res?.data?.data?.[0]?.attributes)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }

    // attribute handel
    const [extractedValues, setExtractedValues] = useState([]);
    const getattribute = (newvalue, index, items) => {
        console.log("items", items)
        const updatedValues = [...extractedValues];
        updatedValues[index].value = newvalue;
        // console.log(updatedValues[index].value)
        console.log("updatedValues", updatedValues)
        setExtractedValues(updatedValues);
    }
    useEffect(() => {
        const values = attributes.map(item => ({
            name: item.attribute_name,
            value: item.attribute_values[0]
        }));
        // console.log("extractedValues", extractedValues)
        setExtractedValues(values);
    }, [attributes]);

    // Singleproducts 
    let Singleproducts = (items) => {
        secureLocalStorage.setItem("venderId", items?.venderId)
        secureLocalStorage.setItem("productId", items?.productId)
        try {
            let data = {
                "userId": userid,
                "productId": items?.productId,
            }
            // console.log(data)
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/addViewproduct`, data).then((res) => {
                // console.log(res)
            }).catch((error) => {

            })
        } catch (error) {
        }


        navigate(`/singleprojects/${items?.productId}/${items?.venderId}`)
    }

    // like dishlike handel
    let livedisclikehandel = (items) => {
        try {
            let data = {
                "userId": userid,
                "productId": items.productId,
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/like_dislike_product_api`, data).then((res) => {
                // console.log(res.data.result)
                if (res.data.result) {
                    toast.success(`${res.data.message}`)
                    let datawishlist = {
                        "userId": userid
                    }
                    axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, datawishlist).then((res) => {
                        if (res.status == 200) {
                            dispatch(setwish_list(res.data.data));
                        }
                    }).catch((error) => {
                        dispatch(setwish_list([]));
                    })
                    try {
                        let datashow = {
                            "userId": userid,
                            "productId": items?.productId,
                            "shopId": items?.venderId
                        }
                        console.log(data)
                        axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, datashow).then((res) => {
                            setshowproductsdetails(res?.data?.data)
                            setmainproductsimg(res?.data?.data[0]?.images?.[0])


                        }).catch((error) => {
                        })
                    } catch (error) {
                    }
                }
            }).catch((error) => {
            })
        } catch (error) {
        }
    }


    let [storecolorvalue, setcolorhandel] = useState("")
    let [storsizevalue, setstorsizevalue] = useState("")
    let [storquntityvalue, setstorquntityvalue] = useState("")

    // add to card addtocardhandele
    let addtocardhandele = (productId) => {
        try {
            let data = {
                "userId": userid,
                "productId": productId,
                "size": storsizevalue !== "" ? (storsizevalue) : (showproductsdetails?.[0]?.size[0]),
                "color": storecolorvalue !== "" ? (storecolorvalue) : (showproductsdetails?.[0]?.color[0]),
                "qty": storquntityvalue !== "" ? (storquntityvalue) : ("1"),
                "status": "0",
            }
            // console.log(data)
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/Addcart`, data).then((res) => {
                // console.log(res.data.result)
                if (res.data.result) {
                    toast.success(`${res.data.message}`)
                    let cartlistdata = {
                        "userId": userid,
                        "status": "0"
                    }
                    axios.post(`${process.env.REACT_APP_SECRET_KEY}/cartList`, cartlistdata).then((res) => {
                        if (res.status === 200) {
                            dispatch(setcard_list(res.data.data[0]?.products));
                        }
                    }).catch((error) => {
                        dispatch(setcard_list([]));
                    })

                }
            }).catch((error) => {
                // console.log(error)
                if (error.response && error.response.status === 400) {
                    toast.error(`${error.response.data.message}`)

                }
            })
        } catch (error) {
        }
    }


    // price voice prroducts listing 
    let lowtohigh = (e) => {
        handleOpen()
        if (e.target.value === "low-high") {
            let newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => a.sale_price - b.sale_price);
            setshow_allproducts_category(newallshow_allproducts_category);
        } else if (e.target.value === "high-low") {
            let newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => b.sale_price - a.sale_price);
            setshow_allproducts_category(newallshow_allproducts_category);
        } else if (e.target.value === "a-z") {
            const newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => {
                return a.product_name.localeCompare(b.product_name);
            });
            setshow_allproducts_category(newallshow_allproducts_category);
        } else if (e.target.value === "z-a") {
            const newallshow_allproducts_category = [...show_allproducts_category].sort((a, b) => {
                return b.product_name.localeCompare(a.product_name);
            });
            setshow_allproducts_category(newallshow_allproducts_category);

        }
        setVisibleBottom(false)
    }

    // price getlowhighhandel 
    let getlowhighhandel = (e) => {
        e.preventDefault()
        handleOpen()
        let newallshow_allproducts_category = pricelowhigh.filter((item) => item.sale_price >= getlowprice && item.sale_price <= gethighprice)
        setshow_allproducts_category(newallshow_allproducts_category);
        // console.log(newallshow_allproducts_category)
    }

    // window loader 
    const [open1, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
        autoclose()
    };
    let autoclose = () => {
        setTimeout(() => {
            setOpen(false)
        }, 500);
    }

    // login handel
    let UsersingnHandel = () => {
        swal({
            title: "Please Sign in ?",
            text: "You need to Sign in to view this feature",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteaddress();
            } else {
            }
        });
        let deleteaddress = () => {
            navigate("/login")
        }
    }



    // go to cart
    let gotocarthandel = () => {
        setTimeout(() => {
            navigate("/addtocard")
        }, 500);
    }

    let getdata = secureLocalStorage.getItem("searchvalue")
    useEffect(() => {
        try {
            let data = {
                "key": getdata
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/searchProduct`, data).then((res) => {
                if (res.status === 200) {
                    setshow_allproducts_category(res?.data?.data)
                }
            }).catch((error) => {
                if (error?.response?.status == 400) {
                    console.log("yus")
                    setshow_allproducts_category([])
                    setTimeout(() => {
                        setloderstatus(false)
                    }, 2000);
                }
            })
        } catch (error) {
        }
    }, [getdata])

    return (
        <>
            {show_allproducts_category?.length > 0 ? (
                <div className="container py-4 rtl px-0 px-md-3">
                    <div className="__inline-62 p-4">

                        <div className="feature-product-title text-bold mt-0 ml-1 mb-4" style={{ color: 'black', textAlign: "start" }}>
                            Sesrch Products list
                        </div>

                        <div className="">
                            <div className="">
                                <div className="row" id="">
                                    {show_allproducts_category?.map((items, index) => {
                                        return (
                                            <div key={index} className=" col-lg-2 col-md-6 col-sm-4 col-6  p-2 " onClick={() => Singleproducts(items)}>
                                                {items?.discount === 0 ? ("") : (
                                                    <span className="for-discoutn-value p-1 pl-2 pr-2">
                                                        -{items?.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                    </span>
                                                )}
                                                <div className="product-single-hover style--card deal_of_the_day1 ">
                                                    <div className="overflow-hidden position-relative">
                                                        <div className=" inline_product clickable d-flex justify-content-center">
                                                            {/* onClick={() => getproductsdetails(items)} data-toggle="modal" data-target="#viewallmyModal" */}
                                                            <div className="pb-0 text-center">
                                                                <a className="w-90 text-center" style={{ paddingTop: "40px" }}>
                                                                    <img width={"80%"} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="single-product-details">
                                                            <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                {items.product_name?.slice(0, 20)}{items.product_name?.length > 23 ? "..." : ""}
                                                            </div>

                                                            <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                <strike className="pl-1 pr-2" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                    CFA{items.unit_price}
                                                                </strike>
                                                                <span className="text-accent text-dark">
                                                                    CFA{items.sale_price}
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (
                loderstatus == true ? (
                    <div className=' mt-5 mb-5'>
                        <div className='row justify-content-center'>
                            <div className="spinner-border text-primary"></div>
                        </div>
                    </div>
                ) : (
                    <div className='col-12 row  justify-content-center mt-5 mb-5'>
                        <div className='col-lg-4 col-12 text-center'>
                            <div>
                                <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                            </div>
                            <h6 className='mt-2'>Data Not Found</h6>
                        </div>
                    </div>
                )

            )}

            <Footer />
        </>



    )
}

export default Search_Products
import { Skeleton } from '@mui/material'
import React from 'react'

const NewArrivals_skeleten = () => {
  return (
    <div className="row g-4 pt-2 mt-0 pb-2 __deal-of align-items-start" >
      <div className="col-xl-3 col-md-4" style={{ height: "690px" }}>
        <div className="deal_of_the_day h-100 bg--light" style={{ height: "480px" }}>
          <div className="recomanded-product-card mt-0">
            <div className="d-flex justify-content-center align-items-center __pt-20 __m-20-r">
              <Skeleton width="12rem" height="30rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-9 col-md-8">
        <div className="latest-product-margin">
          <div className="row mt-0 g-2">
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-6 p-2" >
              <div className="product-single-hover style--card deal_of_the_day1 text-center">
                <Skeleton width="100%" height="20rem"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NewArrivals_skeleten
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from 'react-redux';
import { BiSolidCategory } from 'react-icons/bi';
import { MdDiscount } from 'react-icons/md';
import { useQuery } from '@tanstack/react-query';
import { IoIosStar } from 'react-icons/io';
import Footer from './Footer';
const Customer_favouriteProducts_filter = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate()
    let [loderstatus, setloderstatus] = useState(true)
    let userid = secureLocalStorage.getItem("useid")
    let [todaydeals_Product_list, settodaydeals_Product_list] = useState([])

    // get inntoutToday_offerProduct_list list 
    useEffect(() => {
        getnewArrival_Product_list()
    }, [0])
    let getnewArrival_Product_list = () => {
        try {
            axios.get(`${process.env.REACT_APP_SECRET_KEY}/customer_favourite_list`).then((res) => {
                // console.log(res.data)
                if (res.status == 200) {
                    settodaydeals_Product_list(res.data.data)
                } else if (res.data.data?.length == 0) {
                    setloderstatus(false)
                }
            }).catch((error) => {
                // console.log(error)
                setloderstatus(false)
            })
        } catch (error) {
        }
    }


    const fetchProducts_Today_offerlist = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/customer_favourite_list`);
        if (response.status == 200) {
            let newid = response?.data?.data?.map(item => item.productId);
            filtervalueobj.productIds = newid
            return response.data;
        }
        else if (response.data.data?.length == 0) {
            setloderstatus(false)
        }
    };

    const { data: productslist, errorlist, isLoadinglist } = useQuery({
        queryKey: ['customer_favourite_list'],
        queryFn: fetchProducts_Today_offerlist,
        onError: (error) => {

        }
    });





    // Singleproducts 
    let location_path = useLocation();
    let Singleproducts = (items) => {
        secureLocalStorage.setItem("venderId", items?.venderId)
        secureLocalStorage.setItem("productId", items?.productId)
        navigate(`/singleprojects_inntout/${items?.productId}/${items?.venderId}`)
        secureLocalStorage.setItem("pagepath", location_path?.pathname)
    }

    let [storsizevalue, setstorsizevalue] = useState("")
    let [storquntityvalue, setstorquntityvalue] = useState("")


    let inntoutcategory = secureLocalStorage.getItem("inntoutcategory")
    const fetchProducts = async () => {
        let data = {
            "maincategoryId": inntoutcategory
        }
        const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data);
        return response.data;
    };

    const { data: products, error, isLoading } = useQuery({
        queryKey: ['InntCategory_list'],
        queryFn: fetchProducts,
    });

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);
    const [priceGap] = useState(500);
    let [filterloader, setfilterloader] = useState(true)
    let [changeproducts, setchangeproducts] = useState(true)
    let [filter_products, setfilter_products] = useState([])
    let [filtervalueobj, setfiltervalueobj] = useState(

        // {
        //     "categoryId": "668d2d403f56222eaf8c2e0a",
        //     "price1": "570",
        //     "price": "100",
        //     "discount": "0",
        //     "rating": "0",
        //     "status": "2"
        // }

        {
            "productIds": [],
            "categoryId": "",
            "price": "",
            "price1": "" ? 1000 : 1000,
            "discount": "",
            "rating": "",
            "status": "1"
        }
    )


    // price range handel
    const handlePriceInputChange = (e) => {
        const inputName = e.target.name;
        let newValue = parseInt(e.target.value);
        if (newValue < 0) {
            newValue = 0;
        } else if (newValue > 10000) {
            newValue = 10000;
        } else if (inputName === 'minPrice' && newValue > maxPrice - priceGap) {
            newValue = maxPrice - priceGap;
        } else if (inputName === 'maxPrice' && newValue < minPrice + priceGap) {
            newValue = minPrice + priceGap;
        }

        if (inputName === 'minPrice') {
            setMinPrice(newValue);
            filtervalueobj.price = newValue
        } else {
            setMaxPrice(newValue);
            filtervalueobj.price1 = newValue
        }
    };

    // filter value handel
    let filtervalue = (event) => {
        // console.log(event.target.value)
        setfiltervalueobj({ ...filtervalueobj, [event.target.name]: event.target.value })
    }

    // filter products 
    let filterproducts = (event) => {
        event.preventDefault()
        setfilterloader(false)
        console.log("hello dataaa", filtervalueobj)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/customer_favouriteProducts_filter`, filtervalueobj).then((res) => {
            console.log(res?.data)
            if (res?.data.result == 'true') {
                setfilter_products(res?.data?.data)
                setfilterloader(true)
                setchangeproducts(false)
            }
        }).catch((error) => {
            console.log(error)
            setchangeproducts(true)
            setfilterloader(true)
            toast.error(error?.response?.data?.message)
        })
    }

    return (
        <>
            <Toaster />
            <div className="container mb-2">
                <div className='row justify-content-center mt-3'>
                    <div className='col-lg-12'>
                        <div className="__inline-62 p-4 " style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                            <div className="feature-product-title mt-0 ml-1 mb-3" style={{ color: "black", fontWeight: "700", textAlign: "start" }}>
                            Customer Fevorites
                            </div>

                            <div className='row mb-3'>
                                <div className='col-lg-2 text-center'>
                                    <div className="sorting-item">
                                        <BiSolidCategory />

                                        <label className="for-shoting" htmlFor="sorting">
                                            <span className="text-nowrap">Category</span>
                                        </label>
                                        <select onChange={filtervalue} name='categoryId'>
                                            <option hidden>Select</option>
                                            {products?.data?.map((items, index) => {
                                                return (
                                                    <option key={index} value={items._id}>{items.category_englishName}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-2 text-center'>
                                    <div className="sorting-item">
                                        <MdDiscount />
                                        <label className="for-shoting" htmlFor="sorting">
                                            <span className="text-nowrap">Discount</span>
                                        </label>
                                        <select onChange={filtervalue} name='discount'>
                                            <option hidden>Select</option>
                                            <option value="5">5%</option>
                                            <option value="10">10%</option>
                                            <option value="15">15%</option>
                                            <option value="20">20%</option>
                                            <option value="25">25%</option>
                                            <option value="30">30%</option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-2 text-center'>
                                    <div className="sorting-item">
                                        <IoIosStar className="tio-star text-warning" />
                                        <label className="for-shoting" htmlFor="sorting">
                                            <span className="text-nowrap">Reaview</span>
                                        </label>
                                        <select onChange={filtervalue} name='rating'>
                                            <option value="latest" hidden>Select</option>
                                            <option value="1">1<IoIosStar className="tio-star text-warning" /></option>
                                            <option value="2">2<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                                            <option value="3">3<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                                            <option value="4">4<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>
                                            <option value="5">5<IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /><IoIosStar className="tio-star text-warning" /></option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-2 text-center'>
                                    <small><b>Min:</b> {minPrice}</small> <br />
                                    <input
                                        type="range"
                                        className="min-range"
                                        min="0"
                                        max="10000"
                                        value={minPrice}
                                        step="1"
                                        onChange={handlePriceInputChange}
                                        name="minPrice"
                                    />
                                </div>
                                <div className='col-lg-2 text-center'>
                                    <small><b>Max:</b> {maxPrice}</small><br />
                                    <input
                                        type="range"
                                        className="max-range"
                                        min="0"
                                        max="10000"
                                        value={maxPrice}
                                        step="1"
                                        onChange={handlePriceInputChange}
                                        name="maxPrice"
                                    />
                                </div>
                                <div className='col-lg-2 text-center'>
                                    <form >

                                        <div className="text-start">
                                            <button onClick={filterproducts} className="btn--primary btn">
                                                Search <i className="fa fa-search" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </form>

                                </div>
                                <div>

                                </div>
                            </div>

                            {filterloader == true ? (
                                <>
                                    {changeproducts == true ? (
                                        <div className="">
                                            {productslist?.data?.length > 0 ? (
                                                <div className="row">
                                                    {productslist?.data?.map((items, index) => {
                                                        return (
                                                            <div onClick={() => Singleproducts(items)} key={index} className='col-lg-2 col-md-6 col-6 mb-2'>
                                                                {items.discount == 0 ? "" : (
                                                                    <span className="for-discoutn-value p-1">
                                                                        -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                                    </span>
                                                                )}
                                                                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                                    <div className="overflow-hidden position-relative">
                                                                        <div className=" inline_product clickable d-flex justify-content-center">
                                                                            <div className=" pb-0">
                                                                                <Link className="w-100">
                                                                                    <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                                </Link>
                                                                            </div>
                                                                            {/* <div className="quick-view">
                                                                        <span onClick={() => getproductsdetails(items)} className="btn-circle stopPropagation" data-toggle="modal" data-target="#todaydealsmyModal">
                                                                            <i className="czi-eye align-middle" />
                                                                        </span>
                                                                    </div> */}
                                                                        </div>
                                                                        <div className="single-product-details">
                                                                            <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                                {items?.product_name.slice(0, 17)}{items?.product_name?.length > 23 ? "..." : ""}
                                                                            </div>

                                                                            <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                                <span className="text-accent text-dark">
                                                                                    CFA{items?.sale_price}
                                                                                </span>
                                                                                <strike className="pl-1" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                                    CFA{items?.unit_price}
                                                                                </strike><br />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            ) : (
                                                <>
                                                    {loderstatus == true ? (
                                                        <div className='row justify-content-center mt-5 mb-5'>
                                                            <div className="spinner-border text-primary"></div>
                                                        </div>
                                                    ) : (
                                                        <div className='col-12 row  justify-content-center mt-5 mb-5'>
                                                            <div className='col-lg-4 col-12 text-center'>
                                                                <div>
                                                                    <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                                                </div>
                                                                <h6 className='mt-2'>Data Not Found</h6>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {filter_products?.map((items, index) => {
                                                return (
                                                    <div onClick={() => Singleproducts(items)} key={index} className='col-lg-2 col-md-6 col-6'>
                                                        {items.discount == 0 ? "" : (
                                                            <span className="for-discoutn-value p-1">
                                                                -{items.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                                                            </span>
                                                        )}
                                                        <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                            <div className="overflow-hidden position-relative">
                                                                <div className=" inline_product clickable d-flex justify-content-center">
                                                                    <div className=" pb-0">
                                                                        <Link className="w-100">
                                                                            <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                        </Link>
                                                                    </div>
                                                                    {/* <div className="quick-view">
                                                                        <span onClick={() => getproductsdetails(items)} className="btn-circle stopPropagation" data-toggle="modal" data-target="#todaydealsmyModal">
                                                                            <i className="czi-eye align-middle" />
                                                                        </span>
                                                                    </div> */}
                                                                </div>
                                                                <div className="single-product-details">
                                                                    <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                        {items?.product_name.slice(0, 17)}{items?.product_name?.length > 23 ? "..." : ""}
                                                                    </div>

                                                                    <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                        <span className="text-accent text-dark">
                                                                            CFA{items?.sale_price}
                                                                        </span>
                                                                        <strike className="pl-1" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                            CFA{items?.unit_price}
                                                                        </strike><br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </>

                            ) : (
                                <div className='row justify-content-center mt-5 mb-5'>
                                    <div className="spinner-border text-primary"></div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Customer_favouriteProducts_filter

import React from 'react';
import { Skeleton } from 'primereact/skeleton';

export default function Hello() {
  return (
    <div className="__inline-62 pl-4 pr-4 pt-4 pb-4 deal_of_the_day1 row justify-content-between">
      <div className="col-lg-2 col-md-6 col-sm-4 col-6  p-2 ">
        <Skeleton className='deal_of_the_day1' width="12rem" height="20rem"></Skeleton>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-4 col-6  p-2 ">
        <Skeleton  className='deal_of_the_day1' width="12rem" height="20rem"></Skeleton>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-4 col-6  p-2 ">
        <Skeleton  className='deal_of_the_day1' width="12rem" height="20rem"></Skeleton>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-4 col-6  p-2 ">
        <Skeleton  className='deal_of_the_day1' width="12rem" height="20rem"></Skeleton>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-4 col-6  p-2 ">
        <Skeleton  className='deal_of_the_day1' width="12rem" height="20rem"></Skeleton>
      </div>
    </div>
  );
}

import React from 'react'
import Footer from '../Home_page/Footer'

const Track_order = () => {
  return (
    <div>
      <div>
        <div className="container rtl py-5" style={{ textAlign: 'left' }}>
          <div className="card __card">
            <div className="card-body py-5">
              <div className="mw-1000 mx-auto">
                <h3 className="text-center text-capitalize">Track Order</h3>
                <form className="p-3">
                  <input type="hidden" name="_token" defaultValue="g4i7uNIJcxiOCHE9mokJgFVparvZEd9Fu0MP2u33" />
                  <div className="row g-3">
                    <div className="col-md-4 col-sm-6">
                      <input className="form-control prepended-form-control" type="text" placeholder="Order id" required />
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <input className="form-control prepended-form-control" type="text" placeholder="Your phone number" required />
                    </div>
                    <div className="col-md-4">
                      <button className="btn btn--primary w-100" type="submit" name="trackOrder">Track Order</button>
                    </div>
                  </div>
                  <div className="mt-5 pt-md-5 mx-auto text-center" style={{ maxWidth: 377 }}>
                    <img className="mb-2" src="https://6valley.6amtech.com/public/assets/front-end/img/track-truck.svg" alt />
                    <div className="opacity-50">
                      Enter your order ID &amp; phone number to get delivery updates
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>


    </div>
  )
}

export default Track_order
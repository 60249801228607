import React, { useEffect, useMemo, useState } from 'react'
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';
import secureLocalStorage from 'react-secure-storage';
import swal from 'sweetalert'
import { FaUserFriends } from 'react-icons/fa';
import { BsChatDots } from 'react-icons/bs';
import { MdOutlineLoyalty, MdStarBorderPurple500 } from 'react-icons/md';
import { IoIosGift } from 'react-icons/io';
import { HiLightBulb } from 'react-icons/hi';
import { BiLogOut } from 'react-icons/bi';
import { FiCornerUpLeft } from 'react-icons/fi';
import axios from 'axios';

const Search = styled('div')(({ theme }) => ({
    color: 'black',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function Mobileview_inntout() {
    let navigate = useNavigate()
    const wishlistdata = useSelector(state => state.arr);
    const arr = useSelector(state => state.arr);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    let userid = secureLocalStorage.getItem("useid")

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    let gotoaddtocard = () => {
        setMobileMoreAnchorEl(null);
        navigate("/addtocardlist_inntout")
    }


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem>
                <IconButton onClick={gotoaddtocard}
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={arr?.inntoutcardlist?.length} color="error">
                        <i className="navbar-tool-icon czi-cart text-info" />
                    </Badge>
                </IconButton>
            </MenuItem>
        </Menu>
    );


    const [visibleBottom, setVisibleBottom] = useState(false);
    let logout_handel = (deleteid) => {
        swal({
            title: "Are you sure?",
            text: "You want to logout",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            // content: {
            //     element: "input",
            //     attributes: {
            //         placeholder: "Enter your reason for deletion",
            //         type: "text",
            //     },
            // },
        }).then((willDelete) => {
            if (willDelete) {
                userlogout();
            } else {
            }
        });
        let userlogout = () => {
            navigate("/")
            setVisibleBottom(false)
            localStorage.removeItem("loginstatus")
            secureLocalStorage.removeItem("useid")
            secureLocalStorage.removeItem("token")
        }


    };

    let gotohome = () => {
        navigate("/")
    }

    // /categories_products"
    let Productslist = (allproductsid) => {
        // console.log(allproductsid)
        secureLocalStorage.setItem("Inntoutcategories_productsid", allproductsid)
        navigate(`/Inntoutproductslist`)
    }

    let [showcategorylist, setshowcategorylist] = useState()
    let inntcategory = secureLocalStorage.getItem("inntoutcategory")
    useEffect(() => {
        try {
            let data = {
                "maincategoryId": inntcategory
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data).then((res) => {
                // console.log(res.data.data)
                setshowcategorylist(res?.data?.data)
            }).catch((error) => {

            })
        } catch (error) {
        }
    }, [0])



    // search api call 
    let [showsuggetionvalue, setshowsuggetionvalue] = useState([])
    let [search, setsearch] = useState('')
    const [searchValue, setSearchValue] = useState(''); // State to hold the search valu
    let [serchobj, setserchobj] = useState(
        {
            "serachvalue": ""
        }
    )

    let getvaluehandel = (e) => {
        setserchobj({ ...serchobj, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        let data = {
            "key": serchobj?.serachvalue
        }
        let timer = setTimeout(() => {
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/searchInntoutProduct`, data).then((res) => {
                if (res.status === 200) {
                    console.log(res?.data?.data)
                    setshowsuggetionvalue(res?.data?.data)
                }
            }).catch((error) => {
            })
        }, 500);

        return () => {
            clearTimeout(timer)
        }
    }, [serchobj.serachvalue])

    const handleChange = (event, newValue) => {
        serchobj.serachvalue = newValue
        secureLocalStorage.setItem("searchvalue", newValue)
        navigate("/inntout_searchlist")
    }

    let handleFormSubmit = (event) => {
        event.preventDefault()
        secureLocalStorage.setItem("searchvalue", serchobj?.serachvalue)
        navigate("/inntout_searchlist")
    }

    // gotohome handl
    let gotoinntouthome = () => {
        navigate("/inntoutHome")
    }
    return (
        <>
            {userid == null ? (
                <header className="rtl __inline-10  ">
                    <div className="navbar-sticky bg-light mobile-head  ">
                        <div className="navbar navbar-expand-md navbar-light headerfreez " id="headerr">
                            <Box sx={{ flexGrow: 1 }} >
                                <AppBar position="static" style={{ backgroundColor: "white" }}>
                                    <Toolbar>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="black"
                                            aria-label="open drawer"
                                            sx={{ mr: 2 }}
                                        >
                                            <FiCornerUpLeft onClick={gotohome} />

                                            <img width={"35px"} className="__inline-11 pl-2" onClick={gotoinntouthome} src="./logo/newlogo15.jpeg" />
                                        </IconButton>
                                        <Search className='shadow'>
                                            <form onSubmit={handleFormSubmit} className="search_form">
                                                <Stack spacing={2} sx={{ width: "100%", borderRadius: "10px" }}>
                                                    <Autocomplete
                                                        style={{ backgroundColor: "white" }}
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        value={searchValue} // Set the value of the Autocomplete to the searchValue state
                                                        onChange={handleChange} // Handle change in the Autocomplete
                                                        options={showsuggetionvalue?.map((option) => option.product_name)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                name='serachvalue'
                                                                onChange={getvaluehandel}
                                                                size="small"
                                                                value={search}
                                                                placeholder="Search in innt"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </Stack>
                                            </form>
                                            <input style={{ display: "none" }} className="form-control appended-form-control search-bar-input" type="text" autoComplete="off" placeholder="Search here..." name="name" />
                                            <button className="input-group-append-overlay search_button" type="submit" style={{ borderRadius: '0px 7px 7px 0px', left: 'unset', right: 0, top: 0 }}>
                                                <span className="input-group-text __text-20px">
                                                    <i className="czi-search text-dark" />
                                                </span>
                                            </button>

                                        </Search>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>


                                        </Box>
                                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                            <IconButton
                                            >
                                                <Link to="/login">
                                                    <span style={{ fontSize: "x-small", color: "black" }}><b>hello,</b><b>sign in</b></span>
                                                </Link>
                                            </IconButton>
                                        </Box>
                                    </Toolbar>
                                </AppBar>
                                {renderMobileMenu}
                                {renderMenu}

                            </Box>
                        </div>
                        <div className="navbar navbar-expand-md navbar-stuck-menu" style={{ marginTop: "30px", marginBottom: "5px" }}>
                        </div>
                    </div>
                    <div className="container" style={{ backgroundColor: "rgb(120, 165, 120)" }}>
                        <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                                <div className="navbar navbar-expand-md " style={{ marginTop: "30px", marginBottom: "5px" }}>
                                    <div className="">

                                        <div className="" >

                                            <ul className="d-flex" style={{ listStyle: "none" }} >
                                                <li className="nav-item" >
                                                    <Link to="/inntoutHome" className="nav-link text-capitalize text-dark text-bold" style={{ paddingLeft: "0px", fontWeight: "bold" }}>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <Link className="nav-link text-capitalize text-dark" to="/" style={{ fontWeight: "bold" }}>Back to innt</Link>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle text-dark " style={{ fontWeight: "bold" }} href="#" data-toggle="dropdown">Categories</a>
                                                    <ul className="dropdown-menu __dropdown-menu-sizing dropdown-menu-left scroll-bar" style={{ textAlign: 'left' }}>
                                                        {showcategorylist?.map((items, index) => {
                                                            return (
                                                                <li key={index} style={{ cursor: "pointer" }} onClick={() => Productslist(items._id)} className="__inline-17">
                                                                    <div className='pl-3 pt-3 pb-3'>
                                                                        <div >
                                                                            {items.category_englishName}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </li>

                                                <li className="nav-item dropdown ">
                                                    <Link className="nav-link text-capitalize text-dark" to="/discountProduct_list" style={{ fontWeight: "bold" }}>Discounts</Link>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <Link className="nav-link text-capitalize text-dark" to="/inntoutorderlist" style={{ fontWeight: "bold" }}>My Order</Link>
                                                </li>
                                            </ul>

                                            <style>
                                                {`
    @media (max-width: 768px) {
      .nav-link {
        font-size: x-small; /* Change to small font size on mobile */
        padding: 0px;
        padding-right: 15px;
      }
    }
  `}
                                            </style>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            ) : (
                <header className="rtl __inline-10  ">
                    <div className="navbar-sticky bg-light mobile-head  ">
                        <div className="navbar navbar-expand-md navbar-light headerfreez " id="headerr">
                            <Box sx={{ flexGrow: 1 }} >
                                <AppBar position="static" style={{ backgroundColor: "white" }}>
                                    <Toolbar>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="black"
                                            aria-label="open drawer"
                                            sx={{ mr: 2 }}
                                        >
                                            <FiCornerUpLeft onClick={gotohome} />
                                            <img width={"35px"} className="__inline-11 pl-2 pl-2" onClick={gotoinntouthome} src="./logo/newlogo15.jpeg" />
                                        </IconButton>
                                        <Search className='shadow'>
                                            <form onSubmit={handleFormSubmit} className="search_form">
                                                <Stack spacing={2} sx={{ width: "100%", borderRadius: "10px" }}>
                                                    <Autocomplete
                                                        style={{ backgroundColor: "white" }}
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        value={searchValue} // Set the value of the Autocomplete to the searchValue state
                                                        onChange={handleChange} // Handle change in the Autocomplete
                                                        options={showsuggetionvalue?.map((option) => option.product_name)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                name='serachvalue'
                                                                onChange={getvaluehandel}
                                                                size="small"
                                                                value={search}
                                                                placeholder="Search in innt"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </Stack>
                                            </form>
                                            <input style={{ display: "none" }} className="form-control appended-form-control search-bar-input" type="text" autoComplete="off" placeholder="Search here..." name="name" />
                                            <button className="input-group-append-overlay search_button" type="submit" style={{ borderRadius: '0px 7px 7px 0px', left: 'unset', right: 0, top: 0 }}>
                                                <span className="input-group-text __text-20px">
                                                    <i className="czi-search text-dark" />
                                                </span>
                                            </button>

                                        </Search>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>


                                        </Box>
                                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                            <IconButton
                                                size="large"
                                                aria-label="show more"
                                                aria-controls={mobileMenuId}
                                                aria-haspopup="true"
                                                onClick={handleMobileMenuOpen}
                                                color="black"
                                            >
                                                <MoreIcon />
                                            </IconButton>
                                        </Box>
                                    </Toolbar>
                                </AppBar>
                                {renderMobileMenu}
                                {renderMenu}

                            </Box>
                        </div>
                        <div className="navbar navbar-expand-md navbar-stuck-menu" style={{ marginTop: "30px", marginBottom: "5px" }}>
                        </div>
                    </div>
                    <div className="container" style={{ backgroundColor: "rgb(120, 165, 120)" }}>
                        <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                                <div className="navbar navbar-expand-md " style={{ marginTop: "30px", marginBottom: "5px" }}>
                                    <div className="">

                                        <div className="" >

                                            <ul className="d-flex" style={{ listStyle: "none" }} >
                                                <li className="nav-item" >
                                                    <Link to="/inntoutHome" className="nav-link text-capitalize text-dark text-bold" style={{ paddingLeft: "0px", fontWeight: "bold" }}>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <Link className="nav-link text-capitalize text-dark" to="/" style={{ fontWeight: "bold" }}>Back to innt</Link>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle text-dark " style={{ fontWeight: "bold" }} href="#" data-toggle="dropdown">Categories</a>
                                                    <ul className="dropdown-menu __dropdown-menu-sizing dropdown-menu-left scroll-bar" style={{ textAlign: 'left' }}>
                                                        {showcategorylist?.map((items, index) => {
                                                            return (
                                                                <li key={index} style={{ cursor: "pointer" }} onClick={() => Productslist(items._id)} className="__inline-17">
                                                                    <div className='pl-3 pt-3 pb-3'>
                                                                        <div >
                                                                            {items.category_englishName}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </li>

                                                <li className="nav-item dropdown ">
                                                    <Link className="nav-link text-capitalize text-dark" to="/discountProduct_list" style={{ fontWeight: "bold" }}>Discounts</Link>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <Link className="nav-link text-capitalize text-dark" to="/inntoutorderlist" style={{ fontWeight: "bold" }}>My Order</Link>
                                                </li>
                                            </ul>

                                            <style>
                                                {`
    @media (max-width: 768px) {
      .nav-link {
        font-size: x-small; /* Change to small font size on mobile */
        padding: 0px;
        padding-right: 15px;
      }
    }
  `}
                                            </style>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}





        </>
    );
}

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  bannerlist: [],
  cardlist: [],
  wishlist: [],
  inntoutcardlist: [],
  userprofiledata: [],
  addresdata: [],
  locationlist: [],
  todaysdeals: [],
  pathnameinntout:[]
}

const arrSlice = createSlice({
  name: 'arr',
  initialState,
  reducers: {
    setArr: (state, action) => {
      state.bannerlist = action.payload;
    },
    setcard_list: (state, action) => {
      state.cardlist = action.payload;
    },
    setwish_list: (state, action) => {
      state.wishlist = action.payload;
    },
    inntout_setcard_list: (state, action) => {
      state.inntoutcardlist = action.payload;
    },
    user_profile: (state, action) => {
      state.userprofiledata = action.payload;
    },
    address_list: (state, action) => {
      state.addresdata = action.payload;
    },
    locationdata: (state, action) => {
      state.locationlist = action.payload;
    },
    todaysdealsdata: (state, action) => {
      state.todaysdeals = action.payload;
    },


    pathname_inntout: (state, action) => {
      state.pathnameinntout = action.payload;
    },
  },
});

export const { setArr, setcard_list, setwish_list, inntout_setcard_list, user_profile, address_list, locationdata, todaysdealsdata ,pathname_inntout } = arrSlice.actions;

export default arrSlice.reducer;
import axios from "axios";
import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
const UseFetch = (url) => {
    const [data, setData] = useState(null);
    let userid = secureLocalStorage.getItem("useid")
    useEffect(() => {
        try {
            let data = {
                "userId": userid,
                "status": "0"
            }
            axios.post(url, data).then((res) => {
                console.log(res.data)
                if (res.status == 200) {
                    setData(res.data.data)
                }

            }).catch((error) => {

            })
        } catch (error) {
        }
    }, []);
// frind list and search frind list
    return [data];
};

export default UseFetch;
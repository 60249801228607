import React, { useEffect, useState } from 'react'
import Header from './Home_page/Header'
import { Link, useNavigate } from 'react-router-dom'
import Footer from './Home_page/Footer'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import toast, { Toaster } from "react-hot-toast";
import Verifyotp from './Verifyotp'

const Signup = () => {

  let navigate = useNavigate();
  let mobilenumber = secureLocalStorage.getItem("mobile_number")

  useEffect(() => {
    if (mobilenumber != null) {
      navigate("/verifyotp")
    }
  }, [mobilenumber])

  let [msg, setmsg] = useState("");
  let [passwordmsg, setpasswordmsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);


  let [inputvalue, setinputvalue] = useState(
    {
      "first_name": "",
      "last_name": "",
      "mobile_number": "",
      "password": "",
      "conform_password": "",
      "user_profile": "",
      "fcm_id": "6666666",
      "friend_referral_code": "",
      "email": "",
    }
  );

  // show password icons
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  // show img and handel input file
  const handleImageChange = (e) => {
    inputvalue.user_profile = e.target.files[0]
    const input = e.target;
    const file = input.files && input.files[0];
    inputvalue.user_profile = file
    setmsg("")

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      // Handle the case where no file is selected
      setImagePreview(null);
    }
  };

  // all input handel 
  let handelinputchange = (event) => {
    setinputvalue({ ...inputvalue, [event.target.name]: event.target.value })
  };

  // signup handel
  let [signupbtn_status, setsignupbtn_status] = useState(true)

  let signuphandel = (event) => {
    let data = "hello"
    localStorage.setItem("loginstatus", data)
    event.preventDefault()
    setsignupbtn_status(false)

     const formData = new FormData();
      formData.append("first_name" , inputvalue?.first_name)
      formData.append("last_name" , inputvalue?.last_name)
      formData.append("mobile_number" , inputvalue?.mobile_number)
      formData.append("password" , inputvalue?.password)
      formData.append("conform_password" , inputvalue?.conform_password)
      formData.append("user_profile" , inputvalue?.user_profile)
      formData.append("fcm_id" , inputvalue?.fcm_id,)
      formData.append("friend_referral_code" , inputvalue?.friend_referral_code)
      formData.append("email" , inputvalue?.email)
    

    if (inputvalue.password == inputvalue.conform_password) {
      setpasswordmsg('');
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/userSignup`, formData).then((res) => {
        if (res.data.result) {
          secureLocalStorage.setItem("mobile_number", res?.data?.data?.mobile_number)
          secureLocalStorage.setItem("email", res?.data?.data?.email)
          secureLocalStorage.setItem("otp", res?.data?.data?.otp)
          toast.success("User Signup Successfully")
          setsignupbtn_status(true)
          setTimeout(() => {
            navigate(`/verifyotp`)
          }, 500);
        }
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(`${error.response.data.message}`)
          setsignupbtn_status(true)
        }
      })
    } else {
      setpasswordmsg('Passwords is not maching');
    }
  };


  // login_goto_singnup handel
  let singnup_goto_login = () => {
    navigate("/login")
  }



  return (
    <div>
      {mobilenumber != null ? (
        <Verifyotp />
      ) : ("")}
      <Toaster />

      <div className="container py-4 py-lg-5 my-4" style={{ textAlign: 'left' }}>
        <div className="login-card">

          <div className='text-center mb-4'>
            <span className='font-bold text-capitalize fs-18-mobile mr-5  pb-1 pr-3 pl-3' style={{ cursor: "pointer" }} onClick={singnup_goto_login}>Sign in</span>
            <span className='font-bold text-capitalize fs-18-mobile ml-5  pb-1 pr-3 pl-3' style={{ cursor: "pointer", borderBottom: "2px solid #1455ac" }}  >Sign up</span>
          </div>
          <div className="mx-auto __max-w-760">
            <form className="needs-validation_" onSubmit={signuphandel} >
              <div className='row justify-content-center'>
                <div className='col-lg-3'>
                  <div className="profile--info mb-4">
                    <div className="position-relative profile-img mb-3">
                      <div id="imagePreview">
                        <img id="blah" className='borderinput' src={imagePreview} />
                      </div>
                      <label className="change-profile-icon m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                          <path d="M7.3125 9.75C7.3125 9.30245 7.49029 8.87323 7.80676 8.55676C8.12323 8.24029 8.55245 8.0625 9 8.0625C9.44755 8.0625 9.87678 8.24029 10.1932 8.55676C10.5097 8.87323 10.6875 9.30245 10.6875 9.75C10.6875 10.1976 10.5097 10.6268 10.1932 10.9432C9.87678 11.2597 9.44755 11.4375 9 11.4375C8.55245 11.4375 8.12323 11.2597 7.80676 10.9432C7.49029 10.6268 7.3125 10.1976 7.3125 9.75Z" fill="white" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M5.6055 5.7315C5.6055 5.42204 5.66646 5.11561 5.78488 4.82971C5.90331 4.5438 6.07689 4.28402 6.29571 4.0652C6.73764 3.62327 7.33702 3.375 7.962 3.375H10.038C10.663 3.375 11.2624 3.62327 11.7043 4.0652C12.1462 4.50713 12.3945 5.10652 12.3945 5.7315C12.3946 5.73685 12.3967 5.74198 12.4002 5.74597C12.4038 5.74996 12.4087 5.75254 12.414 5.75325L14.0865 5.88825C14.8358 5.94975 15.4515 6.50325 15.5918 7.242C15.9483 9.12636 15.9747 11.0584 15.6698 12.9517L15.597 13.4047C15.5303 13.8198 15.3262 14.2005 15.0176 14.4859C14.7089 14.7713 14.3135 14.9449 13.8945 14.979L12.4373 15.0968C10.1495 15.2826 7.85049 15.2826 5.56275 15.0968L4.1055 14.979C3.6864 14.9449 3.29087 14.7711 2.98221 14.4856C2.67355 14.2 2.4696 13.8192 2.403 13.404L2.33025 12.9517C2.025 11.058 2.052 9.12675 2.40825 7.242C2.47637 6.88276 2.66054 6.55582 2.93247 6.31139C3.20441 6.06697 3.54906 5.91857 3.9135 5.889L5.586 5.75325C5.59144 5.75286 5.59652 5.75038 5.60015 5.74632C5.60379 5.74226 5.60571 5.73695 5.6055 5.7315ZM9 6.9375C8.25408 6.9375 7.53871 7.23382 7.01127 7.76126C6.48382 8.28871 6.1875 9.00408 6.1875 9.75C6.1875 10.4959 6.48382 11.2113 7.01127 11.7387C7.53871 12.2662 8.25408 12.5625 9 12.5625C9.74592 12.5625 10.4613 12.2662 10.9887 11.7387C11.5162 11.2113 11.8125 10.4959 11.8125 9.75C11.8125 9.00408 11.5162 8.28871 10.9887 7.76126C10.4613 7.23382 9.74592 6.9375 9 6.9375Z" fill="white" />
                        </svg>
                        <input type="file" id="imageInput" accept="image/*" onChange={handleImageChange} hidden />
                      </label>
                    </div>
                    <label className="form-label font-semibold">Upload a Profile Picture</label>
                    <small className='text-danger'>{msg}</small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label font-semibold">First name</label>
                    <input className="form-control borderinput" type="text" onChange={handelinputchange} required name="first_name" placeholder='Entre your first name' style={{ textAlign: 'left' }} />
                    <div className="invalid-feedback">Please enter your first name!</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label font-semibold">Last name</label>
                    <input className="form-control borderinput" type="text" onChange={handelinputchange} required name="last_name" placeholder='Entre your last name' style={{ textAlign: 'left' }} />
                    <div className="invalid-feedback">Please enter your last name!</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label font-semibold">Email address</label>
                    <input className="form-control borderinput" type="email" onChange={handelinputchange} name="email" placeholder='Entre your email' style={{ textAlign: 'left' }} autoComplete="off" />
                    <div className="invalid-feedback">Please enter valid email address!</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label font-semibold">Mobile number
                      {/* <small className="text-primary">( * Country code is must like for BD 880 )</small> */}
                    </label>
                    <input className="form-control borderinput" type="number" onChange={handelinputchange} required name="mobile_number" placeholder='Entre your number' style={{ textAlign: 'left' }} />
                    <div className="invalid-feedback">Please enter your phone number!</div>
                  </div>
                </div>
                <div className="col-sm-6">

                  <div className="form-group">
                    <label className="form-label font-semibold">Password</label>
                    <div className="password-toggle rtl">
                      <input className="form-control borderinput" onChange={handelinputchange} required name="password" placeholder='Entre your password' type={showPassword ? 'text' : 'password'} id="si-password" style={{ textAlign: 'left' }} />
                      <label className="password-toggle-btn">
                        <input className="custom-control-input" type="checkbox" /><span className="sr-only">Show password </span>
                      </label>
                    </div>
                    <span style={{ position: "absolute", top: "35px", right: "15px", fontSize: "20px", cursor: "pointer" }} onClick={togglePasswordVisibility}>
                      {showPassword ? '👁️' : '👁️‍🗨️'}
                    </span>
                    <small className='text-danger'>{passwordmsg}</small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label font-semibold">Confirm Password</label>
                    <div className="password-toggle rtl">
                      <input className="form-control borderinput" onChange={handelinputchange} required name="conform_password" placeholder='Confirm password' type={showPassword1 ? 'text' : 'password'} id="si-password" style={{ textAlign: 'left' }} />
                      <label className="password-toggle-btn">
                        <input className="custom-control-input" type="checkbox" /><span className="sr-only">Show password </span>
                      </label>
                    </div>
                    <span style={{ position: "absolute", top: "35px", right: "15px", fontSize: "20px", cursor: "pointer" }} onClick={togglePasswordVisibility1}>
                      {showPassword1 ? '👁️' : '👁️‍🗨️'}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label font-semibold">Refer code
                      {/* <small className="text-muted">(Optional)</small> */}
                    </label>
                    <input type="text" id="referral_code" className="form-control borderinput" onChange={handelinputchange} name="friend_referral_code" placeholder='Add referral code' />
                  </div>
                </div>
              </div>

              <div style={{ direction: 'ltr' }}>
                <div className="mx-auto mt-4 __max-w-356">
                  {signupbtn_status === true ? (
                    <button className="w-100 btn btn--primary" type="submit" >
                      Continue
                    </button>
                  ) : (
                    <div className='text-center'>
                      <div className="spinner-border text-primary"></div>
                    </div>
                  )}

                </div>
                {/* <div className="text-black-50 mt-3 text-center">
                  <small>
                    Already have account ?
                    <Link className="text-primary text-underline" to="/login">
                      Sign in
                    </Link>
                  </small>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  )
}

export default Signup
import React, { useEffect } from 'react'
import Footer from '../Home_page/Footer'
import axios from 'axios'
import { useState } from 'react'

const Terms_and_conditions = () => {

  let [showtermandcondition, setshowtermandcondition] = useState({})
  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_SECRET_KEY}/termAndCondiction`).then((res) => {
        // console.log(res)
        if (res.data.result) {
          setshowtermandcondition(res.data.data[0])
        }
      }).catch((err) => {

      })

    } catch (error) {

    }
  })
  return (
    <div>

      <div className="row">
        <div className="col-12" style={{ marginTop: '10rem', position: 'fixed', zIndex: 9999 }}>
          <div id="loading" style={{ display: 'none' }}>
            <center>
              <img width={200} src="https://6valley.6amtech.com/storage/app/public/company/2022-04-23-62640d298e373.png" onerror="this.src='https://6valley.6amtech.com/public/assets/front-end/img/loader.gif'" />
            </center>
          </div>
        </div>
      </div>
      <div className="__inline-60">
        <div className="container rtl">
          <div className="row">
            <div className="col-md-12 sidebar_heading text-center mb-2">
              <h1 className="h3  mb-0 folot-left headerTitle">{showtermandcondition?.title}</h1>
            </div>
          </div>
          <hr />
        </div>
        <div className="container pb-5 mb-2 mb-md-4 mt-3 rtl">
          <div className="row justify-content-center">
            <section className="col-lg-10 mt-3">
              <section className="container pt-4 pb-5 ">
                <div className="row pt-4" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: showtermandcondition?.title }} />
              </section>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Terms_and_conditions 
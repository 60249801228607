import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import { IoLocationOutline } from 'react-icons/io5';
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { topsellardata } from '../../readux/arrSlice';
import { useQuery } from '@tanstack/react-query';



const Sellers_inntout = () => {

    let dispatch = useDispatch()
    let navigate = useNavigate()
    let [t, i18n] = useTranslation("global")



    // const locationdata = useSelector(state => state.arr);
    let locationdata = JSON.parse(secureLocalStorage.getItem("latlon"))
    const fetchProducts = async () => {
        let data = {
            "lat": locationdata?.lat,
            "lon": locationdata?.lon
        }
        const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutShop_list`, data);
        return response.data;
    };


    const { data: products, error, isLoading } = useQuery({
        queryKey: ['inntOutShop_list'],
        queryFn: fetchProducts,
    });

    // console.log("inntOutShop_list", products?.data)

    let [showallshops, setshowallshops] = useState()
    let userid = secureLocalStorage.getItem("useid")

    useEffect(() => {
        getSellerList();
    }, []);

    let getSellerList = () => {
        try {
            let data = {
                "lat": locationdata?.lat,
                "lon": locationdata?.lon
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntOutShop_list`, data)
                .then((res) => {
                    if (res.data.result) {
                        setshowallshops(res?.data?.data)
                    }
                })
                .catch((error) => {
                    // console.log("Error:", error)
                });
        } catch (error) {
        }
    }

    // shopdetails handel
    let shopsdetails = (objitems) => {
        secureLocalStorage.setItem("shopid", objitems?.shopId)
        navigate("/shopview_inntout")
    }

    return (
        <div className="mt-2">
            <Toaster />
            <div className="container px-md-3">
                <div className="__inline-62 pl-4 pr-4 pt-2 pb-4 deal_of_the_day1">
                    <div className="feature-product-title mt-0 ml-1" style={{ color: 'black', textAlign: "start" }}>
                        {t("navbar.topSellers")}
                        <Link to="/topsaller_details_inntout" className="text-capitalize " style={{ fontSize: "15px", color: '#1455ac', cursor: "pointer", float: "right" }}>
                            {t("navbar.viewall")}
                        </Link>
                    </div>
                    <div className="mt-3">
                        {products?.data?.length === 0 ? (
                            <div className='col-12 row  justify-content-center '>
                                <div className='col-lg-4 col-12 text-center'>
                                    <h6 className='mt-2'>Data Not Found</h6>
                                    <div>
                                        <button className='btn btn-info mt-2'><Link to="/">Go To Home</Link></button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='row'>
                                {products?.data?.slice(0, 8)?.map((items, index) => {
                                    return (
                                        <div className='col-lg-3 col-md-6 col-12'>
                                            <div onClick={() => shopsdetails(items)} key={index} className="others-store-card p-1">
                                                <div style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                    <div className="overflow-hidden other-store-banner">
                                                        <img style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.frontImage}`} className="w-100 h-100 object-cover" alt />
                                                    </div>
                                                    <div className="name-area">
                                                        <div className="position-relative">
                                                            <div className="overflow-hidden other-store-logo rounded-full">
                                                            {console.log("items?.logo", items?.logo)}
                                                                {/* <img style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} className="rounded-full" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`} /> */}
                                                                {
                                                                    items?.logo === " " ||  items?.logo === null || items?.logo === undefined ? (
                                                                        <img
                                                                            src="./imglist/user.png"
                                                                            className="avatar rounded-circle"
                                                                            alt=""
                                                                            width={40}
                                                                        />
                                                                    ) : items?.logo?.startsWith("https://") ? (
                                                                        <img
                                                                            onClick={() => shopsdetails(items)}  src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`}
                                                                            className="avatar rounded-circle"
                                                                            alt=""
                                                                            width={40}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            onClick={() => shopsdetails(items)}  src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.logo}`}
                                                                            className="avatar rounded-circle"
                                                                            alt=""
                                                                            width={40}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="info pt-2 ">
                                                            <div style={{ textAlign: "start" }}>
                                                                <h5 style={{ cursor: "pointer" }} onClick={() => shopsdetails(items)} className='mt-2'>{items?.shop_name}</h5>
                                                                <small className=''><IoLocationOutline /> {items?.shop_address.slice(0, 20)}{items?.shop_address?.length >= 20 ? "..." : ""}</small> <br />
                                                                <small className=''>{items.distances}KM</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="info-area">
                                                        <div className="info-item">
                                                            <h6 style={{ color: '#1455ac' }}>{items.raters}</h6>
                                                            <span>Raters</span>
                                                        </div>
                                                        <div className="info-item">
                                                            <h6 style={{ color: '#1455ac' }}>{items.sales}</h6>
                                                            <span>Sales</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sellers_inntout
import { Autocomplete, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'

const Mobileproductssearch = () => {
    let navigate = useNavigate()
    let [showsuggetionvalue, setshowsuggetionvalue] = useState([])
    let [search, setsearch] = useState('')
    const [searchValue, setSearchValue] = useState(''); // State to hold the search valu
    let [serchobj, setserchobj] = useState(
        {
            "serachvalue": ""
        }
    )

    let getvaluehandel = (e) => {
        setserchobj({ ...serchobj, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        let data = {
            "key": serchobj?.serachvalue
        }
        let timer = setTimeout(() => {
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/searchProduct`, data).then((res) => {
                if (res.status === 200) {
                    // console.log(res?.data?.data)
                    setshowsuggetionvalue(res?.data?.data)
                }
            }).catch((error) => {
            })
        }, 500);

        return () => {
            clearTimeout(timer)
        }
    }, [serchobj.serachvalue])

    // search products
    const handleChange = (event, newValue) => {
        serchobj.serachvalue = newValue
        secureLocalStorage.setItem("searchvalue", newValue)
        navigate("/searchproducts")
    }

    let handleFormSubmit = (event) => {
        event.preventDefault()
        secureLocalStorage.setItem("searchvalue", serchobj?.serachvalue)
        navigate("/searchproducts")
    }

    return (
        <div>

            <div className="input-group-overlay " style={{ textAlign: 'left' }}>
                <form onSubmit={handleFormSubmit} className="search_form">
                    <Stack spacing={2} sx={{ width: "100%", borderRadius: "10px" }}>
                        <Autocomplete
                            style={{ backgroundColor: "white" }}
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            value={searchValue} // Set the value of the Autocomplete to the searchValue state
                            onChange={handleChange} // Handle change in the Autocomplete
                            options={showsuggetionvalue?.map((option) => option.product_name)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name='serachvalue'
                                    onChange={getvaluehandel}
                                    size="small"
                                    value={search}
                                    placeholder='Search Here'
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                    </Stack>
                    <input style={{ display: "none" }} className="form-control appended-form-control search-bar-input" type="text" autoComplete="off" placeholder="Search here..." name="name" />
                    <button className="input-group-append-overlay search_button" type="submit" style={{ borderRadius: '0px 7px 7px 0px', left: 'unset', right: 0, top: 0 }}>
                        <span className="input-group-text __text-20px">
                            <i className="czi-search text-white" />
                        </span>
                    </button>
                </form>
            </div>

        </div>
    )
}

export default Mobileproductssearch
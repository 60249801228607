import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Slider from 'react-slick';
import { useQuery } from '@tanstack/react-query';



const Inntout_category = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false, // Hide the previous and next arrows
    autoplay: true, // Enable autoplay
    slidesToShow: 8, // Initially, show 4 slides
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Adjust the settings for screens with width less than 1024px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Adjust the settings for screens with width less than 768px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Adjust the settings for screens with width less than 480px
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  let navigate = useNavigate()
  let inntoutcategory = secureLocalStorage.getItem("inntoutcategory")

  const fetchProducts = async () => {
    let data = {
      "maincategoryId": inntoutcategory
    }
    const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/InntCategory_list`, data);
    return response.data;
  };

  const { data: products, error, isLoading } = useQuery({
    queryKey: ['InntCategory_list'],
    queryFn: fetchProducts,
  });

  // /categories_products"
  let Productslist = (allproductsid) => {
    // console.log(allproductsid)
    secureLocalStorage.setItem("Inntoutcategories_productsid", allproductsid)
    navigate(`/Inntoutproductslist`)
  }


  return (
    <div className="container ">
      <div className='row justify-content-center mt-3'>
        <div className='col-lg-10'>

          <div className="card __shadow h-100 max-md-shadow-0" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="feature-product-title mt-0 ml-1 mb-3" style={{ color: "black", fontWeight: "700", textAlign: "start" }}>
                  Choose Your Menu
                </div>

                <div>
                  {/* <Link className="text-capitalize " style={{ color: '#1455ac!important' }} to="#">View all
                    <i className="czi-arrow-right ml-1 mr-n1" />
                  </Link> */}
                </div>
              </div>

              {products?.data === 0 ? (
                <div className='col-12 row  justify-content-center '>
                  <div className='col-lg-4 col-12 text-center'>
                    <h6 className='mt-2'>Data Not Found</h6>
                    <div>
                      <button className='btn btn-info mt-2'><Link to="/">Go To Home</Link></button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {products?.data <= 6 ? (
                    <div className='row'>
                      {products?.data?.slice(0, 8)?.map((items, index) => {
                        return (
                          <div key={index} className="text-center __m-5px __cate-item">
                            <a onClick={() => Productslist(items._id)}>
                              <div className="__img">
                                <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.category_image}`} />
                              </div>
                              <p className="text-center small mt-2">{items.category_englishName}</p>
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <>
                      <div className='row'>
                        {products?.data?.slice(0, 8)?.map((items, index) => {
                          return (
                            <div className='col-lg-2 col-md-6 col-6 mb-2'>
                              <div key={index} className="text-center __m-5px __cate-item">
                                <a onClick={() => Productslist(items._id)}>
                                  <div className="__img">
                                    <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.category_image}`} />
                                  </div>
                                  <p className="text-center small mt-2">{items.category_englishName}</p>
                                </a>
                              </div>
                            </div>
                          )

                        })}
                      </div>
                    </>
                  )}

                </>
              )}





            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Inntout_category
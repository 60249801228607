import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Footer = () => {
  let [t, i18n] = useTranslation("global")
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This makes the scroll smooth
    });
  };

  return (
    <div>
      <footer className="page-footer font-small mdb-color rtl">
        <div className="pt-4" style={{ background: '#1455ac20' }}>
          <div className="container text-center __pb-13px">
            <div className="row justify-content-between   text-center text-md-left mt-3 pb-3 ">
              <div className="col-md-3 col-lg-3 pl-4 footer-web-logo text-center">
                <h6 className="text-uppercase mb-4 font-weight-bold footer-heder text-start"><b>{t("footer.needhelp")} </b></h6>
                <ul className="widget-list __pb-10px ">
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="/contact" onClick={scrollToTop}>Contact-Us</Link>
                  </li>
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="#" onClick={scrollToTop}>Frequently asked question</Link>
                  </li>

                  <h6 className="text-uppercase mt-4 font-weight-bold footer-heder text-start"><b>NewSletter</b></h6>
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="#"><small>Sign up to   our newsletter to receives our best deals and promotion</small></Link>
                  </li>
                  {/* <div className="input-group-overlay search-form-mobile">
                    <form type="submit" className="search_form">
                      <input className="form-control appended-form-control search-bar-input" type="text" autoComplete="off" placeholder="Search here..." name="name" />
                      <button className="input-group-append-overlay search_button" type="submit" style={{ borderRadius: '0px 7px 7px 0px', left: 'unset', right: 0, top: 0 }}>
                        <span className="input-group-text __text-20px text-white">
                          <small> Subscribe</small>
                        </span>
                      </button>
                    </form>
                  </div> */}
                </ul>

              </div>
              <div className="col-md-3 col-lg-3 pl-4 footer-web-logo text-center">
                <h6 className="text-uppercase mb-4 font-weight-bold footer-heder text-start">About Us</h6>
                <ul className="widget-list __pb-10px ">
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="/aboutcompany" onClick={scrollToTop} >Who are we?</Link>
                  </li>
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="/termsandcondition" onClick={scrollToTop} >Terms and conditions</Link>
                  </li>
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="/pricacyprolicy" onClick={scrollToTop} >Privacy policy</Link>
                  </li>
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="/refund_policy" onClick={scrollToTop} >Retrun policy</Link>
                  </li>
                  <li className="widget-list-item"><Link className="widget-list-link text-start" to="/return_policy" onClick={scrollToTop} >Refund policy</Link>
                  </li>
                </ul>

              </div>

              <div className="col-md-3 col-lg-3 pl-4 footer-padding-bottom text-center">
                <h6 className="text-uppercase mb-4 font-weight-bold footer-heder text-start">partner with innt</h6>
                <ul className="widget-list __pb-10px">
                  <li className="widget-list-item"><a className="widget-list-link text-start" href="https://www.vendor.innt.bf/">Sell on innt</a>
                  </li>
                  <li className="widget-list-item"><a className="widget-list-link text-start" href="#">Deliver on innt</a>
                  </li>
                </ul>
              </div>

              <div className="col-md-3 col-lg-3 pl-4 footer-padding-bottom">
                <div className="">
                  <h6 className="text-uppercase mb-4 font-weight-bold footer-heder text-start">Download innt for free</h6>

                  <div className="store-contents d-flex pr-lg-4">
                    <div className="mr-2 mb-2">
                      <a className href="#" role="button">
                        <img width={100} src="public/assets/front-end/png/apple_app.png" alt />
                      </a>
                    </div>
                    <div className="mr-2 mb-2">
                      <a href="#" role="button">
                        <img width={100} src="public/assets/front-end/png/google_app.png" alt />
                      </a>
                    </div>
                  </div>
                  <h6 className="text-uppercase mt-3 font-weight-bold footer-heder text-start">Join us on</h6>
                  <div className="store-contents d-flex pr-lg-4">
                    <div className="mr-2 mb-2">
                      <a className href="#" role="button">
                        <img width={50} src="./imglist/Facebook_icon.svg.webp" alt />
                      </a>
                    </div>
                    <div className="mr-2 mb-2">
                      <a href="#" role="button">
                        <img width={50} src="./imglist/Instagram_logo_2016.svg.webp" alt />
                      </a>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          <p className="t ext-left __text-16px text-center p-2">Copyright innt@2024</p>
          </div>
        </div>


      </footer>

    </div>
  )
}

export default Footer
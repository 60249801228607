import React, { useEffect, useState } from 'react'
import Footer from '../Home_page/Footer'
import { Typography } from '@mui/material'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import toast, { Toaster } from "react-hot-toast";
import { TfiLayoutMenuSeparated } from "react-icons/tfi";
import Popover from '@mui/material/Popover';
import swal from 'sweetalert'
import { FaHeart, FaRegEye } from 'react-icons/fa'
import { FcSalesPerformance } from 'react-icons/fc'
import { IoLocationOutline } from 'react-icons/io5'
import { BsThreeDots } from "react-icons/bs";
import { IoIosStar, IoMdShareAlt } from "react-icons/io";
import { CiChat1 } from "react-icons/ci";
import { FaRegStar } from "react-icons/fa6";
import { MdReportProblem } from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import { setcard_list, setwish_list } from '../../readux/arrSlice'
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-cyan/theme.css";



const Shopview_inntout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  let shopid = secureLocalStorage.getItem("shopid")
  let userid = secureLocalStorage.getItem("useid")
  let [newArrival_Product_list, setnewArrival_Product_list] = useState([])
  let [showproductsdetails, setshowproductsdetails] = useState([])
  let [mainproductsimg, setmainproductsimg] = useState("")
  let [loderstatus, setloderstatus] = useState(true)
  const [maxPrice, setMaxPrice] = useState("");
  const [visible, setVisible] = useState(false);

  let [filtervalueobj, setfiltervalueobj] = useState(
    {
      "shopId": shopid,
      "price": "100",
      "discount": "",
      "rating": ""
    }
  )
  let [attributes, setattributes] = useState([])
  // social media toggel
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'popover-button' : undefined;
  const [age, setAge] = useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };


  let [shopproductslist, setshopproductslist] = useState()
  let [showdata, setshowdata] = useState([])
  useEffect(() => {
    getviewshops()
    addView_shop()
  }, [])
  let getviewshops = () => {
    setloderstatus(false)
    try {
      let data = {
        "shopId": shopid
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/inntShop_details`, data)
      .then((res) => {
          console.log("ressss",res)
          if (res?.status === 200) {
            setloderstatus(true)
            setshopproductslist(res?.data?.data?.productlist)
            setshowdata(res?.data?.data)
          }
        })
        .catch((error) => {
          setloderstatus(true)
        });
    } catch (error) {
      setloderstatus(true)
    }
  }

  //addView_shop
  let addView_shop = () => {
    try {
      let data = {
        "userId": userid,
        "shopId": shopid
      }

      axios.post(`${process.env.REACT_APP_SECRET_KEY}/addView_shop`, data)
        .then((res) => {
          if (res?.status === 200) {
          }
        })
        .catch((error) => {
        });
    } catch (error) {
    }
  }

  // get wish list 
  useEffect(() => {
    getnewArrival_Product_list()
  }, [0])
  let getnewArrival_Product_list = () => {
    try {
      axios.get(`${process.env.REACT_APP_SECRET_KEY}/newArrival_Product_list`).then((res) => {
        // console.log(res.data)
        if (res.status == 200) {
          setnewArrival_Product_list(res.data.data)
        }
      }).catch((error) => {
        // console.log(error)

      })
    } catch (error) {
    }



  }

  // get products details
  let locationdata = JSON.parse(secureLocalStorage.getItem("latlon"))
  let getproductsdetails = (items) => {
    try {
      let data = {
        "userId": userid,
        "productId": items?.productId,
        "shopId": items?.venderId,
        "lat": locationdata?.lat,
        "lon": locationdata?.lon
      }
      // console.log(data)
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, data).then((res) => {
        setshowproductsdetails(res?.data?.data)
        setmainproductsimg(res?.data?.data[0]?.images?.[0])
        setattributes(res?.data?.data?.[0]?.attributes)
      }).catch((error) => {

      })
    } catch (error) {
    }
  }

  // attribute handel
  const [extractedValues, setExtractedValues] = useState([]);
  const getattribute = (newvalue, index, items) => {
    console.log("items", items)
    const updatedValues = [...extractedValues];
    updatedValues[index].value = newvalue;
    // console.log(updatedValues[index].value)
    console.log("updatedValues", updatedValues)
    setExtractedValues(updatedValues);
  }
  useEffect(() => {
    const values = attributes.map(item => ({
      name: item.attribute_name,
      value: item.attribute_values[0]
    }));
    // console.log("extractedValues", extractedValues)
    setExtractedValues(values);
  }, [attributes]);

  // Singleproducts 
  let Singleproducts = (items) => {
    // console.log(items.venderId)
    // console.log(items.productId)
    secureLocalStorage.setItem("venderId", items?.venderId)
    secureLocalStorage.setItem("productId", items?.productId)
    navigate(`/singleprojects_inntout/${items?.productId}/${items?.venderId}`)
  }

  // like dishlike handel
  let livedisclikehandel = (items) => {
    try {
      let data = {
        "userId": userid,
        "productId": items.productId,
      }
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/like_dislike_product_api`, data).then((res) => {
        // console.log(res.data.result)
        if (res.data.result) {
          toast.success(`${res.data.message}`);

          try {
            let datashow = {
              "userId": userid,
              "productId": items?.productId,
              "shopId": items?.venderId
            }
            // console.log(data)
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/productDetails`, datashow).then((res) => {
              // console.log(res.data.data)
              setshowproductsdetails(res?.data?.data)
              setmainproductsimg(res?.data?.data[0]?.images?.[0])
              let datawishlist = {
                "userId": userid
              }
              axios.post(`${process.env.REACT_APP_SECRET_KEY}/likeProduct_listApi`, datawishlist).then((res) => {
                if (res.status == 200) {
                  dispatch(setwish_list(res.data.data));
                }
              }).catch((error) => {
                dispatch(setwish_list([]));
              })
            }).catch((error) => {
            })
          } catch (error) {
          }
        }
      }).catch((error) => {
      })
    } catch (error) {
    }
  }


  let [storquntityvalue, setstorquntityvalue] = useState("")
  // add to card addtocardhandele
  let addtocardhandele = (productId) => {
    try {
      let data = {
        "userId": userid,
        "productId": productId,
        "attributes": extractedValues,
        "qty": storquntityvalue !== "" ? (storquntityvalue) : ("1"),
        "status": "0",
      }
      // console.log(data)
      axios.post(`${process.env.REACT_APP_SECRET_KEY}/Addcart`, data).then((res) => {
        // console.log(res.data.result)
        if (res.data.result) {
          toast.success(`${res.data.message}`);
          let cartlistdata = {
            "userId": userid,
            "status": "0"
          }
          axios.post(`${process.env.REACT_APP_SECRET_KEY}/cartList`, cartlistdata).then((res) => {
            if (res.status === 200) {
              dispatch(setcard_list(res.data.data[0]?.products));
            }
          }).catch((error) => {
            dispatch(setcard_list([]));
          })
        }
      }).catch((error) => {
        // console.log(error)
        if (error.response && error.response.status === 400) {
          toast.error(`${error.response.data.message}`);
        }
      })
    } catch (error) {
    }
  }

  // login handel
  let UsersingnHandel = () => {
    swal({
      title: "Please Sign in ?",
      text: "You need to Sign in to view this feature",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteaddress();
      } else {
      }
    });
    let deleteaddress = () => {
      navigate("/login")
    }
  }

  // go to cart
  let gotocarthandel = () => {
    setTimeout(() => {
      navigate("/addtocard")
    }, 500);
  }

  // price range handel
  const handlePriceInputChange = (e) => {
    setMaxPrice(e.target.value);
    filtervalueobj.price = e.target.value

  };

  // filter value handel
  let filtervalue = (event) => {
    console.log(event.target.value)
    setfiltervalueobj({ ...filtervalueobj, [event.target.name]: event.target.value })
  }

  // filter products 
  let filterproducts = (event) => {
    event.preventDefault()
    console.log("hello boss", filtervalueobj)
    axios.post(`${process.env.REACT_APP_SECRET_KEY}/filterShopProduct`, filtervalueobj).then((res) => {
      console.log(res?.data?.data)
      setshopproductslist(res?.data?.data)
    }).catch((error) => {

    })
  }

  // chat handel
  let chathandel = (shopid) => {
    let data = {
      "shopid": shopid?.shop_data?.shopId,
      "name": shopid?.shop_data?.firstName,
      "lastname": shopid?.shop_data?.lastName,
      "img": shopid?.shop_data?.upload_frontId,
      "status": 1,
    }
    secureLocalStorage.setItem("chatvendorid", JSON.stringify(data))
    navigate("/inbox")
  }

  // add fevorit shop
  let Addshophandel = (shopid) => {
    if (userid) {
      try {
        let data = {
          "userId": userid,
          "shopId": shopid?.shop_data?.shopId
        }
        // console.log(data)
        axios.post(`${process.env.REACT_APP_SECRET_KEY}/favouriteShop`, data).then((res) => {
          toast.success(`${res.data.message}`);
        }).catch((error) => {
          toast.error(`Allready added in shop favourite list`);
        })
      } catch (error) {
      }
    } else {
      navigate("/login")
    }


  }

  // report handel
  const [Reportmsg_title, setReportmsg_title] = useState();
  const [Reportmsg, setReportmsg] = useState();


  // send Reportmsg 
  let [report_status, setinnnt_Reportmsg] = useState(true)
  let sendsuggesiton = () => {
    setinnnt_Reportmsg(false)

    let data = {
      "userId": userid,
      "text": Reportmsg,
      "title": Reportmsg_title,
      "shopId": shopid,
      "status": 0,
    }

    axios.post(`${process.env.REACT_APP_SECRET_KEY}/addReportOf_shop`, data)
      .then((res) => {
        if (res.data.result) {
          toast.success(res.data.message)
          setReportmsg("")
          setReportmsg_title("")
          setinnnt_Reportmsg(true)
          setVisible(false)
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message)
        setinnnt_Reportmsg(true)
      });
  }
  return (
    <>
      <Toaster />
      <div className="card flex justify-content-center">
        <Dialog
          className='p-2'
          visible={visible}
          style={{ width: window.innerWidth <= 768 ? '100vw' : '50vw' }} // Conditional inline style
          onHide={() => { if (!visible) return; setVisible(false); }}
        >
          <p className="m-2">
            <div className='text-center'>
              <h5 className="font-bold p-2">Report</h5>
            </div>
            <div className="card">
              <div className="card-body">
                <div className='mt-3 mb-3'>
                  <div className="form-groupcol-12 col-lg-12 col-md-6 mb-0">
                    <div className="form-group mr-1">
                      <label>Title</label>
                      <select onChange={(event) => setReportmsg_title(event.target.value)} value={Reportmsg_title} className="form-control p-1" id="sel1" name="sellist1" style={{ cursor: "pointer" }}>
                        <option hidden>Select</option>
                        <option value={"Product Quality"}>Product Quality</option>
                        <option value={"Delivery"}>Delivery</option>
                        <option value={"Other"}>Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-groupcol-12 col-lg-12 col-md-12 mb-0">
                    <label htmlFor="lastName" className="mb-2 text-capitalize">Message</label>
                    <textarea onChange={(event) => setReportmsg(event.target.value)} value={Reportmsg} style={{ width: "100%" }} rows="2" name="" id="" ></textarea>
                  </div>
                </div>
                <div className='text-center'>
                  {report_status === true ? (
                    Reportmsg?.length > 0 && Reportmsg_title?.length > 0 ? (
                      <button className='btn btn-info' onClick={sendsuggesiton}>Send</button>
                    ) : (
                      <button className='btn btn-info' disabled>Send</button>
                    )
                  ) : (
                    <div className="spinner-border text-primary"></div>
                  )}

                </div>
              </div>
            </div>
          </p>
        </Dialog>
      </div>
      {loderstatus == true ? (
        <div className="container py-4 __inline-67">
          <div className="rtl">
            <div className="bg-white __shop-banner-main">
              <img className="__shop-page-banner" src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${showdata?.shop_data?.upload_backsideId}`} />
              <div className="position-relatve z-index-99 rtl w-100" style={{ textAlign: 'left' }}>
                <div className="__rounded-10 bg-white position-relative">
                  <div className="d-flex flex-wrap justify-content-between seller-details">
                    <div className="d-flex align-items-center p-2 flex-grow-1">
                      <div className>
                        <div className="position-relative">
                          <img className="__inline-68" style={{ cursor: "pointer", height: "100px" }} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${showdata?.shop_data?.upload_frontId}`} />
                        </div>
                      </div>
                      <div className="__w-100px flex-grow-1  pl-2 pl-sm-4">
                        <span className="font-weight-bold ">
                          {showdata?.shop_data?.shop_name}
                        </span>
                        <div className>
                          <small className=''><IoLocationOutline /> {showdata?.shop_data?.shop_address.slice(0, 20)}{showdata?.shop_data?.shop_address?.length >= 20 ? "..." : ""}</small>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="mr-sm-4">
                        <div className="d-flex">
                          <>
                            <BsThreeDots onClick={handleClick} aria-describedby={id} className='mr-2' style={{ fontSize: "25px", cursor: "pointer" }} />
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >

                              <Typography sx={{ p: 2 }}>
                                <div>
                                  <p onClick={() => chathandel(showdata)} style={{ cursor: "pointer" }}><CiChat1 className='mr-2' />Chat</p>
                                  {/* <p style={{ cursor: "pointer" }}><IoMdShareAlt className='mr-2' />Share</p> */}
                                  <p onClick={() => Addshophandel(showdata)} style={{ cursor: "pointer" }}><FaRegStar className='mr-2' />Add Fevorite</p>
                                  <p onClick={() => (setAnchorEl(null), setVisible(true))} style={{ cursor: "pointer" }}><MdReportProblem className='mr-2' />Report</p>
                                </div>
                              </Typography>
                            </Popover>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-3 justify-content-sm-between py-4" dir="left">
            <div className="col-lg-5 col-md-12 col-12 d-flex flex-wrap justify-content-between align-items-center w-max-md-100 me-auto gap-3">
              <div className="table-responsive" style={{ scrollbarWidth: "thin" }}>
                <div className="d-flex justify-content-between">
                  <div className="col-6 ">
                    <div className="d-flex justify-content-center align-items-center rounded __h-79px hr-right-before">
                      <div className="text-center">
                        <div className="d-flex flex-wrap align-items-center mb-2 pro">
                          <div className="text-center" style={{ marginRight: 10 }}>
                            <IoIosStar className="tio-star text-warning" style={{ fontSize: "20px" }} />
                          </div>
                        </div>
                        <div className="__text-12px text-base">
                          <strong>{showdata?.average_ratings}</strong> Rating
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="d-flex justify-content-center align-items-center rounded __h-79px">
                      <div className="text-center">
                        <img src="./inntimg/products.svg" className="mb-2" alt />
                        <div className="__text-12px text-base">
                          <strong>{showdata?.products_count}</strong> Products
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="d-flex justify-content-center align-items-center rounded __h-79px">
                      <div className="text-center">
                        <FcSalesPerformance className="mb-2" />
                        <div className="__text-12px text-base">
                          <strong>{showdata?.sales_count}</strong> Sales
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="d-flex justify-content-center align-items-center rounded __h-79px">
                      <div className="text-center">
                        <FaRegEye className="mb-2" />
                        <div className="__text-12px text-base">
                          <strong>{showdata?.view_count}</strong> Page Views
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 ">
                    <div className="d-flex justify-content-center align-items-center rounded __h-79px hr-right-before">
                      <div className="text-center">
                        <FaHeart className="mb-2" />
                        <div className="__text-12px text-base">
                          <strong>{showdata?.like_count}</strong> Liked Items
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-12 d-flex flex-column flex-sm-row gap-3">
              <form>
                <div className="sorting-item mt-2">
                  <MdDiscount />
                  <label className="for-shoting" htmlFor="sorting">
                    <span className="text-nowrap">Discount</span>
                  </label>
                  <select onChange={filtervalue} name='discount'>
                    <option hidden>Select</option>
                    <option value="5">5%</option>
                    <option value="10">10%</option>
                    <option value="15">15%</option>
                    <option value="20">20%</option>
                    <option value="25">25%</option>
                    <option value="30">30%</option>

                  </select>
                </div>

                <div className="sorting-item mt-2">
                  <i className="tio-star text-warning" />
                  <label className="for-shoting" htmlFor="sorting">
                    <span className="text-nowrap">Reaview</span>
                  </label>
                  <select onChange={filtervalue} name='rating'>
                    <option value="latest" hidden>Select</option>
                    <option value="1">1<i className="tio-star text-warning" /></option>
                    <option value="2">2<i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>
                    <option value="3">3<i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>
                    <option value="4">4<i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>
                    <option value="5">5<i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /><i className="tio-star text-warning" /></option>

                  </select>
                </div>
              </form>
              <form >
                <div className="">
                  <small><b>Max:</b> {filtervalueobj.price}</small><br />
                  <input
                    type="range"
                    className="max-range"
                    min="0"
                    max="10000"
                    value={filtervalueobj.price}
                    step="1"
                    onChange={handlePriceInputChange}
                    name="maxPrice"
                  />
                </div>
                <div className=" mt-2 text-center">
                  <button onClick={filterproducts} className="btn--primary btn">
                    Search <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="row justify-content-center rtl">
            <div className="col-lg-12 product-div">
              <div className="row justify-content-start" >
                {shopproductslist?.length > 0 ? (
                  shopproductslist?.map((items, index) => {
                    return (
                      <div key={index} className=" col-lg-2 col-md-6 col-sm-4 col-6  p-2 " onClick={() => Singleproducts(items)}>
                        {items?.discount === 0 ? ("") : (
                          <span className="for-discoutn-value p-1 pl-2 pr-2">
                            -{items?.discount}{items?.discount_name === "Unit" ? "CFA" : "%"}
                          </span>
                        )}
                        <div className="product-single-hover style--card deal_of_the_day1 ">
                          <div className="overflow-hidden position-relative">
                            <div className=" inline_product clickable d-flex justify-content-center">
                              {/* onClick={() => getproductsdetails(items)} data-toggle="modal" data-target="#viewallmyModal" */}
                              <div className="pb-0 text-center">
                                <a className="w-90 text-center" style={{ paddingTop: "40px" }}>
                                  <img width={"80%"} src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                </a>
                              </div>
                            </div>
                            <div className="single-product-details">
                              <div onClick={() => Singleproducts(items)} style={{ cursor: "pointer" }} className="p-10px pb-0">
                                {items.product_name?.slice(0, 20)}{items.product_name?.length > 23 ? "..." : ""}
                              </div>

                              <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                <strike className="pl-1 pr-2" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                  CFA{items.unit_price}
                                </strike>
                                <span className="text-accent text-dark">
                                  CFA{items.sale_price}
                                </span>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className='col-12 row  justify-content-center mt-2'>
                    <div className='col-lg-4 col-12 text-center'>
                      <div>
                        <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                      </div>
                      <h6 className='mt-2'>Data Not Found</h6>

                    </div>
                  </div>
                )}
                <div className="col-12">
                  <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation" id="paginator-ajax">
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='row justify-content-center mt-5 mb-5'>
          <div className="spinner-border text-primary"></div>
        </div>
      )
      }
      <Footer />
    </>
  )
}

export default Shopview_inntout
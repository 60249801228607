import React, { useEffect, useState } from 'react'
import Header from '../Home_page/Header'
import Footer from '../Home_page/Footer'
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'

const Inntout_searchlist = () => {
    let navigate = useNavigate()
    let [loderstatus, setloderstatus] = useState(true)
    let getdata = secureLocalStorage.getItem("searchvalue")
    let [showsuggetionvalue, setshowsuggetionvalue] = useState([])

    useEffect(() => {
        try {
            let data = {
                "key": getdata
            }
            axios.post(`${process.env.REACT_APP_SECRET_KEY}/searchInntoutProduct`, data).then((res) => {
                if (res.status === 200) {
                    setshowsuggetionvalue(res?.data?.data)
                }
            }).catch((error) => {
                if (error?.response?.status == 400) {
                    console.log("yus")
                    setshowsuggetionvalue([])
                    setTimeout(() => {
                        setloderstatus(false)
                    }, 2000);
                }
            })
        } catch (error) {
        }
    }, [getdata])


    let Singleproducts = (items) => {
        secureLocalStorage.setItem("venderId", items?.venderId)
        secureLocalStorage.setItem("productId", items?.productId)
        navigate(`/singleprojects_inntout/${items?.productId}/${items?.venderId}`)
    }
    return (
        <div>
            <div className="container ">
                <div className='row justify-content-center mt-3 mb-3'>
                    <div className='col-lg-12'>
                        <div className="__inline-62 p-4 " style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>    
                            <div className="feature-product-title mt-0 ml-1 mb-3" style={{ color: "black", fontWeight: "700", textAlign: "start" }}>
                                Search Product
                            </div>
                            {showsuggetionvalue?.length > 0 ? (
                                <div className="row" id="ajax-products">
                                    {showsuggetionvalue?.map((items, index) => {
                                        return (
                                            <div key={index} className='col-lg-2 col-md-6 col-6' onClick={() => Singleproducts(items)} >
                                                <div className="product-single-hover style--card" style={{ border: "1px solid rgba(153,199,253,.6)", borderRadius: "10px" }}>
                                                    <div className="overflow-hidden position-relative">
                                                        <div className=" inline_product clickable d-flex justify-content-center">

                                                            <div className="">
                                                                <div className="w-100">
                                                                    <img src={`${process.env.REACT_APP_SECRET_KEY_IMAGEPATH}${items?.image1}`} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="single-product-details">
                                                            <div style={{ cursor: "pointer" }} className="p-10px pb-0">
                                                                {items?.product_name.slice(0, 15)}{items?.product_name?.length > 23 ? "..." : ""}
                                                            </div>

                                                            <div className="product-price   align-items-center gap-8 p-10px pb-0">
                                                                <span className="text-accent text-dark">
                                                                    ${items.sale_price}
                                                                </span>
                                                                <strike className="pl-1" style={{ fontSize: '12px', color: '#9B9B9B' }}>
                                                                    ${items.unit_price}
                                                                </strike><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <>
                                    <div className="row justify-content-center mt-5" id="ajax-products">
                                        {loderstatus == true ? (
                                            <div className='row justify-content-center'>
                                                <div className="spinner-border text-primary"></div>
                                            </div>

                                        ) : (
                                            <div className='col-12 row  justify-content-center mt-5'>
                                                <div className='col-lg-4 col-12 text-center'>
                                                    <div>
                                                        <img style={{ borderRadius: "80%" }} width={"40%"} className='img-fluid' src="./imglist/datanotfound_logo.png" alt />
                                                    </div>
                                                    <h6 className='mt-2'>Data Not Found</h6>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            <div className="col-12">
                                <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation" id="paginator-ajax">
                                </nav>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer />
            </div>

            )
}

            export default Inntout_searchlist
import axios from 'axios'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

const Technicalissue = () => {
    let navigate = useNavigate()
    let token = secureLocalStorage.getItem("token")
    let userid = secureLocalStorage.getItem("useid")
    useEffect(() => {
        getprofileuser()
      }, [token, userid])
      const getprofileuser = () => {
        try {
          let dataAbout = {
            userId: userid
          }
          const options = {
            headers: {
              token: token
            },
          };
          axios.post(`http://103.104.74.215:3037/user/api/getUser_profile`, dataAbout, options)
            .then((res) => {
              if (res.data.result) {
                navigate('/')
              }
    
            })
            .catch((error) => {
              console.log("erer", error)
              
            });
    
        } catch (error) {
    
        }
    
      };
    return (
        <div style={{backgroundColor:"white",position:"absolute",top:"2px",zIndex:"999",width:"100%"}}>
            <div className='row justify-content-center'>
                <div className='col-lg-5'>
                    <img src="./technicalissue.webp" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Technicalissue
import React from 'react'
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import swal from 'sweetalert';
const Logout = () => {
    let navigate = useNavigate()
    // logout  
    let logout_handel = (deleteid) => {
        swal({
            title: "Are you sure?",
            text: "You want to logout",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            // content: {
            //     element: "input",
            //     attributes: {
            //         placeholder: "Enter your reason for deletion",
            //         type: "text",
            //     },
            // },
        }).then((willDelete) => {
            if (willDelete) {
                userlogout();
            } else {
            }
        });
        let userlogout = () => {
            navigate("/")
            localStorage.removeItem("loginstatus")
            secureLocalStorage.removeItem("login_status")
            secureLocalStorage.removeItem("useid")
            secureLocalStorage.removeItem("token")
            secureLocalStorage.removeItem("chatvendorid")
        }


    };
    return (
        <div style={{ cursor: "pointer" }} onClick={logout_handel} className="widget-title">
            <a className=''>
                <span>
                    <BiLogOut style={{ fontSize: "20px" }} />
                </span>
                <span>Logout</span>
            </a>
        </div>

    )
}

export default Logout